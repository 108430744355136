import React, { useState, useEffect } from 'react';
import puskesmasList from '../../assets/JsonData/daftarPuskesmas.json';
import puskesDesa from '../../assets/JsonData/Puskes_Desa.json';
import axios from 'axios';
import './css/register.css';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Table } from 'react-bootstrap';
import { API_MAMABOI } from '../../utils/constant';

const Register = ({ data }) => {
  const [userName, setUserName] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  const [access, setAccess] = useState('');
  const [desa, setDesa] = useState('');
  const [noHp, setNoHp] = useState('');
  const [desaList, setDesaList] = useState([]);
  const history = useHistory();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function changePuskes(e) {
    setAccess(e.target.value);

    const desaku = puskesDesa.filter((r) => {
      return r.Puskesmas === e.target.value;
    });

    setDesaList(desaku);
  }

  const saveUser = async (e) => {
    e.preventDefault();

    //'https://test.mamaboi.net/api/users/login',

    //

    try {
      const response = await axios.post(API_MAMABOI + 'users', {
        email: userName,
        name: name,
        password: password,

        access: access,
        desa: desa,
        noHp: noHp,
      });

      alert('Data Ibu berhasil diinput');

      history.push('/layout');
    } catch (error) {
      if (error.response) {
        //setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div onSubmit={saveUser}>
      <form className='row g-3'>
        <div className='col-12'>
          <Button variant='primary' onClick={handleShow}>
            Lihat Users
          </Button>
        </div>
        <div className='col-md-6'>
          <label for='inputEmail4' className='form-label'>
            Email / username
          </label>
          <input
            type='email'
            className='form-control'
            id='inputEmail4'
            onChange={(e) => setUserName(e.target.value)}
            required
          />
        </div>
        <div class='col-md-6'>
          <label for='inputPassword4' className='form-label'>
            Password
          </label>
          <input
            type='password'
            className='form-control'
            id='inputPassword4'
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div class='col-md-6'>
          <label for='inputAddress' className='form-label'>
            Nama lengkap
          </label>
          <input
            type='text'
            className='form-control'
            id='inputAddress'
            placeholder='Nama lengkap'
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div class='col-md-6'>
          <label for='inputPassword4' className='form-label'>
            No Hanphone
          </label>
          <input
            type='number'
            className='form-control'
            onChange={(e) => setNoHp(e.target.value)}
            required
          />
        </div>

        <div class='col-md-6'>
          <label for='inputState' className='form-label'>
            Hak Akses
          </label>
          <select
            id='inputState'
            className='form-select'
            onChange={changePuskes}
            required
          >
            <option value='' disabled selected>
              Pilih hak akses
            </option>
            <option value='Kabupaten'>Kabupaten</option>
            {puskesmasList.map((puskesmas, index) => (
              <option>{puskesmas.puskesmas}</option>
            ))}
          </select>
        </div>
        <div class='col-md-4'>
          <label for='inputState' className='form-label'>
            Desa
          </label>
          <select
            id='inputState'
            className='form-select'
            onChange={(e) => setDesa(e.target.value)}
          >
            <option value='' disabled selected>
              Pilih Desa
            </option>
            {desaList.map((desa, index) => (
              <option>{desa.Desa}</option>
            ))}
          </select>
        </div>
        <div className='col-12'>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              id='gridCheck'
            />
            <label className='form-check-label' for='gridCheck'>
              Check bila data sudah benar
            </label>
          </div>
        </div>
        <div class='col-12'>
          <button type='submit' className='btn btn-primary'>
            Register
          </button>
        </div>
      </form>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Users Mamaboi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='table-wrapper'>
            <Table id='dataIbu' striped bordered hover size='sm'>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Nama</th>
                  <th>Username</th>
                  <th>access</th>
                  <th>Desa</th>
                  <th>No HP</th>
                </tr>
              </thead>
              <tbody>
                {data.map((datausers, index) => (
                  <tr key={datausers.id}>
                    <td> {index + 1}</td>
                    <td> {datausers.name}</td>
                    <td> {datausers.email}</td>
                    <td> {datausers.access}</td>
                    <td> {datausers.desa}</td>
                    <td> {datausers.noHp}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Register;
