import React, { useState, useEffect } from 'react';

import noHPKades from '../../assets/JsonData/NoHPKades.json';
import noHPCamat from '../../assets/JsonData/NoHPCamat.json';
import noHPBidan from '../../assets/JsonData/noHpBidan.json';
import axios from 'axios';
import './css/register.css';
import { useHistory } from 'react-router-dom';
import { Form, Button, Badge, Modal } from 'react-bootstrap';
import LoadDataNoHp from '../task/LoadDataNoHp';
import { API_MAMABOI } from '../../utils/constant';

const PesanEdukasi = ({ data, nama }) => {
  const [pesan, setPesan] = useState('');
  const [pesanDialog, setPesanDialog] = useState('');
  const history = useHistory();
  const [loadHp, setLoadHp] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (checked.length === 0 || pesan === '') {
      return;
    }
    setPesanDialog(
      'Apakah anda akan mengirimkan pesan edukasi kepada ' + checked + ' ?'
    );
    setShow(true);
  };
  const [checked, setChecked] = useState([]);
  const changeCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }

    setChecked(updatedList);
    // setAlasanKritis(updatedList.join());
    console.log(updatedList.join());
  };

  const savePesan = async (jenis, tujuan, sender) => {
    //'https://test.mamaboi.net/api/users/login',

    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    var hour = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getUTCSeconds();
    var newdate = year + '-' + month + '-' + day;
    var newdatetime =
      year +
      '-' +
      month +
      '-' +
      day +
      ' ' +
      hour +
      ':' +
      minutes +
      ':' +
      seconds;
    //

    try {
      const response = await axios.post(API_MAMABOI + 'pesan', {
        tanggal: newdatetime,
        jenis: jenis,
        tujuan: tujuan,
        pesan: pesan,
        sender: sender,
      });
    } catch (error) {
      if (error.response) {
        //setMsg(error.response.data.msg);
      }
    }
  };

  const load = () => {
    const LoadDataHpku = new LoadDataNoHp();
    LoadDataHpku.load(setLoadHp, data);
  };

  useEffect(() => {
    load();
  }, [data]);

  function kirimPesan() {
    var url = 'https://pati.wablas.com/api/send-message';
    var Tokenwhatsap =
      'IONVgbV7rm3bzVoDI4jjm9zRFwDmA6j1AzgzTDkjDAQBPgNSf1CTSZ3AtORKVfls';
    //var nohp = '082237839018';
    //'081239619532'

    var i = 0;

    checked.map((r) => {
      var kop = `📢 *MAMA BO'I INFO* 📢  \n\ \n\ `;
      if (r == 'Ibu Hamil') {
        for (var i = 0; i < loadHp.ibuHamil.length; i++) {
          if (loadHp.ibuHamil[i].nomorHpIbu !== '') {
            var data = {
              phone: loadHp.ibuHamil[i].nomorHpIbu,
              message: kop + pesan + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user).then((response) =>
              response.text()
            );
          }
        }
      }
      if (r == 'Ibu Bersalin') {
        for (var i = 0; i < loadHp.ibuBersalin.length; i++) {
          if (loadHp.ibuBersalin[i].nomorHpIbu !== '') {
            var data = {
              phone: loadHp.ibuBersalin[i].nomorHpIbu,
              message: kop + pesan + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user).then((response) =>
              response.text()
            );
          }
        }
      }

      if (r == 'Kepala Desa') {
        for (var i = 0; i < noHPKades.length; i++) {
          if (noHPKades[i].noHP !== '') {
            var data = {
              phone: noHPKades[i].noHP,
              message: kop + pesan + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user).then((response) =>
              response.text()
            );
          }
        }
      }
      if (r == 'Camat') {
        for (var i = 0; i < noHPCamat.length; i++) {
          if (noHPCamat[i].noHp !== '') {
            var data = {
              phone: noHPCamat[i].noHp,
              message: kop + pesan + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user).then((response) =>
              response.text()
            );
          }
        }
      }
      if (r == 'Bidan') {
        for (var i = 0; i < noHPBidan.length; i++) {
          if (noHPBidan[i].noHpBidan !== '') {
            var data = {
              phone: noHPBidan[i].noHpBidan,
              message:
                kop +
                'Soda Molek Bidan *' +
                noHPBidan[i].bidan +
                `* \n\ ` +
                pesan +
                `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user).then((response) =>
              response.text()
            );
          }
        }
      }
      if (r == 'Suami') {
        for (var i = 0; i < loadHp.ibuHamil.length; i++) {
          if (
            loadHp.ibuHamil[i].noHpSuami !== '' &&
            loadHp.ibuHamil[i].noHpSuami !== '0'
          ) {
            var data = {
              phone: loadHp.ibuHamil[i].noHpSuami,
              message:
                kop +
                'Soda Molek Bapak *' +
                loadHp.ibuHamil[i].namaSuami +
                `* \n\ ` +
                pesan +
                `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user).then((response) =>
              response.text()
            );
          }
        }
      }
      if (r == 'Pendonor Darah') {
        for (var i = 0; i < loadHp.ibuHamil.length; i++) {
          if (loadHp.ibuHamil[i].hpPendonor !== '') {
            var data = {
              phone: loadHp.ibuHamil[i].hpPendonor,
              message: kop + pesan + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user).then((response) =>
              response.text()
            );
          }
        }
      }
      if (r == 'Tokoh Agama') {
        for (var i = 0; i < loadHp.ibuHamil.length; i++) {
          if (loadHp.ibuHamil[i].nomorTA !== '') {
            var data = {
              phone: loadHp.ibuHamil[i].nomorTA,
              message: kop + pesan + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user).then((response) =>
              response.text()
            );
          }
        }
      }
      if (r == 'Tokoh Budaya') {
        for (var i = 0; i < loadHp.ibuHamil.length; i++) {
          if (loadHp.ibuHamil[i].nomorTB !== '') {
            var data = {
              phone: loadHp.ibuHamil[i].nomorTB,
              message: kop + pesan + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user).then((response) =>
              response.text()
            );
          }
        }
      }
      if (r == 'Kader') {
        for (var i = 0; i < loadHp.ibuHamil.length; i++) {
          if (loadHp.ibuHamil[i].nomorKader !== '') {
            var data = {
              phone: loadHp.ibuHamil[i].nomorKader,
              message: kop + pesan + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user).then((response) =>
              response.text()
            );
          }
        }
      }
    });
    setShow(false);
    var jenis = 'Pesan Edukasi';
    var tujuan = checked;
    var sender = nama;
    savePesan(jenis, tujuan, sender);
    alert('pesan terkirim untuk ' + checked);
    history.push('/layout');
    history.push('/layout/pesanedukasi');
  }

  return (
    <div>
      <Form>
        <h2>Sio Sodak</h2>
        <Form.Check
          type='switch'
          id='custom-switch1'
          label='Kepala Desa'
          class='form-check-input'
          onChange={changeCheck}
          value='Kepala Desa'
        />
        <Form.Check
          type='switch'
          id='custom-switch2'
          label='Camat'
          class='form-check-input'
          onChange={changeCheck}
          value='Camat'
        />
        <Form.Check
          type='switch'
          id='custom-switch3'
          label='Ibu Hamil'
          class='form-check-input'
          onChange={changeCheck}
          value='Ibu Hamil'
        />
        <Form.Check
          type='switch'
          id='custom-switch33'
          label='Ibu Bersalin'
          class='form-check-input'
          onChange={changeCheck}
          value='Ibu Bersalin'
        />
        <Form.Check
          type='switch'
          id='custom-switch4'
          label='Pendonor Darah'
          class='form-check-input'
          onChange={changeCheck}
          value='Pendonor Darah'
        />
        <Form.Check
          type='switch'
          id='custom-switch5'
          label='Tokoh Agama'
          class='form-check-input'
          onChange={changeCheck}
          value='Tokoh Agama'
        />
        <Form.Check
          type='switch'
          id='custom-switch6'
          label='Tokoh Budaya / Maneleo'
          class='form-check-input'
          onChange={changeCheck}
          value='Tokoh Budaya'
        />
        <Form.Check
          type='switch'
          id='custom-switch7'
          label='Kader'
          class='form-check-input'
          onChange={changeCheck}
          value='Kader'
        />
        <Form.Check
          type='switch'
          id='custom-switch8'
          label='Bidan'
          class='form-check-input'
          onChange={changeCheck}
          value='Bidan'
        />
        <Form.Check
          type='switch'
          id='custom-switch9'
          label='Suami'
          class='form-check-input'
          onChange={changeCheck}
          value='Suami'
        />

        <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
          <Form.Label>isi pesan</Form.Label>
          <Form.Control
            as='textarea'
            rows={3}
            value={pesan}
            onChange={(e) => setPesan(e.target.value)}
          />
        </Form.Group>
        <Button variant='primary' onClick={handleShow}>
          Kirim pesan <Badge bg='secondary'>9</Badge>
        </Button>
      </Form>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pesan Edukasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>{pesanDialog}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            cancel
          </Button>
          <Button variant='primary' onClick={kirimPesan}>
            kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PesanEdukasi;
