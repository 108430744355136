import React, { useState, useRef, useEffect } from 'react';
import { Map, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import PuskesDesa from '../../assets/JsonData/Puskes_Desa.json';
import L from 'leaflet';
import './myMap.css';
import { map } from 'leaflet';
import { Button } from 'react-bootstrap';
import LoadPetaAccess from '../task/LoadPetaAccess';
import { API_MAMABOI } from '../../utils/constant';

const MappingIbu = ({ desas, access }) => {
  const mapRef = useRef();
  const [zoom, setZoom] = useState();
  const [center, setCenter] = useState([]);
  const [desaPuskes, setDesaPuskes] = useState([]);

  const load = () => {
    const loadData = new LoadPetaAccess();
    loadData.load(setZoom, setCenter, setDesaPuskes, access);
  };

  useEffect(load, [access]);

  // useEffect(createMap(), [access]);
  function onEachFeature(desa, layer) {
    //console.log(f.properties.DESA)

    let listDesa = desaPuskes.filter((r) => {
      return desa.properties.DESA === r.Desa;
    });

    if (listDesa.length === 1) {
      layer.options.weight = 0.8;
      layer.options.fillOpacity = 1;
      layer.options.fillColor = desa.properties.COLOR;
      layer.options.color = 'white';

      var out = [];
      if (desa.properties) {
        out.push('Kecamatan : ' + '<b>' + desa.properties.KECAMATAN + '</b>');
        out.push('Desa : ' + '<b>' + desa.properties.DESA + '</b>');
        out.push('Total Ibu Hamil : ' + '<b>' + desa.properties.HAMIL + '</b>');
        out.push('Ibu KRR : ' + '<b>' + desa.properties.KRR + '</b>');
        out.push('Ibu KRT : ' + '<b>' + desa.properties.KRT + '</b>');
        out.push('Ibu KRST : ' + '<b>' + desa.properties.KRST + '</b>');
        layer.bindPopup(out.join('<br />'));
      }
    } else {
      layer.options.weight = 0.8;
      layer.options.fillOpacity = 1;
      layer.options.fillColor = '	#F0F0F0';
      layer.options.color = '	#BEBEBE';
    }
    layer.on('mouseover', function (e) {
      this.setStyle({
        weight: 4,
      });
    });

    layer.on('mouseout', function () {
      this.setStyle({
        weight: 0.5,
      });
    });
  }

  // function createMap() {
  //   const { current = {} } = mapRef;
  //   const { leafletElement: map } = current;
  //   clearMap();

  //   if (access != 'Kabupaten') {
  //     // var puskes = zoomCenter.filter((item) => {
  //     //   return item.name == access;
  //     // });

  //     const desaPuskes = PuskesDesa.filter((r) => {
  //       return r.Puskesmas === access;
  //     });

  //     let desaku = [];
  //     const petakec = new L.GeoJSON(desas, {
  //       onEachFeature: (feature, layer) => {
  //         desaku = desaPuskes.filter((r) => {
  //           return r.Desa === feature.properties.DESA;
  //         });

  //         if (desaku.length !== 0) {
  //           layer.options.weight = 0.8;
  //           layer.options.fillOpacity = 1;
  //           layer.options.fillColor = feature.properties.COLOR;
  //           layer.options.color = 'white';
  //           //console.log(f.properties.DESA)

  //           var out = [];
  //           if (feature.properties) {
  //             out.push(
  //               'Kecamatan : ' + '<b>' + feature.properties.KECAMATAN + '</b>'
  //             );
  //             out.push('Desa : ' + '<b>' + feature.properties.DESA + '</b>');
  //             out.push(
  //               'Total Ibu Hamil : ' + '<b>' + feature.properties.HAMIL + '</b>'
  //             );
  //             out.push('Ibu KRR : ' + '<b>' + feature.properties.KRR + '</b>');
  //             out.push('Ibu KRT : ' + '<b>' + feature.properties.KRT + '</b>');
  //             out.push(
  //               'Ibu KRST : ' + '<b>' + feature.properties.KRST + '</b>'
  //             );

  //             layer.bindPopup(out.join('<br />'));
  //           }
  //         } else {
  //           layer.options.weight = 0.8;
  //           layer.options.fillOpacity = 1;
  //           layer.options.fillColor = '	#F0F0F0';
  //           layer.options.color = '	#BEBEBE';
  //         }

  //         layer.on('mouseover', function (e) {
  //           this.setStyle({
  //             weight: 4,
  //             fillOpacity: 0.5,
  //           });
  //         });

  //         layer.on('mouseout', function () {
  //           this.setStyle({
  //             weight: 0.5,
  //             fillOpacity: 1,
  //           });
  //         });
  //       },
  //     });
  //     petakec.addTo(map);
  //     // map.setView(puskes[0].koordinat, 12.2);
  //   } else {
  //     map.setView([-10.752445, 123.127355], 10.2);

  //     const petakec = new L.GeoJSON(desas, {
  //       onEachFeature: (feature, layer) => {
  //         layer.options.weight = 0.8;
  //         layer.options.fillOpacity = 1;
  //         layer.options.fillColor = feature.properties.COLOR;
  //         layer.options.color = 'white';
  //         //console.log(f.properties.DESA)

  //         var out = [];
  //         if (feature.properties) {
  //           out.push(
  //             'Kecamatan : ' + '<b>' + feature.properties.KECAMATAN + '</b>'
  //           );
  //           out.push('Desa : ' + '<b>' + feature.properties.DESA + '</b>');
  //           out.push(
  //             'Total Ibu Hamil : ' + '<b>' + feature.properties.HAMIL + '</b>'
  //           );
  //           out.push('Ibu KRR : ' + '<b>' + feature.properties.KRR + '</b>');
  //           out.push('Ibu KRT : ' + '<b>' + feature.properties.KRT + '</b>');
  //           out.push('Ibu KRST : ' + '<b>' + feature.properties.KRST + '</b>');

  //           layer.bindPopup(out.join('<br />'));
  //         }

  //         layer.on('mouseover', function (e) {
  //           this.setStyle({
  //             weight: 4,
  //           });
  //         });

  //         layer.on('mouseout', function () {
  //           this.setStyle({
  //             weight: 0.5,
  //           });
  //         });
  //       },
  //     });
  //     petakec.addTo(map);
  //   }
  // }

  function onSelectChange(e) {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    var kecamatan = [
      {
        name: 'Rote Barat',
        koordinat: [-10.858225374311647, 122.85796900585532],
      },
      {
        name: 'Rote Barat Daya',
        koordinat: [-10.866963017381602, 122.93778430514685],
      },
      ,
      {
        name: 'Ndao Nuse',
        koordinat: [-10.823068244905466, 122.70042863793482],
      },
      {
        name: 'Rote Barat Laut',
        koordinat: [-10.795098501394149, 122.94770693546936],
      },
      {
        name: 'Lobalain',
        koordinat: [-10.77416238620225, 123.07014215226727],
      },
      {
        name: 'Rote Selatan',
        koordinat: [-10.788664668900418, 123.19682824897457],
      },
      {
        name: 'Rote Tengah',
        koordinat: [-10.715784743881224, 123.1368223831096],
      },
      {
        name: 'Pantai Baru',
        koordinat: [-10.642764460925171, 123.28462041206107],
      },
      {
        name: 'Rote Timur',
        koordinat: [-10.641560682600872, 123.37158444822296],
      },
      {
        name: 'Landu Leko',
        koordinat: [-10.530656139962185, 123.33766929559646],
      },
      {
        name: 'Luaolu',
        koordinat: '',
      },
    ];

    clearMap();

    if (e != 'Kabupaten') {
      var kec = kecamatan.filter((item) => {
        return item.name == e;
      });

      const petakec = new L.GeoJSON(desas, {
        onEachFeature: (feature, layer) => {
          if (feature.properties.KECAMATAN == e) {
            layer.options.weight = 0.8;
            layer.options.fillOpacity = 1;
            layer.options.fillColor = feature.properties.COLOR;
            layer.options.color = 'white';

            //console.log(f.properties.DESA)

            var out = [];
            if (feature.properties) {
              out.push(
                'Kecamatan : ' + '<b>' + feature.properties.KECAMATAN + '</b>'
              );
              out.push('Desa : ' + '<b>' + feature.properties.DESA + '</b>');
              out.push(
                'Total Ibu Hamil : ' + '<b>' + feature.properties.HAMIL + '</b>'
              );
              out.push('Ibu KRR : ' + '<b>' + feature.properties.KRR + '</b>');
              out.push('Ibu KRT : ' + '<b>' + feature.properties.KRT + '</b>');
              out.push(
                'Ibu KRST : ' + '<b>' + feature.properties.KRST + '</b>'
              );

              layer.bindPopup(out.join('<br />'));
            }
          } else {
            layer.options.weight = 0.8;
            layer.options.fillOpacity = 1;
            layer.options.fillColor = '	#F0F0F0';
            layer.options.color = '	#BEBEBE';
          }

          layer.on('mouseover', function (e) {
            this.setStyle({
              weight: 4,
              fillOpacity: 0.5,
            });
          });

          layer.on('mouseout', function () {
            this.setStyle({
              weight: 0.5,
              fillOpacity: 1,
            });
          });
        },
      });
      petakec.addTo(map);
      map.setView(kec[0].koordinat, 12.2);
    } else {
      map.setView([-10.752445, 123.127355], 10.2);

      const petakec = new L.GeoJSON(desas, {
        onEachFeature: (feature, layer) => {
          layer.options.weight = 0.8;
          layer.options.fillOpacity = 1;
          layer.options.fillColor = feature.properties.COLOR;
          layer.options.color = 'white';
          layer.option.borderRadius = 10;
          //console.log(f.properties.DESA)

          var out = [];
          if (feature.properties) {
            out.push(
              'Kecamatan : ' + '<b>' + feature.properties.KECAMATAN + '</b>'
            );
            out.push('Desa : ' + '<b>' + feature.properties.DESA + '</b>');
            out.push(
              'Total Ibu Hamil : ' + '<b>' + feature.properties.HAMIL + '</b>'
            );
            out.push('Ibu KRR : ' + '<b>' + feature.properties.KRR + '</b>');
            out.push('Ibu KRT : ' + '<b>' + feature.properties.KRT + '</b>');
            out.push('Ibu KRST : ' + '<b>' + feature.properties.KRST + '</b>');

            layer.bindPopup(out.join('<br />'));
          }

          layer.on('mouseover', function (e) {
            this.setStyle({
              weight: 4,
            });
          });

          layer.on('mouseout', function () {
            this.setStyle({
              weight: 0.5,
            });
          });
        },
      });
      petakec.addTo(map);
    }
  }

  // const mapStyle = {
  //   fillColor: 'red',
  //   weight: 1,
  //   color: 'black',
  //   fillOpacity: 1,
  // };

  function clearMap() {
    const map = mapRef.current.leafletElement;
    map.eachLayer(function (layer) {
      map.removeLayer(layer);
    });
  }

  // function onMouseGerak(e) {
  //   console.log(e);
  // }

  return (
    <div className='bingkaiPeta'>
      {access === 'Kabupaten' ? (
        <div className='mb-3'>
          <label for='exampleFormControlInput1' class='form-label'>
            Pilih Kecamatan
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            onChange={(e) => onSelectChange(e.target.value)}
          >
            <option>Kabupaten</option>
            <option>Ndao Nuse</option>
            <option>Rote Barat</option>
            <option>Rote Barat Laut</option>
            <option>Rote Barat Daya</option>
            <option>Lobalain</option>
            <option>Rote Selatan</option>
            <option>Rote Tengah</option>
            <option>Pantai Baru</option>
            <option>Rote Timur</option>
            <option>Landu Leko</option>
          </select>
        </div>
      ) : (
        <div></div>
      )}

      <Map
        ref={mapRef}
        style={{ height: '90vh' }}
        zoom={zoom}
        center={center}
        minZoom={10.2}
        maxZoom={12.2}
      >
        <div className='legend'>
          <div className='lg1'>
            <p>KRST</p>
          </div>

          <div className='lg2'>
            <p>KRT</p>{' '}
          </div>
          <div className='lg3'>
            {' '}
            <p>KRR</p>
          </div>
          <div className='lg4'>
            {' '}
            <p>None</p>
          </div>
        </div>
        <GeoJSON
          data={desas}
          onEachFeature={onEachFeature}
          // onMouseMove={onMouseGerak}
        />
      </Map>
    </div>
  );
};

export default MappingIbu;
