import axios from 'axios';
import { API_MAMABOI } from '../../utils/constant';

class LoadDataPesan2MPartus {
  // mendapatkan data
  load = (setData, access) => {
    this.setData = setData;
    this.#proccessData();
    this.access = access;
  };

  #proccessData = async () => {
    var dataIbuku = [];
    var dataKunjungan = [];

    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbuku = response.data.data;
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await axios.get(API_MAMABOI + 'kunjungan');
      dataKunjungan = response.data.data;
    } catch (error) {
      console.error(error);
    }

    //mencari ibu 2 minggu partus

    let com1 = 'Ibu hamil';
    let com2 = 'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)';
    let com3 = '';
    var dataIbuPartus = dataIbuku.map((item) => {
      com3 = item.statusIbu;
      if (
        com3.toUpperCase() === com1.toUpperCase() ||
        com3.toUpperCase() === com2.toUpperCase()
      ) {
        var kunjungan = dataKunjungan.filter((filter) => {
          return filter.idIbu === item.id;
        });

        if (kunjungan.length === 0) {
          return;
        }

        var kunjunganAkhir = kunjungan[kunjungan.length - 1];
        var usiaKandungan = kunjunganAkhir.usiaHamil;
        var tanggalKunjunganAkhir = kunjunganAkhir.tanggalKun;

        //perhitungan selisih tanggal utk menentukan minggu partus
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();
        var newdate = year + '-' + month + '-' + day;
        var d1 = new Date(tanggalKunjunganAkhir);
        var d2 = new Date(newdate);

        var dok = Math.round((d2 - d1) / 1000 / 60 / 60 / 24 / 7);

        var usiaNow = usiaKandungan + dok;

        if (usiaNow >= 40) {
          var partusDua = {};
          partusDua.namaIbu = item.namaIbu;
          partusDua.puskesmas = item.puskesmas;
          partusDua.usiaHamil = usiaNow;
          partusDua.foto = item.fotoIbu;
          partusDua.desa = item.desaIbu;
          partusDua.noHp = item.nomorHpIbu;
          partusDua.hpSuami = item.noHpSuami;
          partusDua.hpBidan = item.noHpBidan;
          partusDua.bidan = item.bidan;
          partusDua.suami = item.namaSuami;
          partusDua.pendonorDarah = item.pendonorDarah;
          partusDua.hpPendonor = item.hpPendonor;

          //sio sodak
          partusDua.namaTA = item.namaTA;
          partusDua.nomorTA = item.nomorTA;
          partusDua.namaTB = item.namaTB;
          partusDua.nomorTB = item.nomorTB;
          partusDua.namaKader = item.namaKader;
          partusDua.nomorKader = item.nomorKader;

          return partusDua;
        }
      }
    });

    let partusDuaMinggu = dataIbuPartus.filter((el) => {
      return el != null && el != '';
    });

    if (this.access != 'Kabupaten') {
      partusDuaMinggu = partusDuaMinggu.filter((r) => {
        return r.puskesmas == this.access;
      });
    }

    this.setData(partusDuaMinggu);
  };
}

export default LoadDataPesan2MPartus;
