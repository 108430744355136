import axios from 'axios';
import { API_MAMABOI } from '../../utils/constant';

class LoadDataNoHp {
  // mendapatkan data
  load = (setData, access) => {
    this.setData = setData;
    this.access = access;
    this.#proccessData();
  };

  #proccessData = async () => {
    var dataIbuku = [];

    try {
      const response = await axios.get(API_MAMABOI + 'kohort');

      dataIbuku = response.data.data;
    } catch (error) {
      console.error(error);
    }

    // ambil data ibu bersalin

    // for (var i = 0; i < dataBersalin.length; i++) {
    // dataBersalin.push(DataIbuBersalin[i][6], DataIbuBersalin[i][1]);
    //}

    //_______
    let com1 = 'Ibu hamil';
    let com2 = 'Ibu bersalin';
    let com3 = '';

    var dataIbuHamilHp = dataIbuku.filter((r) => {
      return r.statusIbu.toUpperCase() === com1.toUpperCase();
    });
    var dataIbuBersalinHp = dataIbuku.filter((r) => {
      return r.statusIbu.toUpperCase() === com2.toUpperCase();
    });

    var dataNoHp = {};
    dataNoHp.ibuHamil = dataIbuHamilHp;
    dataNoHp.ibuBersalin = dataIbuBersalinHp;

    //console.log(rujukanIbu);

    this.setData(dataNoHp);
    //____________
  };
}

export default LoadDataNoHp;
