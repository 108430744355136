import React, { useState, useEffect } from 'react';
import './css/dataIbu.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Table, Button, Form, Badge, Modal } from 'react-bootstrap';

import LoadDataIbuExport from '../task/LoadDataIbuExport';
import { API_MAMABOI } from '../../utils/constant';

const DataIbu = ({ data }) => {
  const [dataIbusaring, setDataIbusaring] = useState([]);
  const [dataFix, setDataFix] = useState([]);
  const [show, setShow] = useState(false);
  const [idKu, setId] = useState('');

  const load = () => {
    const LoadDataIbuku = new LoadDataIbuExport();
    LoadDataIbuku.load(setDataIbusaring, data, setDataFix);
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  function pilihOpsi(event) {
    var val = event.target.value;
    if (val === '0') {
      setDataIbusaring(dataFix);
    }

    if (val === '1') {
      var dataSelect = dataFix.filter((r) => {
        return r.golDarahIbu == '';
      });
      setDataIbusaring(dataSelect);
    }
    if (val === '2') {
      var dataSelect = dataFix.filter((r) => {
        return r.pendonorDarah == '';
      });
      setDataIbusaring(dataSelect);
    }
    if (val === '3') {
      var dataSelect = dataFix.filter((r) => {
        return r.nikIbu == '' || r.nikIbu == '0';
      });
      setDataIbusaring(dataSelect);
    }
    if (val === '4') {
      var dataSelect = dataFix.filter((r) => {
        return r.pkh == 'Tidak' || r.pkh == '';
      });
      setDataIbusaring(dataSelect);
    }
    if (val === '5') {
      var dataSelect = dataFix.filter((r) => {
        return r.bpnt == 'Tidak' || r.bpnt == '';
      });
      setDataIbusaring(dataSelect);
    }
    if (val === '6') {
      var dataSelect = dataFix.filter((r) => {
        return r.bst == 'Tidak' || r.bst == '';
      });
      setDataIbusaring(dataSelect);
    }
    if (val === '7') {
      var dataSelect = dataFix.filter((r) => {
        return r.askes == 'Tidak Ada';
      });
      setDataIbusaring(dataSelect);
    }
    if (val === '8') {
      var dataSelect = dataFix.filter((r) => {
        return r.statusKawin == 'Tidak Kawin';
      });
      setDataIbusaring(dataSelect);
    }
    if (val === '9') {
      var dataSelect = dataFix.filter((r) => {
        return r.status_Pj == '' || r.status_Pj == 'Pilih opsi';
      });
      setDataIbusaring(dataSelect);
    }
    if (val === '10') {
      var dataSelect = dataFix.filter((r) => {
        return r.status_Pj.toUpperCase() == 'KRR';
      });
      setDataIbusaring(dataSelect);
    }
    if (val === '11') {
      var dataSelect = dataFix.filter((r) => {
        return r.status_Pj.toUpperCase() == 'KRT';
      });
      setDataIbusaring(dataSelect);
    }
    if (val === '12') {
      var dataSelect = dataFix.filter((r) => {
        return r.status_Pj.toUpperCase() == 'KRST';
      });
      setDataIbusaring(dataSelect);
    }
    if (val === '13') {
      var dataSelect = dataFix.filter((r) => {
        return r.tinggi && r.tinggi <= 145;
      });
      setDataIbusaring(dataSelect);
    }
    if (val === '14') {
      var dataSelect = dataFix.filter((r) => {
        return r.lila && r.lila <= 23;
      });
      setDataIbusaring(dataSelect);
    }
  }

  useEffect(load, []);

  // const getDataIbu = async () => {
  //   try {
  //     const response = await axios.get('https://test.mamaboi.net/api/kohort');

  //     setDataIbus(response.data.data);
  //     if (data !== 'Kabupaten') {
  //       const dataIbusaring = dataIbus.filter((r) => {
  //         return (
  //           r.puskesmas === data &&
  //           (r.statusIbu === 'Ibu hamil' ||
  //             r.statusIbu ===
  //               'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)')
  //         );
  //       });
  //       setDataIbusaring(dataIbusaring);
  //     } else {
  //       setDataIbusaring(dataIbus);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const deleteProduct2 = async () => {
    await axios.delete(API_MAMABOI + `kohort/${idKu}`);
    alert('Data Ibu dengan id ' + idKu + ' berhasil dihapus');
    handleClose();
    // getDataIbu();
  };

  const deleteProduct = async (id) => {
    setId(id);
    handleShow();

    //await axios.delete(`https://test.mamaboi.net/api/kohort/${id}`);
    //alert('data berhasil dihapus');
    // getDataIbu();
  };

  return (
    <div>
      <div className='detailsII'>
        <div className='dataIbu'>
          <div class='cardHeader'>
            <h2>
              #Data Ibu Hamil
              <Badge bg='danger' className='badgeKu'>
                {dataIbusaring.length}
              </Badge>
            </h2>
            <ReactHTMLTableToExcel
              className='btn'
              table='dataIbu'
              filename='export Data Ibu'
              sheet='Data Ibu'
              buttonText='Export to Exl'
            />
          </div>
          <Form.Select
            aria-label='Default select example'
            onChange={pilihOpsi}
            className='selectKu'
          >
            <option value='0'>Buat pilihan untuk menganalisa data</option>
            <option value='1'>Ibu Hamil belum memiliki Golongan Darah</option>
            <option value='2'>Ibu Hamil belum memiliki Pendonor Darah</option>
            <option value='3'>Ibu Hamil belum meimiliki NIK</option>
            <option value='4'>
              Ibu Hamil bukan penerima Program Keluarga Harapan
            </option>
            <option value='5'>
              Ibu Hamil bukan penerima Bantuan Pangan Non Tunai
            </option>
            <option value='6'>
              Ibu Hamil bukan penerima Bantuan Sosial Tunai
            </option>
            <option value='7'>Ibu Hamil belum memiliki Askes</option>
            <option value='8'>Ibu Hamil di luar Nikah</option>
            <option value='9'>
              Ibu Hamil belum dihitung status Pudji Rojati
            </option>
            <option value='10'>Ibu Hamil KRR</option>
            <option value='11'>Ibu Hamil KRT</option>
            <option value='12'>Ibu Hamil KRST</option>
            <option value='13'>Ibu Hamil dengan Tinggi di bawah 145 cm</option>
            <option value='14'>Ibu Hamil KEK </option>
          </Form.Select>

          <div className='table-wrapper'>
            <Table id='dataIbu' striped bordered hover size='sm'>
              <thead>
                <tr>
                  <th>No</th>
                  <th width='150px'>Action</th>
                  <th>Nama</th>
                  <th>Asal desa</th>
                  <th>Status</th>
                  <th>Puskesmas</th>
                  <th>Bidan</th>
                  <th>NoHp bidan</th>
                  <th>Desa bidan</th>
                  <th>Tempat Data</th>
                  <th>Foto Ibu</th>
                  <th>Tanggal Lahir</th>
                  <th>NIK</th>
                  <th>NoHp Ibu</th>
                  <th>Pendidikan</th>
                  <th>Pekerjaan</th>
                  <th>Desa</th>
                  <th>Gol darah</th>
                  <th>Pendonor darah</th>
                  <th>NoHp Pendonor</th>
                  <th>Lila</th>
                  <th>Status kawin</th>
                  <th>Foto rumah</th>
                  <th>Nama Suami</th>
                  <th>NoHp suami</th>
                  <th>Pekerjaan suami</th>
                  <th>Pendidikan suami</th>
                  <th>Alamat Suami </th>
                  <th>Suami merokok</th>
                  <th>PKH</th>
                  <th>BPNT</th>
                  <th>BST</th>
                  <th>Askes</th>
                  <th>Jumlah anak</th>
                  <th>Status ibu</th>
                  <th>Alasan menolak </th>
                  <th>Foto bukti menolak</th>
                  <th>GPA</th>
                  <th>Pernah keguguran</th>
                  <th>Tahun gugur</th>
                  <th>Bayi meninggal</th>
                  <th>Tahun meninggal</th>
                  <th>Tinggi</th>
                  <th>HPHT</th>
                  <th>Akses faskes</th>
                  <th>Transport</th>
                  <th>Biaya</th>
                  <th>Kendala intervensi</th>
                  <th>Pilihan faskes</th>
                  <th>Hamil terakhir</th>
                  <th>Hamil ke</th>
                  <th>Tahun nikah</th>
                  <th>Lahir tarikan tang</th>
                  <th>Lahir uri rogoh</th>
                  <th>Lahir infus</th>
                  <th>Sesar</th>
                  <th>Kurang darah</th>
                  <th>Malaria</th>
                  <th>TBC paru</th>
                  <th>Payah jantung</th>
                  <th>diabetes </th>
                  <th>PMS</th>
                  <th>Bengkak</th>
                  <th>Kembar</th>
                  <th>Hydromnion</th>
                  <th>Bayi mati kandungan</th>
                  <th>Lebih bulang</th>
                  <th>Letak sunsang </th>
                  <th>Letak lintang</th>
                  <th>Perdarahan</th>
                  <th>Kejang</th>
                  <th>Skor PR</th>

                  <th>Nama Tokoh Agama</th>
                  <th>Nomor HP TA</th>
                  <th>Nama Tokoh Budaya</th>
                  <th>Nomor HP TB</th>
                  <th>Nama Kader</th>
                  <th>Nomor HP Kader</th>

                  <th>Tanggal Input</th>

                  {/* <th>Tanggal Akhir Mens</th> */}
                </tr>
              </thead>
              <tbody>
                {dataIbusaring.map((dataibu, index) => (
                  <tr key={dataibu.id}>
                    <td> {index + 1}</td>
                    <td>
                      <Link to={`/layout/edit/${dataibu.id}`} className='btn'>
                        Edit
                      </Link>
                      <Button
                        variant='danger'
                        onClick={() => deleteProduct(dataibu.id)}
                      >
                        hapus
                      </Button>
                    </td>
                    <td> {dataibu.namaIbu}</td>
                    <td> {dataibu.desaIbu}</td>
                    <td className={dataibu.status_Pj.toLowerCase()}>
                      {' '}
                      {dataibu.status_Pj}
                    </td>
                    <td> {dataibu.puskesmas}</td>
                    <td> {dataibu.bidan}</td>
                    <td> {dataibu.noHpBidan}</td>
                    <td> {dataibu.desaBidan}</td>
                    <td> {dataibu.tempatData}</td>
                    <td> {dataibu.fotoIbu}</td>
                    <td> {dataibu.tanggalLahirIbu}</td>
                    <td> {dataibu.nikIbu}</td>
                    <td> {dataibu.nomorHpIbu}</td>
                    <td> {dataibu.pendidikanIbu}</td>
                    <td> {dataibu.pekerjaanIbu}</td>
                    <td> {dataibu.desaIbu}</td>
                    <td> {dataibu.golDarahIbu}</td>
                    <td> {dataibu.pendonorDarah}</td>
                    <td> {dataibu.hpPendonor}</td>
                    <td> {dataibu.lila}</td>
                    <td> {dataibu.statusKawin}</td>
                    <td> {dataibu.fotoRumah}</td>
                    <td> {dataibu.namaSuami}</td>
                    <td> {dataibu.noHpSuami}</td>
                    <td> {dataibu.pekerjaanSuami}</td>
                    <td> {dataibu.pendidikanSuami}</td>
                    <td> {dataibu.alamatSuami}</td>
                    <td> {dataibu.suamiRokok}</td>
                    <td> {dataibu.pkh}</td>
                    <td> {dataibu.bpnt}</td>
                    <td> {dataibu.bst}</td>

                    <td> {dataibu.askes}</td>

                    <td> {dataibu.jumlahAnak}</td>
                    <td> {dataibu.statusIbu}</td>
                    <td> {dataibu.alasan}</td>
                    <td> {dataibu.fotoBukti}</td>
                    <td> {dataibu.gpa}</td>
                    <td> {dataibu.pernahGugur}</td>
                    <td> {dataibu.tahunGugur}</td>
                    <td> {dataibu.bayiMeninggal}</td>
                    <td> {dataibu.tahunMeninggal}</td>
                    <td> {dataibu.tinggi}</td>
                    <td> {dataibu.hpht}</td>
                    <td> {dataibu.aksesIbu}</td>

                    <td> {dataibu.transportIbu}</td>
                    <td> {dataibu.biayaIbu}</td>
                    <td> {dataibu.kendalaIntervensi}</td>
                    <td> {dataibu.pilihanFaskes}</td>
                    <td> {dataibu.hamilTerakhir}</td>
                    <td> {dataibu.hamilKe}</td>
                    <td> {dataibu.tahunNikah}</td>
                    <td> {dataibu.lahirTarikanTang}</td>
                    <td> {dataibu.lahirUriRogoh}</td>
                    <td> {dataibu.lahirInfus}</td>
                    <td> {dataibu.sesar}</td>
                    <td> {dataibu.kurangDarah}</td>
                    <td> {dataibu.malaria}</td>

                    <td> {dataibu.tbcParu}</td>
                    <td> {dataibu.payahJantung}</td>
                    <td> {dataibu.diabetes}</td>
                    <td> {dataibu.pms}</td>
                    <td> {dataibu.bengkak}</td>
                    <td> {dataibu.kembar}</td>
                    <td> {dataibu.hydromnion}</td>
                    <td> {dataibu.bayiMatiKandungan}</td>
                    <td> {dataibu.lebihBulan}</td>
                    <td> {dataibu.letakSunsang}</td>
                    <td> {dataibu.letakLintang}</td>
                    <td> {dataibu.perdarahan}</td>
                    <td> {dataibu.kejang}</td>

                    <td> {dataibu.skor_pj}</td>

                    <td> {dataibu.namaTA}</td>
                    <td> {dataibu.nomorTA}</td>
                    <td> {dataibu.namaTB}</td>
                    <td> {dataibu.nomorTB}</td>
                    <td> {dataibu.namaKader}</td>
                    <td> {dataibu.nomorKader}</td>

                    <td> {dataibu.createdAt}</td>

                    {/* <td> {dataibu.tanggalMensAkhir}</td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Hapus Data Ibu</Modal.Title>
        </Modal.Header>
        <Modal.Body>Apakah anda ingin data ibu dengan id {idKu}?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Batal
          </Button>
          <Button variant='danger' onClick={deleteProduct2}>
            Hapus
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DataIbu;
