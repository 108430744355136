import React, { useState, useEffect } from 'react';
import './css/dataIbu.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Table, Button } from 'react-bootstrap';
import { API_MAMABOI } from '../../utils/constant';

//import LoadDataIbu from '../task/LoadDataIbu';
import LoadDataIbuBersalin from '../task/LoadDataIbuBersalin';

const DataIbuBersalin = ({ data }) => {
  const [kecamatan, setKecamatan] = useState('');
  const [dataIbus, setDataIbus] = useState([]);
  const [dataIbusaring, setDataIbusaring] = useState([]);
  const [dataIbuBersalinFix, setDataIbuBersalinFix] = useState([]);

  const load = () => {
    const LoadDataIbuku = new LoadDataIbuBersalin();
    LoadDataIbuku.load(setDataIbusaring, data, setDataIbuBersalinFix);
  };

  useEffect(() => {
    load();
  }, []);

  // const getDataIbu = async () => {
  //   try {
  //     const response = await axios.get('https://test.mamaboi.net/api/kohort');

  //     setDataIbus(response.data.data);
  //     if (data !== 'Kabupaten') {
  //       const dataIbusaring = dataIbus.filter((r) => {
  //         return (
  //           r.puskesmas === data &&
  //           (r.statusIbu === 'Ibu hamil' ||
  //             r.statusIbu ===
  //               'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)')
  //         );
  //       });
  //       setDataIbusaring(dataIbusaring);
  //     } else {
  //       setDataIbusaring(dataIbus);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const deleteProduct = async (id) => {
    await axios.delete(API_MAMABOI + `kohort/${id}`);
    alert('data berhasil dihapus');
    // getDataIbu();
  };

  return (
    <div>
      <div className='detailsII'>
        <div className='dataIbu'>
          <div class='cardHeader'>
            <h2>#Data Ibu Bersalin</h2>
            <ReactHTMLTableToExcel
              className='btn'
              table='dataIbu'
              filename='export Data Ibu Bersalin'
              sheet='Data Ibu bersalin'
              buttonText='Export to Exl'
            />
          </div>

          <div className='table-wrapper'>
            <Table id='dataIbu' striped bordered hover size='sm'>
              <thead>
                <tr>
                  <th>No</th>
                  <th width='150px'>Action</th>
                  <th>Nama</th>
                  <th>Asal desa</th>
                  <th>Status</th>
                  <th>Puskesmas</th>
                  <th>Bidan</th>
                  <th>NoHp bidan</th>
                  <th>Desa bidan</th>
                  <th>Tempat Data</th>
                  <th>Foto Ibu</th>
                  <th>Tanggal Lahir</th>
                  <th>NIK</th>
                  <th>NoHp Ibu</th>
                  <th>Pendidikan</th>
                  <th>Pekerjaan</th>
                  <th>Desa</th>
                  <th>Gol darah</th>
                  <th>Pendonor darah</th>
                  <th>NoHp Pendonor</th>
                  <th>Status kawin</th>
                  <th>Foto rumah</th>
                  <th>Nama Suami</th>
                  <th>NoHp suami</th>
                  <th>Pekerjaan suami</th>
                  <th>Pendidikan suami</th>
                  <th>Alamat Suami </th>
                  <th>Suami merokok</th>
                  <th>PKH</th>
                  <th>BPNT</th>
                  <th>BST</th>
                  <th>Askes</th>
                  <th>Jumlah anak</th>
                  <th>Status ibu</th>
                  <th>Alasan menolak </th>
                  <th>Foto bukti menolak</th>
                  <th>GPA</th>
                  <th>Pernah keguguran</th>
                  <th>Tahun gugur</th>
                  <th>Bayi meninggal</th>
                  <th>Tahun meninggal</th>
                  <th>Tinggi</th>
                  <th>HPHT</th>
                  <th>Akses faskes</th>
                  <th>Transport</th>
                  <th>Biaya</th>
                  <th>Kendala intervensi</th>
                  <th>Pilihan faskes</th>
                  <th>Hamil terakhir</th>
                  <th>Hamil ke</th>
                  <th>Tahun nikah</th>
                  <th>Lahir tarikan tang</th>
                  <th>Lahir uri rogoh</th>
                  <th>Lahir infus</th>
                  <th>Sesar</th>
                  <th>Kurang darah</th>
                  <th>Malaria</th>
                  <th>TBC paru</th>
                  <th>Payah jantung</th>
                  <th>diabetes </th>
                  <th>PMS</th>
                  <th>Bengkak</th>
                  <th>Kembar</th>
                  <th>Hydromnion</th>
                  <th>Bayi mati kandungan</th>
                  <th>Lebih bulang</th>
                  <th>Letak sunsang </th>
                  <th>Letak lintang</th>
                  <th>Perdarahan</th>
                  <th>Kejang</th>
                  <th>Skor PR</th>

                  <th>Tanggal Input</th>

                  {/* <th>Tanggal Akhir Mens</th> */}
                </tr>
              </thead>
              <tbody>
                {dataIbusaring.map((dataibu, index) => (
                  <tr key={dataibu.id}>
                    <td> {index + 1}</td>
                    <td>
                      <Link to={`/layout/edit/${dataibu.id}`} className='btn'>
                        Edit
                      </Link>
                      {/* <Button
                        variant='danger'
                        onClick={() => deleteProduct(dataibu.id)}
                      >
                        hapus
                      </Button> */}
                    </td>
                    <td> {dataibu.namaIbu}</td>
                    <td> {dataibu.desaIbu}</td>
                    <td> {dataibu.status_Pj}</td>
                    <td> {dataibu.puskesmas}</td>
                    <td> {dataibu.bidan}</td>
                    <td> {dataibu.noHpBidan}</td>
                    <td> {dataibu.desaBidan}</td>
                    <td> {dataibu.tempatData}</td>
                    <td> {dataibu.fotoIbu}</td>
                    <td> {dataibu.tanggalLahirIbu}</td>
                    <td> {dataibu.nikIbu}</td>
                    <td> {dataibu.nomorHpIbu}</td>
                    <td> {dataibu.pendidikanIbu}</td>
                    <td> {dataibu.pekerjaanIbu}</td>
                    <td> {dataibu.desaIbu}</td>
                    <td> {dataibu.golDarahIbu}</td>
                    <td> {dataibu.pendonorDarah}</td>
                    <td> {dataibu.hpPendonor}</td>
                    <td> {dataibu.statusKawin}</td>
                    <td> {dataibu.fotoRumah}</td>
                    <td> {dataibu.namaSuami}</td>
                    <td> {dataibu.noHpSuami}</td>
                    <td> {dataibu.pekerjaanSuami}</td>
                    <td> {dataibu.pendidikanSuami}</td>
                    <td> {dataibu.alamatSuami}</td>
                    <td> {dataibu.suamiRokok}</td>
                    <td> {dataibu.pkh}</td>
                    <td> {dataibu.bpnt}</td>
                    <td> {dataibu.bst}</td>

                    <td> {dataibu.askes}</td>

                    <td> {dataibu.jumlahAnak}</td>
                    <td> {dataibu.statusIbu}</td>
                    <td> {dataibu.alasan}</td>
                    <td> {dataibu.fotoBukti}</td>
                    <td> {dataibu.gpa}</td>
                    <td> {dataibu.pernahGugur}</td>
                    <td> {dataibu.tahunGugur}</td>
                    <td> {dataibu.bayiMeninggal}</td>
                    <td> {dataibu.tahunMeninggal}</td>
                    <td> {dataibu.tinggi}</td>
                    <td> {dataibu.hpht}</td>
                    <td> {dataibu.aksesIbu}</td>

                    <td> {dataibu.transportIbu}</td>
                    <td> {dataibu.biayaIbu}</td>
                    <td> {dataibu.kendalaIntervensi}</td>
                    <td> {dataibu.pilihanFaskes}</td>
                    <td> {dataibu.hamilTerakhir}</td>
                    <td> {dataibu.hamilKe}</td>
                    <td> {dataibu.tahunNikah}</td>
                    <td> {dataibu.lahirTarikanTang}</td>
                    <td> {dataibu.lahirUriRogoh}</td>
                    <td> {dataibu.lahirInfus}</td>
                    <td> {dataibu.sesar}</td>
                    <td> {dataibu.kurangDarah}</td>
                    <td> {dataibu.malaria}</td>

                    <td> {dataibu.tbcParu}</td>
                    <td> {dataibu.payahJantung}</td>
                    <td> {dataibu.diabetes}</td>
                    <td> {dataibu.pms}</td>
                    <td> {dataibu.bengkak}</td>
                    <td> {dataibu.kembar}</td>
                    <td> {dataibu.hydromnion}</td>
                    <td> {dataibu.bayiMatiKandungan}</td>
                    <td> {dataibu.lebihBulan}</td>
                    <td> {dataibu.letakSunsang}</td>
                    <td> {dataibu.letakLintang}</td>
                    <td> {dataibu.perdarahan}</td>
                    <td> {dataibu.kejang}</td>

                    <td> {dataibu.skor_pj}</td>

                    <td> {dataibu.createdAt}</td>

                    {/* <td> {dataibu.tanggalMensAkhir}</td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataIbuBersalin;
