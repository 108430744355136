import React from 'react';
import { Bubble } from 'react-chartjs-2';
import './chartKunjungan.css';

const ChartKunjunganTD = (props) => {
  let grafik = props.data;
  let dataGrafikTD = [];

  dataGrafikTD = grafik.map((item) => {
    let td = item.tekananDarah;
    let coordinate = {};
    let tdArr = td.split('/');
    coordinate.x = parseInt(tdArr[0]);
    coordinate.y = parseInt(tdArr[1]);
    coordinate.r = 10;
    return coordinate;
  });

  // var ctx = document.getElementById('myChart').getContext('2d');
  // var myChart = new Chart(ctx, {
  //   type: 'bar',
  //   data: {
  //     labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  //     datasets: [
  //       {
  //         label: '# of Votes',
  //         data: [12, 19, 3, 5, 2, 3],
  //         backgroundColor: [
  //           'rgba(255, 99, 132, 0.2)',
  //           'rgba(54, 162, 235, 0.2)',
  //           'rgba(255, 206, 86, 0.2)',
  //           'rgba(75, 192, 192, 0.2)',
  //           'rgba(153, 102, 255, 0.2)',
  //           'rgba(255, 159, 64, 0.2)',
  //         ],
  //         borderColor: [
  //           'rgba(255, 99, 132, 1)',
  //           'rgba(54, 162, 235, 1)',
  //           'rgba(255, 206, 86, 1)',
  //           'rgba(75, 192, 192, 1)',
  //           'rgba(153, 102, 255, 1)',
  //           'rgba(255, 159, 64, 1)',
  //         ],
  //         borderWidth: 1,
  //       },
  //     ],
  //   },
  //   options: {
  //     scales: {
  //       y: {
  //         beginAtZero: true,
  //       },
  //     },
  //   },
  // });

  return (
    <div className='graphBoxKunjungan'>
      <div className='box'>
        <Bubble
          data={{
            datasets: [
              {
                label: 'Tekanan darah ibu',
                data: dataGrafikTD,
                backgroundColor: 'rgb(255, 99, 132)',
              },
            ],
          }}
          options={{
            responsive: true,
          }}
        />
      </div>
    </div>
  );
};

export default ChartKunjunganTD;
