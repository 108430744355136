import React, { useState, useEffect } from 'react';
import puskesDesa from '../../assets/JsonData/Puskes_Desa.json';
import axios from 'axios';
import './css/register.css';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Table } from 'react-bootstrap';
import LoadDataProfile from '../task/LoadDataProfile';
import { API_MAMABOI } from '../../utils/constant';

const EditUser = ({
  email,
  nama,
  akses,
  desaKu,

  noHpKu,
  users,
}) => {
  const [userName, setUserName] = useState(email);
  const [name, setName] = useState(nama);
  const [password, setPassword] = useState('');

  const [access, setAccess] = useState(akses);
  const [desa, setDesa] = useState(desaKu);
  const [noHp, setNoHp] = useState(noHpKu);

  const [profile, setProfile] = useState('');
  const [foto, setFoto] = useState([]);
  const [desaList, setDesaList] = useState([]);
  const history = useHistory();
  const [urlFile1, setUrlFile1] = useState();

  const load = () => {
    const LoadProfil = new LoadDataProfile();
    LoadProfil.load(setUrlFile1, nama);
  };

  function changePuskes(e) {
    setAccess(e.target.value);
    const desaku = puskesDesa.filter((r) => {
      return r.Puskesmas === e.target.value;
    });

    setDesaList(desaku);
  }

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setFoto(e.target.files[0]);
  }

  const isiData = async () => {
    var idUser = users.filter((r) => {
      return r.email === email;
    });

    if (idUser.length > 0) {
      setDesa(idUser[0].desa);
      setNoHp(idUser[0].noHp);
    }
  };

  useEffect(() => {
    isiData();
    load();
  }, []);

  const saveProfile = async (e) => {
    const formData = new FormData();
    formData.append('photo', foto);
    formData.append('userName', nama);

    const response = await axios.post(API_MAMABOI + `profile`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.data.success === 1) {
      alert('Foto Profil berhasil diupload');
      history.push('/layout');
    }
  };

  const saveUser = async (e) => {
    e.preventDefault();

    //'https://test.mamaboi.net/api/users/login',

    //

    var idUser = users.filter((r) => {
      return r.email === email;
    });

    var idKu = '';
    if (idUser.length > 0) {
      idKu = idUser[0].id;
    }
    //http://localhost:4000/
    //https://test.mamaboi.net/api/users
    try {
      const response = await axios.patch(API_MAMABOI + `users/${idKu}`, {
        email: userName,
        name: name,
        password: password,

        access: access,
        desa: desa,
        noHp: noHp,
      });

      history.push('/layout');
    } catch (error) {
      if (error.response) {
        //setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <div className='detailsI'>
        <form onSubmit={saveUser}>
          <div className='col-md-12'>
            <label for='inputEmail4' className='form-label'>
              Email / username
            </label>
            <input
              type='email'
              className='form-control'
              value={userName}
              id='inputEmail4'
              onChange={(e) => setUserName(e.target.value)}
              disabled
            />
          </div>
          <div class='col-md-12'>
            <label for='inputPassword4' className='form-label'>
              Ganti Password anda ?
            </label>
            <input
              type='password'
              className='form-control'
              id='inputPassword4'
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div class='col-md-12'>
            <label for='inputAddress' className='form-label'>
              Nama lengkap
            </label>
            <input
              type='text'
              className='form-control'
              id='inputAddress'
              placeholder='Nama lengkap'
              value={nama}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div class='col-md-12'>
            <label for='inputPassword4' className='form-label'>
              No Handphone
            </label>
            <input
              type='number'
              value={noHp}
              className='form-control'
              onChange={(e) => setNoHp(e.target.value)}
              required
            />
          </div>

          <div class='col-md-12'>
            <label for='inputState' className='form-label'>
              Hak akses
            </label>
            <input
              type='text'
              className='form-control'
              value={akses}
              onChange={changePuskes}
              disabled
            />
          </div>
          <div class='col-md-12'>
            <label for='inputState' className='form-label'>
              Desa
            </label>
            <input
              type='text'
              className='form-control'
              value={desa}
              onChange={(e) => setDesa(e.target.value)}
              disabled
            />
          </div>
          <div className='col-12'>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='checkbox'
                id='gridCheck'
              />
              <label className='form-check-label' for='gridCheck'>
                Check bila data sudah benar
              </label>
            </div>
          </div>
          <div class='col-12'>
            <button
              type='submit'
              className='btn btn-primary tombol'
              width={'100%'}
            >
              Edit data user
            </button>
          </div>
        </form>
        <div className='mb-3'>
          <form onSubmit={saveProfile}>
            <div>
              <img
                src={urlFile1}
                alt=''
                width={'100%'}
                className='imgProfile'
              />
            </div>

            <label className='file-label'>
              <input
                className='form-control'
                type='file'
                name='resume'
                onChange={onChange1}
                accept={'image/*'}
                required
              />
              <span className='file-cta'>
                <span class='file-icon'>
                  <i class='fas fa-upload'></i>
                </span>
              </span>
            </label>
            <div class='col-12'>
              <button className='btn btn-primary tombol' type='submit'>
                Upload foto profile
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
