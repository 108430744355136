import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <div>
      <div class='footer'>
        <h1 class='credit'>
          powered by <a href='#'>Informatika - DKISP Rote Ndao</a> &copy;
          copyright @ 2022
        </h1>
      </div>
    </div>
  );
};

export default Footer;
