import axios from 'axios';
import { API_MAMABOI } from '../../utils/constant';

class LoadDataIbuBersalin {
  // mendapatkan data
  load = (setData, access, setDataFix) => {
    this.setData = setData;
    this.access = access;
    this.setDataFix = setDataFix;
    this.#proccessData();
  };

  #proccessData = async () => {
    var DataIbuBersalin = [];
    var dataIbusaring = [];
    var dataBersalin = [];

    try {
      const response = await axios.get(API_MAMABOI + 'kohort');

      DataIbuBersalin = response.data.data;
    } catch (error) {
      console.error(error);
    }

    // ambil data ibu bersalin
    try {
      const response = await axios.get(API_MAMABOI + 'bersalin');
      dataBersalin = response.data.data;
    } catch (error) {
      console.error(error);
    }

    // for (var i = 0; i < dataBersalin.length; i++) {
    // dataBersalin.push(DataIbuBersalin[i][6], DataIbuBersalin[i][1]);
    //}

    //_______
    let com1 = 'Ibu bersalin';
    let com2 = 'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)';
    let com3 = '';
    var equal = '';

    if (this.access !== 'Kabupaten') {
      dataIbusaring = DataIbuBersalin.filter((r) => {
        com3 = r.statusIbu;
        return (
          r.puskesmas.toUpperCase() == this.access.toUpperCase() &&
          com3.toUpperCase() == com1.toUpperCase()
        );
      });
    } else {
      dataIbusaring = DataIbuBersalin.filter((r) => {
        com3 = r.statusIbu;

        return com3.toUpperCase() === com1.toUpperCase();
      });
    }

    dataIbusaring.sort((a, b) => {
      if (a.namaIbu < b.namaIbu) return -1;
      return a.namaIbu > b.namaIbu ? 1 : 0;
    });

    this.setData(dataIbusaring);
    this.setDataFix(dataIbusaring);

    //____________
  };
}

export default LoadDataIbuBersalin;
