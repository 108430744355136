import axios from 'axios';
import { API_MAMABOI } from '../../utils/constant';

class LoadDataIbu {
  // mendapatkan data
  load = (setData, access) => {
    this.setData = setData;
    this.access = access;
    this.#proccessData();
  };

  #proccessData = async () => {
    var dataIbuku = [];
    var dataIbusaring = [];
    var dataKunjungan = [];

    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbuku = response.data.data;
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await axios.get(API_MAMABOI + `kunjungan`);
      dataKunjungan = response.data.data;
    } catch (error) {
      console.error(error);
    }

    let com1 = 'Ibu hamil';
    let com2 = 'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)';
    let com3 = '';
    var equal = '';

    if (this.access !== 'Kabupaten') {
      dataIbusaring = dataIbuku.filter((r) => {
        com3 = r.statusIbu;
        return (
          r.puskesmas.toUpperCase() == this.access.toUpperCase() &&
          (com3.toUpperCase() == com1.toUpperCase() ||
            com3.toUpperCase() == com2.toUpperCase())
        );
      });
    } else {
      dataIbusaring = dataIbuku.filter((r) => {
        com3 = r.statusIbu;

        return (
          com3.toUpperCase() == com1.toUpperCase() ||
          com3.toUpperCase() == com2.toUpperCase()
        );
      });
    }

    var dataJoin = dataIbusaring.map((r) => {
      if (r.status_Pj == '') {
        var dataFilter = dataKunjungan.filter((row) => {
          return r.id == row.idIbu;
        });

        if (dataFilter.length > 0) {
          dataFilter.map((rr) => {
            if (rr.statusPasien !== '') {
              r.status_Pj = rr.statusPasien;
            }
            r.lila = '';
            if (dataFilter.length > 0) {
              dataFilter.map((rr) => {
                if (rr.statusPasien !== '') {
                  r.status_Pj = rr.statusPasien;
                }
                if (rr.lila !== '') {
                  r.lila = rr.lila;
                }
              });
            }
          });
        }
      }
      return r;
    });

    dataJoin.sort((a, b) => {
      if (a.namaIbu < b.namaIbu) return -1;
      return a.namaIbu > b.namaIbu ? 1 : 0;
    });

    this.setData(dataJoin);
  };
}

export default LoadDataIbu;
