import React, { useRef, useState, useEffect } from 'react';
import Navigation from '../navigation/Navigation';
import NavigationBootsrap from '../topbar/NavigationBootstrap';

import './layout.css';
import Register from '../pages/register';
import EditUser from '../pages/EditUser';

import Footer from '../footer/Footer';
import jwt_decode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';

import Dashboard from '../pages/Dasboard';
import DataIbu from '../pages/DataIbu';
import Input from '../pages/Input';
import Kunjungan from '../pages/Kunjungan';
import Edit from '../pages/Edit';
import Bersalin from '../pages/Bersalin';
import User from '../pages/User';
import RujukanIbu from '../pages/RujukanIbu';
import RujukanBayi from '../pages/RujukanBayi';
import DataIbuBersalin from '../pages/DataIbuBersalin';
import Pesan40Hari from '../pages/Pesan40Hari';
import Pesan2m from '../pages/Pesan2m';
import PesanEdukasi from '../pages/PesanEdukasi';
import DataRujukanIbu from '../pages/DataRujukanIbu';
import DataRujukanBayi from '../pages/DataRujukanBayi';
import DataKunjunganIbu from '../pages/DataKunjunganIbu';
import Analitycs from '../pages/Analitycs';
import KunjunganBersalin from '../pages/KunjunganBersalin';

import TopNav from '../topbar/TopNav';
import Forum from '../pages/Forum';
import Terminate from '../pages/Terminate';
import swal from 'sweetalert';

import { API_MAMABOI } from '../../utils/constant';

function Layout2() {
  const [name, setName] = useState('');
  const [id, setId] = useState(2);
  const [email, setEmail] = useState('');
  const [access, setAccess] = useState(`Kabupaten`);
  const [desa, setDesa] = useState('');
  const [noHp, setNoHp] = useState('');
  const [token, setToken] = useState('');
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [expire, setExpire] = useState('');
  const [users, setUsers] = useState([]);
  const [profilKu, setProfileKu] = useState('');

  let tog = useRef(null);
  let navigation = useRef(null);
  let main = useRef(null);

  let akses = '';

  let navLi = useRef(null);

  useEffect(() => {
    refreshToken();
    //S getUsers();
  }, []);
  useEffect(() => {
    if (email) {
      getUsers();
    }
  }, [email]);

  //'https://test.mamaboi.net/api/users/token'
  //http://localhost:4000/api/users/token

  const refreshToken = async () => {
    try {
      const response = await axios.get(API_MAMABOI + 'users/token');
      if (response.data.success == 0) {
        history.push('/');
        swal(
          'Failed',
          'Seseorang sementara login dengan akun yang sama sehingga anda dikeluarkan dari sistem',
          'error'
        );
      } else {
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setName(decoded.name);
        setId(decoded.id);
        setEmail(decoded.email);
        setAccess(decoded.access);
        setNoHp(decoded.noHp);
        setDesa(decoded.desa);
        setPassword(decoded.password);
        setExpire(decoded.exp);
      }
    } catch (error) {
      if (error.response) {
        history.push('/');
        swal('Failed', 'Unautorized', 'error');
      }
    }
  };
  const axiosJWT = axios.create();

  //'https://test.mamaboi.net/api/users/token'
  //http://localhost:4000/api/users/token

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(API_MAMABOI + 'users/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setName(decoded.name);
        setEmail(decoded.email);
        setId(decoded.id);
        setDesa(decoded.desa);
        setNoHp(decoded.noHp);
        setPassword(decoded.password);
        setAccess(decoded.access);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //'https://test.mamaboi.net/api/users'
  //http://localhost:4000/api/users

  const getUsers = async () => {
    const response = await axiosJWT.get(API_MAMABOI + 'users', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setUsers(response.data.data);
  };

  function onClick() {
    navigation.current.classList.toggle('active');
    main.current.classList.toggle('active');
  }

  let list = document.querySelectorAll('.navigation ul li');
  function activeLink() {
    list.forEach((item) => item.classList.remove('hovered'));
    this.classList.add('hovered');
  }
  list.forEach((item) => item.addEventListener('mouseover', activeLink));

  return (
    <HashRouter>
      <div>
        <div ref={navigation} className='navigation'>
          <NavigationBootsrap access={access} email={email} />
        </div>
        <div ref={main} className='main' id='main'>
          <div className='topbarkuk'>
            <TopNav access={access} nama={name} email={email} />
          </div>
          {/* <div className='topbar'>
            <div className='toggle' onClick={onClick}>
              <i className='bx bx-menu'></i>
            </div>
            
            <div>
              <span className='access'>akses : {access}</span>
            </div>
            <div className='field'>
              <div
                className='select is-fullwidth'
                value={access}
                onChange={(e) => setAccess(e.target.value)}
              >
                <select>
                  {puskesmasList.map((puskesmas, index) => (
                    <option>{puskesmas.puskesmas}</option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <span className='access'>welcome {name}</span>{' '}
            </div>
            <div>
              <div className='user'>
                <img id='user' src={user} alt='' />
              </div>
            </div>
          </div> */}

          <div>
            <Switch>
              <Route exact path='/layout' component={Dashboard}>
                <Dashboard data={access} />
              </Route>

              <Route path='/layout/dataibu'>
                <DataIbu data={access} />
              </Route>
              <Route path='/layout/input'>
                <Input data={access} nama={name} />
              </Route>
              <Route
                path='/layout/kunjungan'
                render={() => <Kunjungan data={access} />}
              />
              <Route
                path='/layout/edit/:id'
                render={() => <Edit data={access} />}
              />
              <Route
                path='/layout/bersalin'
                render={() => <Bersalin data={access} />}
              />
              <Route
                path='/layout/laporan'
                render={() => <User data={access} />}
              />
              <Route
                path='/layout/terminate'
                render={() => <Terminate data={access} />}
              />
              <Route
                path='/layout/user'
                render={() => (
                  <EditUser
                    email={email}
                    nama={name}
                    akses={access}
                    desaKu={desa}
                    noHpKu={noHp}
                    users={users}
                    profil={profilKu}
                  />
                )}
              />
              <Route
                path='/layout/register'
                render={() => <Register data={users} />}
              />
              <Route
                path='/layout/rujukanibu'
                render={() => <RujukanIbu data={access} />}
              />
              <Route
                path='/layout/datarujukanibu'
                render={() => <DataRujukanIbu data={access} />}
              />
              <Route
                path='/layout/rujukanbayi'
                render={() => <RujukanBayi data={access} />}
              />
              <Route
                path='/layout/datarujukanbayi'
                render={() => <DataRujukanBayi data={access} />}
              />
              <Route
                path='/layout/datakunjunganibu'
                render={() => <DataKunjunganIbu data={access} />}
              />
              <Route
                path='/layout/dataibubersalin'
                render={() => <DataIbuBersalin data={access} />}
              />
              <Route
                path='/layout/kunjunganbersalin'
                render={() => <KunjunganBersalin data={access} />}
              />
              <Route
                path='/layout/pesan40hari'
                render={() => <Pesan40Hari data={access} nama={name} />}
              />
              <Route
                path='/layout/pesan2minggu'
                render={() => <Pesan2m data={access} nama={name} />}
              />
              <Route
                path='/layout/pesanedukasi'
                render={() => <PesanEdukasi data={access} nama={name} />}
              />
              <Route
                path='/layout/forum'
                render={() => (
                  <Forum name={name} access={access} id={id} email={email} />
                )}
              />
              <Route path='/layout/analitycs'>
                <Analitycs data={access} />
              </Route>
            </Switch>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
    </HashRouter>
  );
}

export default Layout2;
