import React, { useState, useEffect } from 'react';
import './css/kunjungan.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import TableKunjungan from '../tableKunjungan/TableKunjungan';
import ChartKunjungan from '../chartKunjungan/ChartKunjungan';
import ChartKunjunganBerat from '../chartKunjungan/ChartKunjunganBerat';
import ChartKunjunganTD from '../chartKunjungan/ChartKunjungaTD';
import { Card, Button, Modal } from 'react-bootstrap';
import Progres from '../progressBar/Progres';
import LoadDataIbu from '../task/LoadDataIbu';
import { API_MAMABOI } from '../../utils/constant';

const Kunjungan = ({ data }) => {
  const [cariIbu, setCariIbu] = useState('');
  const [nama, setNama] = useState('');
  const [desa, setDesa] = useState('');
  const [hpht, setHpht] = useState('');
  const [statusPj, setStatusPj] = useState('');
  const [jumlahKunjungan, setJumlahKunjungan] = useState(0);
  const [kunjunganIbu, setKunjunganIbu] = useState([]);
  const [tanggalKun, setTanggalKun] = useState('');

  const [ibuHamil, setIbuHamil] = useState([]);
  const load = () => {
    const loadData = new LoadDataIbu();
    loadData.load(setIbuHamil, data);
  };

  useEffect(() => {
    load();
  }, [data]);

  const [perkiraanLahir, setPerkiraanLahir] = useState('');
  const [status, setStatus] = useState('');
  const [fotoIbu, setFoto] = useState('https://fakeimg.pl/250x250/');

  const [usiaHamil, setusiaHamil] = useState('');
  const [tekananDarah, settekananDarah] = useState('');

  const [tinggiFundus, settinggiFundus] = useState('');

  const [lila, setLila] = useState('');
  const [kek, setKek] = useState('');
  const [jantung, setJantung] = useState('');
  const [kepala, setKepala] = useState('');
  const [berat, setBerat] = useState('');
  const [persentase, setPersentase] = useState('');
  const [statusPasien, setStatuspasien] = useState('');
  const [bumilResiko, setBumilResiko] = useState('');
  const [bukuKia, setBukuKia] = useState('');
  const [keluhan, setKeluhan] = useState('');

  const [tataLaksana, setTataLaksana] = useState('');
  const [konselingP4k, setKonselingP4k] = useState('');
  const [imunisasiTT, setImunisasiTT] = useState('');
  const [hepatitis, setHepatitis] = useState('');
  const [tabletTD, setTabletTD] = useState('');
  const [hb, setHb] = useState('');
  const [tripleEliminasi, setTripleEliminasi] = useState('');
  const [hiv, setHiv] = useState('');
  const [malaria, setMalaria] = useState('');

  const [tb, setTb] = useState('');
  const [ims, setIms] = useState('');
  const [golDarah, setGolDarah] = useState('');
  const [komplikasi, setKomplikasi] = useState('');
  const [alasanRujuk, setAlasanRujuk] = useState('');
  const [posterTinggi, setPosterTinggi] = useState('');

  const [uploadPercentage, setUploadPercentage] = useState(0);

  //MODAL
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  //MODAL

  const [urlFotoPoster, setUrlFotoPoster] = useState(
    'https://fakeimg.pl/250x250/'
  );
  const [fotoPoster, setFotoPoster] = useState('');
  const [dukunganKeluarga, setDukunganKeluarga] = useState('');

  const [urlUploadDukungan, setUrlUploadDukungan] = useState(
    'https://fakeimg.pl/250x250/'
  );
  const [uploadDukungan, setUploadDukungan] = useState('');

  const [idIbu, setIdIbu] = useState('');

  const [resiko, setResiko] = useState({
    satu: false,
    dua: false,
    tiga: false,
    empat: false,
    lima: false,
    enam: false,
  });

  function changeCheck(e) {
    setResiko({ [e.target.name]: e.target.checked });
  }

  const history = useHistory();
  // function cariData() {
  //   const response = axios.get('http://localhost:5000/products');
  //   const dataIbu = response.data;
  //   console.log(dataIbu);
  // }

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFotoPoster(URL.createObjectURL(uploaded));
    setFotoPoster(e.target.files[0]);
  }
  function onChange2(e) {
    let uploaded = e.target.files[0];
    setUrlUploadDukungan(URL.createObjectURL(uploaded));
    setUploadDukungan(e.target.files[0]);
  }

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    setPerkiraanLahir(result.toDateString());
  };

  const cariDataKunjungan = async (idIbu) => {
    var dataKunjungan;
    try {
      const response = await axios.get(API_MAMABOI + `kunjungan`);

      dataKunjungan = response.data.data;
    } catch (error) {
      console.error(error);
    }

    const kunjungan = dataKunjungan.filter(function (item) {
      return item.idIbu === idIbu;
    });

    setJumlahKunjungan(kunjungan.length);
    setKunjunganIbu(kunjungan);
  };

  const cariData = async (e) => {
    var dataIbus;
    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbus = response.data.data;
    } catch (error) {
      console.error(error);
    }

    if (dataIbus == null) {
      alert('data tidak ditemukan');
      setNama('');
      return;
    }

    let temukanIbu = dataIbus.find((item) => {
      var a = item.namaIbu.split(' ').join('');
      var b = e.target.value.split(' ').join('');
      return a === b;
    });

    if (temukanIbu != null) {
      setNama(temukanIbu.namaIbu);
      setDesa(temukanIbu.desaIbu);
      setHpht(temukanIbu.hpht);
      setFoto(temukanIbu.fotoIbu);
      setStatus(temukanIbu.statusIbu);
      setStatusPj(temukanIbu.statusPj);
      setIdIbu(temukanIbu.id);
      cariDataKunjungan(temukanIbu.id);
      const date = new Date(temukanIbu.hpht);
      addDays(date, 280);
    } else {
      alert('Data Ibu tidak ditemukan. Coba lagi beberapa saat');
    }
  };

  const saveKunjungan = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('usiaHamil', usiaHamil);
    formData.append('tekananDarah', tekananDarah);
    formData.append('tinggiFundus', tinggiFundus);
    formData.append('lila', lila);
    formData.append('kek', kek);
    formData.append('jantung', jantung);
    formData.append('kepala', kepala);
    formData.append('berat', berat);
    formData.append('persentase', persentase);
    formData.append('statusPasien', statusPasien);

    if (resiko.satu === true) {
      setBumilResiko('Umur < 20 tahun');
    }
    if (resiko.dua === true) {
      setBumilResiko(bumilResiko.concat(',', 'Umur > 35 tahun'));
    }
    if (resiko.tiga === true) {
      setBumilResiko(bumilResiko.concat(',', ' Paritas > 4'));
    }
    if (resiko.empat === true) {
      setBumilResiko(bumilResiko.concat(',', 'jarak kehamilan < 2 tahun'));
    }
    if (resiko.lima === true) {
      setBumilResiko(bumilResiko.concat(',', ' TB < 145 cm'));
    }
    if (resiko.enam === true) {
      setBumilResiko(bumilResiko.concat(',', `BB trimester III < 45 kg`));
    }

    formData.append('bumilResiko', bumilResiko);
    formData.append('bukuKia', bukuKia);
    formData.append('keluhan', keluhan);

    formData.append('tataLaksana', tataLaksana);
    formData.append('konselingP4K', konselingP4k);
    formData.append('imunisasiTT', imunisasiTT);
    formData.append('hepatitis', hepatitis);
    formData.append('tabletTD', tabletTD);

    formData.append('hb', hb);
    formData.append('tripleEliminasi', tripleEliminasi);
    formData.append('hiv', hiv);
    formData.append('malaria', malaria);

    formData.append('tb', tb);
    formData.append('ims', ims);
    formData.append('golDarah', golDarah);
    formData.append('komplikasi', komplikasi);
    formData.append('alasanRujuk', alasanRujuk);
    formData.append('posterTinggi', posterTinggi);
    formData.append('photo', fotoPoster);
    formData.append('dukunganKeluarga', dukunganKeluarga);
    formData.append('photo', uploadDukungan);
    formData.append('idIbu', idIbu);
    formData.append('tanggalKun', tanggalKun);

    //https://test.mamaboi.net/api/kunjungan

    const response = await axios.post(API_MAMABOI + 'kunjungan', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (ProgressEvent) => {
        setUploadPercentage(
          parseInt(
            Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
          )
        );

        // console.log(uploadPercentage);
        // //clear percentage
        // setTimeout(() => setUploadPercentage(0), 1000);
      },
    });

    if (response.data.success === 1) {
      setShow2(false);
      alert('Data Kunjungan Ibu berhasil diinput');
      history.push('/layout/dataibu');
      history.push('/layout/kunjungan');
    }
  };

  return (
    <div>
      <div className='detailsII'>
        <div className='dataIbu'>
          <div class='mb-3'>
            <label for='inputState' className='form-label'>
              Nama Ibu hamil berkunjung
            </label>
            <select id='inputState' className='form-select' onChange={cariData}>
              <option value='' disabled selected>
                Pilih nama ibu berkunjung
              </option>
              {ibuHamil.map((ibu, index) => (
                <option>{ibu.namaIbu}</option>
              ))}
            </select>
          </div>
        </div>
        {/* <div className='dataIbu'>
          <div className='field'>
            <label className='label'>Desa</label>
            <div
            // className='select is-fullwidth'
            // value={kecamatan}
            // onChange={(e) => setKecamatan(e.target.value)}
            >
              <select>
                <option>Pilih nama Desa</option>
                <option>Rumah</option>
                <option>Posyandu</option>
                <option>Puskesmas</option>
                <option>Rumah Sakit</option>
                <option>Lainya</option>
              </select>
            </div>
          </div>
        </div> */}
      </div>
      {nama === '' ? (
        <div></div>
      ) : (
        <div>
          <div className='detailsI'>
            <div className='dataIbu'>
              <div className='field'>
                <Card>
                  <Card.Header>Ibu Hamil</Card.Header>
                  <Card.Body>
                    <Card.Title>Data dasar</Card.Title>
                    <Card.Text>
                      <table>
                        <tr>
                          <td>
                            <label className='label'>Nama Ibu</label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{nama}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className='label'>Alamat</label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{desa}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className='label'>
                              Hari Pertama Haid Terakhir
                            </label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{hpht}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className='label'>Status Ibu</label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{status}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className='label'>
                              Perkiraan Melahirkan
                            </label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{perkiraanLahir}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className='label'>
                              Status Puji Rochjati
                            </label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{statusPj}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className='label'>
                              Jumlah kunjungan Ibu
                            </label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{jumlahKunjungan}</label>
                          </td>
                        </tr>
                      </table>
                    </Card.Text>
                    <Button variant='primary' onClick={() => setShow2(true)}>
                      catat kunjungan baru
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className='dataIbu'>
              <Card style={{ width: '18rem' }}>
                <Card.Img variant='top' src={fotoIbu} alt='profile' />
                <Card.Body>
                  <Card.Title>Ny. {nama}</Card.Title>
                  <Card.Text>status : {status}</Card.Text>
                  <Button variant='primary' onClick={() => setShow(true)}>
                    Lihat data kunjungan
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </div>

          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName='modal-90w'
            aria-labelledby='example-custom-modal-styling-title'
          >
            <Modal.Header closeButton>
              <Modal.Title id='example-custom-modal-styling-title'>
                Detail data kunjungan
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='detailsII'>
                <div className='dataIbu'>
                  <TableKunjungan data={kunjunganIbu} />
                </div>
              </div>
              <div className='detailsII'>
                <div className='dataIbu'>
                  <ChartKunjungan data={kunjunganIbu} />
                </div>
                <div className='dataIbu'>
                  <ChartKunjunganBerat data={kunjunganIbu} />
                </div>
              </div>
              <div className='detailsII'>
                <ChartKunjunganTD data={kunjunganIbu} />
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={show2}
            onHide={() => setShow2(false)}
            dialogClassName='modal-90w'
            aria-labelledby='example-custom-modal-styling-title'
          >
            <Modal.Header closeButton>
              <Modal.Title id='example-custom-modal-styling-title'>
                Catat kunjungan
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='detailsII'>
                <div className='dataIbu'>
                  <h2> Kunjungan ke {jumlahKunjungan + 1}</h2>
                  <form onSubmit={saveKunjungan}>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Tanggal kunjungan
                      </label>
                      <input
                        className='form-control'
                        type='date'
                        value={tanggalKun}
                        onChange={(e) => setTanggalKun(e.target.value)}
                        required
                      ></input>
                    </div>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Usia kehamilan
                      </label>
                      <input
                        className='form-control'
                        type='number'
                        placeholder='(minggu)'
                        value={usiaHamil}
                        onChange={(e) => setusiaHamil(e.target.value)}
                        required
                      ></input>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Tekanan darah
                      </label>
                      <input
                        className='form-control'
                        type='text'
                        placeholder='(mm/Hg)'
                        value={tekananDarah}
                        onChange={(e) => settekananDarah(e.target.value)}
                        required
                      ></input>
                    </div>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Tinggi fundus
                      </label>
                      <input
                        className='form-control'
                        type='number'
                        placeholder='Tinggi fundus'
                        value={tinggiFundus}
                        onChange={(e) => settinggiFundus(e.target.value)}
                        required
                      ></input>
                    </div>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Lingkar lengan atas
                      </label>
                      <input
                        className='form-control'
                        type='number'
                        placeholder='(cm)'
                        value={lila}
                        onChange={(e) => setLila(e.target.value)}
                        required
                      ></input>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Kategori kurang energi kronis (KEK) Ya = kurang dari
                        23.5 cm, tidak = lebih dari 23.5 cm
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={kek}
                        onChange={(e) => setKek(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Ya</option>
                        <option>Tidak</option>
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Denyut jantung janin
                      </label>
                      <input
                        className='form-control'
                        type='number'
                        placeholder='(per menit)'
                        value={jantung}
                        onChange={(e) => setJantung(e.target.value)}
                        required
                      ></input>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Kepala terhadap PAP
                      </label>
                      <input
                        className='form-control'
                        type='text'
                        placeholder='kepala'
                        value={kepala}
                        onChange={(e) => setKepala(e.target.value)}
                        required
                      ></input>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Tafsiran berat badan janin
                      </label>
                      <input
                        className='form-control'
                        type='number'
                        placeholder='berat (gram)'
                        value={berat}
                        onChange={(e) => setBerat(e.target.value)}
                        required
                      ></input>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Persentase
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={persentase}
                        onChange={(e) => setPersentase(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Kepala</option>
                        <option>Sunsang</option>
                        <option>Lintang</option>
                        <option>Oblig</option>
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Status pasien saat kunjungan
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={statusPasien}
                        onChange={(e) => setStatuspasien(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>KRR</option>
                        <option>KRT</option>
                        <option>KRST</option>
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Ibu hamil dengan faktor resiko
                      </label>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck}
                          value={resiko.satu}
                          name='satu'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Umur {'<'} 20 tahun
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck}
                          value={resiko.dua}
                          name='dua'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Umur {'>'} 35 tahun
                        </label>
                      </div>

                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck}
                          value={resiko.tiga}
                          name='tiga'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Paritas {'>'} 4
                        </label>
                      </div>

                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck}
                          value={resiko.empat}
                          name='empat'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          jarak kehamilan {'<'} 2 tahun
                        </label>
                      </div>

                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck}
                          value={resiko.lima}
                          name='lima'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          TB {'<'} 145 cm
                        </label>
                      </div>

                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck}
                          value={resiko.enam}
                          name='enam'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          BB trimester III {'<'} 45 kg
                        </label>
                      </div>

                      {/* setBumilResiko(e.target.value)} */}
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Ibu dapat buku KIA
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={bukuKia}
                        onChange={(e) => setBukuKia(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Ya</option>
                        <option>Tidak</option>
                      </select>
                    </div>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Keluhan pada saat kunjung
                      </label>
                      <input
                        className='form-control'
                        type='text'
                        placeholder='keluhan'
                        value={keluhan}
                        onChange={(e) => setKeluhan(e.target.value)}
                        required
                      ></input>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Tatalaksana
                      </label>
                      <input
                        className='form-control'
                        type='text'
                        placeholder='Tatalaksana'
                        value={tataLaksana}
                        onChange={(e) => setTataLaksana(e.target.value)}
                        required
                      ></input>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Apakah ibu mendapatkan konseling P4K
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={konselingP4k}
                        onChange={(e) => setKonselingP4k(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Ya</option>
                        <option>Tidak</option>
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Imunisasi TT (status imunasasi TT1 - TT5)
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={imunisasiTT}
                        onChange={(e) => setImunisasiTT(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>TT1</option>
                        <option>TT2</option>
                        <option>TT3</option>
                        <option>TT4</option>
                        <option>TT5</option>
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Apakah bumil mendapatkan Tablet tambah darah 90 tablet
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={tabletTD}
                        onChange={(e) => setTabletTD(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Ya</option>
                        <option>Tidak</option>
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Pemeriksaan HB
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={hb}
                        onChange={(e) => setHb(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Tidak</option>
                        <option>Normal</option>
                        <option>Anemia ringan</option>
                        <option>Anemia sedang</option>
                        <option>Anemia berat</option>
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Apakah dilakukan pemeriksaan tripel eliminasi
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={tripleEliminasi}
                        onChange={(e) => setTripleEliminasi(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Ya</option>
                        <option>Tidak</option>
                      </select>
                    </div>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Hasil pemeriksaan hepatitis
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={hepatitis}
                        onChange={(e) => setHepatitis(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Negatif</option>
                        <option>Positif</option>
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Hasil pemeriksaan HIV
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={hiv}
                        onChange={(e) => setHiv(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Negatif</option>
                        <option>Positif</option>
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Hasil pemeriksaan Malaria
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={malaria}
                        onChange={(e) => setMalaria(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Negatif</option>
                        <option>Positif</option>
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Hasil pemeriksaan TB
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={tb}
                        onChange={(e) => setTb(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Negatif</option>
                        <option>Positif</option>
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Hasil pemeriksaan IMS
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={ims}
                        onChange={(e) => setIms(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Negatif</option>
                        <option>Positif</option>
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Hasil pemeriksaan golongan darah
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={golDarah}
                        onChange={(e) => setGolDarah(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>A</option>
                        <option>B</option>
                        <option>O</option>
                        <option>AB</option>
                      </select>
                    </div>
                    {/* setKomplikasi(e.target.value) */}

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Keadaan komplikasi ibu saat hamil ini yang di tangani
                      </label>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value=''
                          name='satu'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Infeksi
                        </label>
                      </div>

                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value=''
                          name='satu'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Perdarahan
                        </label>
                      </div>

                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value=''
                          name='satu'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          preeklamsia
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value=''
                          name='satu'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          eklamsia
                        </label>
                      </div>

                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value=''
                          name='satu'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          HB{'<'} 11 gr %
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value=''
                          name='satu'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          lila {'<'} 23.5
                        </label>
                      </div>

                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value=''
                          name='satu'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          kelainan letak janin
                        </label>
                      </div>

                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value=''
                          name='satu'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Ketuban Pecah Dini (KPD) HDK
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value=''
                          name='satu'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Penyakit penyerta lain
                        </label>
                      </div>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Alasan ibu hamil rujuk
                      </label>
                      <input
                        className='form-control'
                        type='text'
                        placeholder='alasan rujuk'
                        value={alasanRujuk}
                        onChange={(e) => setAlasanRujuk(e.target.value)}
                      ></input>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Apakah sudah dapat poster tinggi badan (smart chart)
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={posterTinggi}
                        onChange={(e) => setPosterTinggi(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Ya</option>
                        <option>Tidak</option>
                      </select>
                    </div>
                    {posterTinggi === 'Ya' ? (
                      <div className='mb-3'>
                        <label
                          for='exampleFormControlInput1'
                          className='form-label'
                        >
                          Foto poster tinggi badan
                        </label>
                        <div>
                          <img
                            src={urlFotoPoster}
                            className=''
                            alt=''
                            width={'300px'}
                          />
                        </div>
                        <div className='file has-name is-fullwidth'>
                          <label className='file-label'>
                            <input
                              className='file-input'
                              type='file'
                              name='resume'
                              onChange={onChange1}
                              accept={'image/*'}
                            />
                            <span className='file-cta'>
                              <span class='file-icon'>
                                <i class='fas fa-upload'></i>
                              </span>
                            </span>
                            {/* <span class='file-name'>{fotoBukti.name}</span> */}
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Adakah dukungan keluarga untuk bersalin di fasilitas
                        kesehatan
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={dukunganKeluarga}
                        onChange={(e) => setDukunganKeluarga(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Ya</option>
                        <option>Tidak</option>
                      </select>
                    </div>
                    {dukunganKeluarga === 'Tidak' ? (
                      <div className='mb-3'>
                        <label
                          for='exampleFormControlInput1'
                          className='form-label'
                        >
                          Foto bukti surat pernyataan menolak diperiksa
                        </label>
                        <div>
                          <img
                            src={urlUploadDukungan}
                            className=''
                            alt=''
                            width={'300px'}
                          />
                        </div>
                        <div className='file has-name is-fullwidth'>
                          <label className='file-label'>
                            <input
                              className='file-input'
                              type='file'
                              name='resume'
                              onChange={onChange2}
                              accept={'image/*'}
                            />
                            <span className='file-cta'>
                              <span class='file-icon'>
                                <i class='fas fa-upload'></i>
                              </span>
                            </span>
                            {/* <span class='file-name'>{fotoBukti.name}</span> */}
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className='mb-3'>
                      <Progres percentage={uploadPercentage} />
                    </div>
                    <div class='d-grid gap-2'>
                      <button className='btn btn-primary' type='submit'>
                        Simpan
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Kunjungan;
