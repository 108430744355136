import React, { useEffect, useState } from 'react';
import { Bar, Line, PolarArea } from 'react-chartjs-2';
import './chart.css';
import ChartHamilBulan from './ChartHamilBulan';
// import LoadDataGrafik from '../task/LoadDataGrafik';

const Chart = ({ data, access }) => {
  let grafik = data;
  let dataGrafik = [];
  dataGrafik = grafik.map((item) => {
    return item.count;
  });

  let grafikData = [];
  var i = 0;
  for (i = 0; i < 3; i++) {
    grafikData.push(dataGrafik[i]);
  }

  return (
    <div className='graphBox'>
      <div className='box'>
        <PolarArea
          data={{
            labels: ['Resti', 'Hamil', 'Bersalin'],
            datasets: [
              {
                label: '# of Votes',
                data: grafikData,
                backgroundColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
              },
            ],
          }}
          options={{
            responsive: true,
          }}
        />
      </div>
      <div className='box'>
        <ChartHamilBulan access={access} />
      </div>
    </div>
  );
};

export default Chart;
