import React, { useState, useEffect } from 'react';

import axios from 'axios';
import './css/register.css';
import { useHistory } from 'react-router-dom';
import { Form, Button, Badge, Modal, Table } from 'react-bootstrap';
import noHPKades from '../../assets/JsonData/NoHPKades.json';
import noHPCamat from '../../assets/JsonData/NoHPCamat.json';
import noHPBidan from '../../assets/JsonData/noHpBidan.json';
import desaKecamatan from '../../assets/JsonData/Puskes_Desa.json';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import LoadDataPesan2MPartus from '../task/LoadDataPesan2MPartus';
import { API_MAMABOI } from '../../utils/constant';

const Pesan2m = ({ data, nama }) => {
  const history = useHistory();
  const [pesanDialog, setPesanDialog] = useState('');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [checked, setChecked] = useState([]);
  const [dataPartus2m, setDataPartus2m] = useState([]);

  const handleShow = () => {
    if (checked.length === 0) {
      return;
    }
    setPesanDialog(
      'Apakah anda akan mengirimkan pesan tentang Ibu yang 2 Minggu lagi akan melahirkan kepada ' +
        checked +
        ' ?'
    );
    setShow(true);
  };

  const load = () => {
    const LoadDataHp2M = new LoadDataPesan2MPartus();
    LoadDataHp2M.load(setDataPartus2m, data);
  };

  useEffect(() => {
    load();
  }, [data]);

  const changeCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }

    setChecked(updatedList);
  };

  const savePesan = async (jenis, tujuan, sender) => {
    //'https://test.mamaboi.net/api/users/login',

    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    var hour = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getUTCSeconds();
    var newdate = year + '-' + month + '-' + day;
    var newdatetime =
      year +
      '-' +
      month +
      '-' +
      day +
      ' ' +
      hour +
      ':' +
      minutes +
      ':' +
      seconds;
    //

    var pesan = '2 Minggu lagi akan Melahirkan';
    try {
      const response = await axios.post(API_MAMABOI + 'pesan', {
        tanggal: newdatetime,
        jenis: jenis,
        tujuan: tujuan,
        pesan: pesan,
        sender: sender,
      });
    } catch (error) {
      if (error.response) {
        //setMsg(error.response.data.msg);
      }
    }
  };

  function kirimPesan() {
    var url = 'https://pati.wablas.com/api/send-message';
    var Tokenwhatsap =
      'IONVgbV7rm3bzVoDI4jjm9zRFwDmA6j1AzgzTDkjDAQBPgNSf1CTSZ3AtORKVfls';
    var nohp = '082237839018';
    //'081239619532'
    //082237839018
    var i = 0;

    checked.map((r) => {
      var kop = `🤰🤰 MAMA BO'I INFO 2 MINGGU MELAHIRKAN 🤰🤰 \n\ \n\ `;
      if (r == 'Ibu Hamil') {
        for (var i = 0; i < dataPartus2m.length; i++) {
          if (dataPartus2m[i].noHp !== '' || dataPartus2m[i].noHp !== '0') {
            var pesanKu =
              `Soda Molek Ibu *` +
              dataPartus2m[i].namaIbu +
              `*` +
              `\n\ \n\Data kami menunjukan bahwa dalam dua minggu ke depan ibu akan melahirkan` +
              `\n\ \n\Mohon menjaga kondisi kesehatan dan memperhatikan tanda - tanda lahiran. Segera mengunjungi Puskesmas/Pustu terdekat apabila ada indikasi melahirkan`;
            var data = {
              phone: dataPartus2m[i].noHp,
              message:
                kop + pesanKu + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user)
              .then((response) => response.text())
              .then((data) => console.log(data));
          }
        }
      }

      if (r == 'Kepala Desa') {
        for (var i = 0; i < dataPartus2m.length; i++) {
          var desa = dataPartus2m[i].desa;
          var hpKades = noHPKades.filter((rr) => {
            return rr.desa == desa;
          });

          if (hpKades.length > 0) {
            var pesanKu =
              `Soda Molek Bapa Desa *` +
              desa +
              `*` +
              `\n\Bersama ini kami informasikan bahwa salah satu warga Bapak yang berstatus Ibu Hamil` +
              `\n\Nama  : *` +
              dataPartus2m[i].namaIbu +
              `* \n\Usia kehamilan  : *` +
              dataPartus2m[i].usiaHamil +
              `* minggu` +
              `\n\No HP  : ` +
              dataPartus2m[i].hp +
              ` \n\ \n\Mohon bantuanya untuk melakukan pendampingan terhadap ibu dimaksud dan memastikan untuk proses melahirkan dilakukan di fasilitas kesehatan terdekat`;
            var data = {
              phone: hpKades[0].noHP,
              message:
                kop + pesanKu + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user)
              .then((response) => response.text())
              .then((data) => console.log(data));
          }
        }
      }
      if (r == 'Camat') {
        for (var i = 0; i < dataPartus2m.length; i++) {
          var desa = dataPartus2m[i].desa;
          var cariCamat = [];
          var cari = desaKecamatan.filter((rr) => {
            return rr.Desa == desa;
          });

          if (cari.length !== 0) {
            cariCamat = noHPCamat.filter((rr) => {
              return rr.kecamatan == cari[0].Kecamatan;
            });
          }

          if (cariCamat.length !== 0) {
            var pesanKu =
              `Soda Molek Bapa Camat *` +
              cariCamat[0].kecamatan +
              `*` +
              `\n\Bersama ini kami informasikan bahwa salah satu warga Bapak yang berstatus Ibu Hamil,` +
              `\n\Nama  : *` +
              dataPartus2m[i].namaIbu +
              `*` +
              `\n\Desa  : *` +
              dataPartus2m[i].desa +
              `* \n\Usia Kehamilan  : *` +
              dataPartus2m[i].usiaHamil +
              `* minggu` +
              `\n\No HP  : ` +
              dataPartus2m[i].noHp +
              ` \n\ \n\Mohon bantuanya untuk melakukan pendampingan terhadap ibu dimaksud dan memastikan untuk proses melahirkan dilakukan di fasilitas kesehatan terdekat.`;
            var data = {
              phone: cariCamat[0].noHp,
              message:
                kop + pesanKu + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user)
              .then((response) => response.text())
              .then((data) => console.log(data));
          }
        }
      }
      if (r == 'Bidan') {
        for (var i = 0; i < dataPartus2m.length; i++) {
          if (
            dataPartus2m[i].noHpBidan !== '' ||
            dataPartus2m[i].noHpBidan !== '0'
          ) {
            var pesanKu =
              `Soda Molek Bidan *` +
              dataPartus2m[i].bidan +
              `*` +
              `\n\Salah satu pasien Ibu` +
              `\n\Nama  : *` +
              dataPartus2m[i].namaIbu +
              `* \n\Usia Kehamilan  : *` +
              dataPartus2m[i].usiaHamil +
              `* minggu` +
              `\n\No HP  : ` +
              dataPartus2m[i].hp +
              ` \n\ \n\\Mohon bantuanya untuk melakukan pendampingan terhadap ibu dimaksud dan memastikan untuk proses melahirkan dilakukan di fasilitas kesehatan terdekat.`;
            var data = {
              phone: dataPartus2m[i].noHpBidan,
              message:
                kop + pesanKu + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user)
              .then((response) => response.text())
              .then((data) => console.log(data));
          }
        }
      }
      if (r == 'Suami') {
        for (var i = 0; i < dataPartus2m.length; i++) {
          if (
            dataPartus2m[i].hpSuami !== '' ||
            dataPartus2m[i].hpSuami !== '0'
          ) {
            var pesanKu =
              `Soda Molek Bapak *` +
              dataPartus2m[i].suami +
              '*' +
              `\n\Data kami menunjukan Ibu` +
              `\n\Nama  : *` +
              dataPartus2m[i].namaIbu +
              `* \n\Usia kehamilan  : *` +
              dataPartus2m[i].usiaHamil +
              `* hari` +
              `\n\No HP  : ` +
              dataPartus2m[i].hp +
              ` \n\ \n\Mohon bantuanya untuk melakukan pendampingan terhadap ibu dimaksud dan memastikan untuk proses melahirkan dilakukan di fasilitas kesehatan terdekat.`;
            var data = {
              phone: dataPartus2m[i].hpSuami,
              message:
                kop + pesanKu + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user)
              .then((response) => response.text())
              .then((data) => console.log(data));
          }
        }
      }
      if (r == 'Pendonor Darah') {
        for (var i = 0; i < dataPartus2m.length; i++) {
          if (
            dataPartus2m[i].hpPendonor !== '' ||
            dataPartus2m[i].hpPendonor !== '0'
          ) {
            var pesanKu =
              `Soda Molek Bapak/Ibu *` +
              dataPartus2m[i].pendonorDarah +
              '*' +
              `\n\sebagai PENDONOR DARAH bagi ibu hamil ` +
              `*` +
              dataPartus2m[i].namaIbu +
              `*, kami informasikan bahwa dalam dua minggu kedepan akan segera melahirkan. ` +
              dataPartus2m[i].usiaHamil +
              ` \n\Untuk itu dimohon bantuanya untuk bersiap apabila diperlukan dalam proses melahirkan ibu dimaksud. \n\Apabila berhalangan mohon diinformasikan sebelumnya`;
            var data = {
              phone: dataPartus2m[i].hpPendonor,
              message:
                kop + pesanKu + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user)
              .then((response) => response.text())
              .then((data) => console.log(data));
          }
        }
      }
      if (r == 'Tokoh Agama') {
        for (var i = 0; i < dataPartus2m.length; i++) {
          if (
            dataPartus2m[i].nomorTA !== '' ||
            dataPartus2m[i].nomorTA !== '0'
          ) {
            var pesanKu =
              `Soda Molek Bapak/Ibu *` +
              dataPartus2m[i].namaTA +
              '*' +
              `\n\Data kami menunjukan Ibu` +
              `\n\Nama  : *` +
              dataPartus2m[i].namaIbu +
              `* \n\Usia kehamilan  : *` +
              dataPartus2m[i].usiaHamil +
              `* hari` +
              `\n\No HP  : ` +
              dataPartus2m[i].hp +
              ` \n\ \n\Mohon bantuanya untuk melakukan pendampingan terhadap ibu dimaksud dan memastikan untuk proses melahirkan dilakukan di fasilitas kesehatan terdekat.`;
            var data = {
              phone: dataPartus2m[i].nomorTA,
              message:
                kop + pesanKu + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user)
              .then((response) => response.text())
              .then((data) => console.log(data));
          }
        }
      }
      if (r == 'Tokoh Budaya') {
        for (var i = 0; i < dataPartus2m.length; i++) {
          if (
            dataPartus2m[i].nomorTB !== '' ||
            dataPartus2m[i].nomorTB !== '0'
          ) {
            var pesanKu =
              `Soda Molek Bapak/Ibu *` +
              dataPartus2m[i].namaTB +
              '*' +
              `\n\Data kami menunjukan Ibu` +
              `\n\Nama  : *` +
              dataPartus2m[i].namaIbu +
              `* \n\Usia kehamilan  : *` +
              dataPartus2m[i].usiaHamil +
              `* hari` +
              `\n\No HP  : ` +
              dataPartus2m[i].hp +
              ` \n\ \n\Mohon bantuanya untuk melakukan pendampingan terhadap ibu dimaksud dan memastikan untuk proses melahirkan dilakukan di fasilitas kesehatan terdekat.`;
            var data = {
              phone: dataPartus2m[i].nomorTB,
              message:
                kop + pesanKu + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user)
              .then((response) => response.text())
              .then((data) => console.log(data));
          }
        }
      }
      if (r == 'Kader') {
        for (var i = 0; i < dataPartus2m.length; i++) {
          if (
            dataPartus2m[i].nomorKader !== '' ||
            dataPartus2m[i].nomorKader !== '0'
          ) {
            var pesanKu =
              `Soda Molek Bapak/Ibu *` +
              dataPartus2m[i].namaKader +
              '*' +
              `\n\Data kami menunjukan Ibu` +
              `\n\Nama  : *` +
              dataPartus2m[i].namaIbu +
              `* \n\Usia kehamilan  : *` +
              dataPartus2m[i].usiaHamil +
              `* hari` +
              `\n\No HP  : ` +
              dataPartus2m[i].hp +
              ` \n\ \n\Mohon bantuanya untuk melakukan pendampingan terhadap ibu dimaksud dan memastikan untuk proses melahirkan dilakukan di fasilitas kesehatan terdekat.`;
            var data = {
              phone: dataPartus2m[i].nomorKader,
              message:
                kop + pesanKu + `\n\ \n\ 🙏🙏🙏 \n\ Sekretariat Mama Bo'i`,
            };

            var options_user = {
              method: 'POST',
              headers: {
                Authorization: Tokenwhatsap,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            };

            var response = fetch(url, options_user)
              .then((response) => response.text())
              .then((data) => console.log(data));
          }
        }
      }
    });
    setShow(false);
    var jenis = 'Pesan 2M akan Melahirkan';
    var tujuan = checked;
    var sender = nama;
    savePesan(jenis, tujuan, sender);

    history.push('/layout');
    history.push('/layout/pesan2minggu');
    alert('pesan terkirim untuk ' + checked);
  }

  return (
    <div>
      <div className='details'>
        <div>
          <div className='recentCustomers'>
            <div className='cardHeader'>
              <h2>
                2 Minggu Partus <Badge bg='danger'>{dataPartus2m.length}</Badge>
              </h2>

              <ReactHTMLTableToExcel
                className='btn'
                table='dataIbu2M'
                filename='2 Minggu melahirkan'
                sheet='tidak berkunjung'
                buttonText='Export to Exl '
              />
            </div>
            <div className='table-wrapper'>
              <Table id='dataIbu2M'>
                {dataPartus2m.map((r, index) => (
                  <tr key={index}>
                    <td width='60px'>
                      <div className='imgBx'>
                        <img src={r.foto} alt='' />
                      </div>
                    </td>
                    <td>
                      <h4>
                        {r.namaIbu} <br />
                        <span>{r.puskesmas}</span>
                        <span>{'        ' + r.usiaHamil + ' minggu'}</span>
                        <span>{'        ' + r.desa}</span>
                        <span>{' ☎️ ' + r.noHp}</span>
                      </h4>
                    </td>
                  </tr>
                ))}
              </Table>
            </div>
          </div>
        </div>
        <Form>
          <h2>Sio Sodak</h2>
          <Form.Check
            type='switch'
            id='custom-switch1'
            label='Kepala Desa'
            class='form-check-input'
            onChange={changeCheck}
            value='Kepala Desa'
          />
          <Form.Check
            type='switch'
            id='custom-switch2'
            label='Camat'
            class='form-check-input'
            onChange={changeCheck}
            value='Camat'
          />
          <Form.Check
            type='switch'
            id='custom-switch3'
            label='Ibu Hamil'
            class='form-check-input'
            onChange={changeCheck}
            value='Ibu Hamil'
          />
          <Form.Check
            type='switch'
            id='custom-switch4'
            label='Pendonor Darah'
            class='form-check-input'
            onChange={changeCheck}
            value='Pendonor Darah'
          />
          <Form.Check
            type='switch'
            id='custom-switch5'
            label='Tokoh Agama'
            class='form-check-input'
            onChange={changeCheck}
            value='Tokoh Agama'
          />
          <Form.Check
            type='switch'
            id='custom-switch6'
            label='Tokoh Budaya'
            class='form-check-input'
            onChange={changeCheck}
            value='Tokoh Budaya'
          />
          <Form.Check
            type='switch'
            id='custom-switch7'
            label='Kader'
            class='form-check-input'
            onChange={changeCheck}
            value='Kader'
          />
          <Form.Check
            type='switch'
            id='custom-switch8'
            label='Bidan'
            class='form-check-input'
            onChange={changeCheck}
            value='Bidan'
          />
          <Form.Check
            type='switch'
            id='custom-switch9'
            label='Suami'
            class='form-check-input'
            onChange={changeCheck}
            value='Suami'
          />
          <div className='buttonBox'>
            <Button variant='primary' onClick={handleShow}>
              Kirim pesan <Badge bg='secondary'>2M</Badge>
              <span className='visually-hidden'>unread messages</span>
            </Button>
          </div>
        </Form>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pesan</Modal.Title>
        </Modal.Header>
        <Modal.Body>{pesanDialog}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={kirimPesan}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Pesan2m;
