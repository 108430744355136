import axios from 'axios';
import daftarPuskesmas from '../../assets/JsonData/daftarPuskesmas.json';
import { API_MAMABOI } from '../../utils/constant';

class LoadDataIbuFix {
  // mendapatkan data
  load = (setData, access, setDataFixku, setJumlahData) => {
    this.setData = setData;
    this.access = access;
    this.setDataFixku = setDataFixku;
    this.setJumlahData = setJumlahData;
    this.#proccessData();
  };

  #proccessData = async () => {
    var dataIbuku = [];
    var dataIbusaring = [];
    var dataKunjungan = [];

    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbuku = response.data.data;
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await axios.get(API_MAMABOI + `kunjungan`);
      dataKunjungan = response.data.data;
    } catch (error) {
      console.error(error);
    }

    let com1 = 'Ibu hamil';
    let com2 = 'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)';
    let com3 = '';
    var equal = '';

    if (this.access !== 'Kabupaten') {
      dataIbusaring = dataIbuku.filter((r) => {
        com3 = r.statusIbu;
        return (
          r.puskesmas.toUpperCase() == this.access.toUpperCase() &&
          (com3.toUpperCase() == com1.toUpperCase() ||
            com3.toUpperCase() == com2.toUpperCase())
        );
      });
    } else {
      dataIbusaring = dataIbuku.filter((r) => {
        com3 = r.statusIbu;

        return (
          r.puskesmas !== '' &&
          (com3.toUpperCase() == com1.toUpperCase() ||
            com3.toUpperCase() == com2.toUpperCase())
        );
      });
    }

    var dataJoin = dataIbusaring.map((r) => {
      if (r.status_Pj == '') {
        var dataFilter = dataKunjungan.filter((row) => {
          return r.id == row.idIbu;
        });
        r.lila = '';
        if (dataFilter.length > 0) {
          dataFilter.map((rr) => {
            if (rr.statusPasien !== '') {
              r.status_Pj = rr.statusPasien;
            }
            if (rr.lila !== '') {
              r.lila = rr.lila;
            } else {
              if (!r.lila) {
                r.lila = '';
              }
            }
          });
        }
      }
      return r;
    });

    dataJoin.sort((a, b) => {
      if (a.namaIbu < b.namaIbu) return -1;
      return a.namaIbu > b.namaIbu ? 1 : 0;
    });
    var dataChartOk = [];
    var dataChartKu = daftarPuskesmas.map((r) => {
      var data = dataJoin.filter((f) => {
        return r.puskesmas === f.puskesmas;
      });
      return [data.length];
    });
    dataChartOk = dataChartKu.map((r) => {
      return r[0];
    });
    this.setData(dataChartOk);
    this.setDataFixku(dataJoin);
    this.setJumlahData(dataJoin.length);
  };
}

export default LoadDataIbuFix;
