import PuskesDesa from '../../assets/JsonData/Puskes_Desa.json';

class LoadPetaAccess {
  // mendapatkan data
  load = (setZoom, setCenter, setDesaPuskes, access) => {
    this.setZoom = setZoom;
    this.setCenter = setCenter;
    this.setDesaPuskes = setDesaPuskes;
    this.access = access;
    this.#proccessData();
  };

  #proccessData = () => {
    var zoomCenter = [
      {
        name: 'Kabupaten',
        koordinat: [-10.752445, 123.127355],
        zoom: 10.2,
      },
      {
        name: 'Delha',
        koordinat: [-10.858225374311647, 122.85796900585532],
        zoom: 12.2,
      },
      {
        name: 'Batutua',
        koordinat: [-10.866963017381602, 122.93778430514685],
        zoom: 12.2,
      },
      ,
      {
        name: 'Ndao',
        koordinat: [-10.823068244905466, 122.70042863793482],
        zoom: 12.2,
      },
      {
        name: 'Busalangga',
        koordinat: [-10.795098501394149, 122.94770693546936],
        zoom: 12.2,
      },
      {
        name: `Ba'a`,
        koordinat: [-10.77416238620225, 123.07014215226727],
        zoom: 12.2,
      },
      {
        name: 'Oele',
        koordinat: [-10.788664668900418, 123.19682824897457],
        zoom: 12.2,
      },
      {
        name: 'Feapopi',
        koordinat: [-10.715784743881224, 123.1368223831096],
        zoom: 12.2,
      },
      {
        name: 'Sonimanu',
        koordinat: [-10.642764460925171, 123.28462041206107],
        zoom: 12.2,
      },
      {
        name: 'Korbafo',
        koordinat: [-10.642764460925171, 123.28462041206107],
        zoom: 12.2,
      },
      {
        name: 'Eahun',
        koordinat: [-10.641560682600872, 123.37158444822296],
        zoom: 12.2,
      },
      {
        name: 'Sotimori',
        koordinat: [-10.530656139962185, 123.33766929559646],
        zoom: 12.2,
      },
      {
        name: 'Oelaba',
        koordinat: [-10.795098501394149, 122.94770693546936],
        zoom: 12.2,
      },
    ];

    if (this.access !== 'Kabupaten') {
      const desaku = PuskesDesa.filter((r) => {
        return r.Puskesmas === this.access;
      });
      this.setDesaPuskes(desaku);
    } else {
      this.setDesaPuskes(PuskesDesa);
    }

    var puskes = zoomCenter.filter((item) => {
      return item.name == this.access;
    });

    this.setZoom(puskes[0].zoom);
    this.setCenter(puskes[0].koordinat);

    const desaPuskes = PuskesDesa.filter((r) => {
      return r.Puskesmas === this.access;
    });
  };
}

export default LoadPetaAccess;
