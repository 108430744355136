import React, { useEffect, useState } from 'react';
import Card from '../cards/Card';
import Chart from '../charts/Chart';
import Table from '../table/Table';
import Table2 from '../table2/Table2';
import TableTidakBerkunjung from '../tableTidakBerkunjung/TableTidakBerkunjung';
import MyMap from '../peta/MyMap';
import LoadDataCard from '../task/LoadDataCard';
import LoadDataCard2 from '../task/LoadDataCard2';
import LoadDataCardKematian from '../task/LoadDataCardKematian';
import { useHistory } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';

const Dasboard = ({ data }) => {
  const [dataStatusCard, setdataStatusCard] = useState([]);
  const [dataStatusCard2, setdataStatusCard2] = useState([]);
  const [dataStatusCardKematian, setdataStatusCardKematian] = useState([]);
  const [tableStatus, setTableStatus] = useState([]);

  const load = () => {
    const loadDataCard = new LoadDataCard();
    loadDataCard.load(setdataStatusCard, setTableStatus, data);
  };
  const load2 = () => {
    const loadDataCard2 = new LoadDataCard2();
    loadDataCard2.load(setdataStatusCard2, data);
  };
  const load3 = () => {
    const loadDataCardKematian = new LoadDataCardKematian();
    loadDataCardKematian.load(setdataStatusCardKematian, data);
  };

  const history = useHistory();
  useEffect(() => {
    if (data) {
      load();
      load2();
      load3();
    }
  }, [data]);

  // useEffect(load, []);

  return (
    <div className='bodyDisplay'>
      <div className='cardBox3'>
        {dataStatusCardKematian.map((item, index) => (
          <Card icon={item.icon} count={item.count} title={item.title} />
        ))}
      </div>
      <div className='cardBox'>
        {dataStatusCard.map((item, index) => (
          <Card icon={item.icon} count={item.count} title={item.title} />
        ))}
      </div>

      <div className='cardBox2'>
        {dataStatusCard2.map((item, index) => (
          <Card icon={item.icon} count={item.count} title={item.title} />
        ))}
      </div>
      <Chart data={dataStatusCard} access={data} />

      <div className='details'>
        <Table access={data} />
        <Table2 access={data} />
      </div>

      <Container fluid='md'>
        <Row>
          <TableTidakBerkunjung access={data} />
        </Row>
      </Container>
      <MyMap access={data} />
    </div>
  );
};

export default Dasboard;
