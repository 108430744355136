import './table.css';
import { useState, useEffect } from 'react';
import LoadData from '../task/LoadData';

const Table = ({ access }) => {
  const [dataHamilPus, setDataHamilPus] = useState([]);

  const load = () => {
    const loadData = new LoadData();
    loadData.load(setDataHamilPus, access);
  };

  useEffect(load, [access]);

  return (
    <div class='detail'>
      <div class='recentOrders'>
        <div class='cardHeader'>
          <h2>Ibu Hamil Puskesmas</h2>
        </div>
        {access === 'Kabupaten' ? (
          <div className='table-wrapper'>
            <table>
              <thead>
                <tr>
                  <th>Puskesmas</th>
                  <th>KRR</th>
                  <th>KRT</th>
                  <th>KRST</th>
                  <th>Total Hamil</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {dataHamilPus.map((r, index) => (
                  <tr key={index}>
                    <td> {r[0]}</td>
                    <td> {r[3]}</td>
                    <td> {r[4]}</td>
                    <td> {r[5]}</td>
                    <td> {r[2]}</td>
                    <td className={r[6]}>
                      <span>{r[6]}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className='table-wrapper'>
            <table>
              <thead>
                <tr>
                  <th>Desa</th>
                  <th>KRR</th>
                  <th>KRT</th>
                  <th>KRST</th>
                  <th>Total Hamil</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {dataHamilPus.map((r, index) => (
                  <tr key={index}>
                    <td> {r[0]}</td>
                    <td> {r[1]}</td>
                    <td> {r[2]}</td>
                    <td> {r[3]}</td>
                    <td> {r[4]}</td>
                    <td className={r[5]}>
                      <span>{r[5]}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
