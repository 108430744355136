import React, { useState, useEffect } from 'react';
import './css/dataIbu.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Table, Button, Form, Badge } from 'react-bootstrap';
import daftarPuskesmas from '../../assets/JsonData/daftarPuskesmas.json';
import dataPuskesDesa from '../../assets/JsonData/Puskes_Desa.json';
import ChartAnalitycs from '../charts/ChartAnalitycs';

import LoadDataIbuFix from '../task/LoadDataIbuFix';
import LoadDataPesan2MPartus from '../task/LoadDataPesan2MPartus';
import LoadDataPesanTidakBerkunjung from '../task/LoadDataPesanTidakBerkunjung';
import { API_MAMABOI } from '../../utils/constant';

const Analitycs = ({ data }) => {
  const [jumlahData, setJumlahData] = useState(0);
  const [dataFix, setDataFix] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [label, setLabel] = useState('Ibu Hamil');
  const [data2M, setData2M] = useState([]);
  const [data40H, setData40H] = useState([]);
  const [dataGrafikFix, setDataGrafikFix] = useState([]);

  const load = () => {
    const LoadDataIbuku = new LoadDataIbuFix();
    LoadDataIbuku.load(setDataGrafikFix, data, setDataFix, setJumlahData);
  };

  const load2 = () => {
    const LoadData2M = new LoadDataPesan2MPartus();
    LoadData2M.load(setData2M, data);
  };

  const load3 = () => {
    const LoadData40H = new LoadDataPesanTidakBerkunjung();
    LoadData40H.load(setData40H, data);
  };

  function loadDataChart(label, dataSelect) {
    var dataChartKu = daftarPuskesmas.map((r) => {
      var data = dataSelect.filter((f) => {
        return r.puskesmas === f.puskesmas;
      });
      return [data.length];
    });
    var dataChartOk = dataChartKu.map((r) => {
      return r[0];
    });
    setDataChart(dataChartOk);
    setJumlahData(dataSelect.length);
    setLabel(label);
  }

  function pilihOpsi(event) {
    var val = event.target.value;
    var label = '';
    if (val === '0') {
      var dataSelect = dataFix;

      label = 'Ibu Hamil';
      loadDataChart(label, dataSelect);
    }

    if (val === '1') {
      var dataSelect = dataFix.filter((r) => {
        return r.golDarahIbu == '';
      });
      label = 'Ibu Hamil belum memiliki Golongan Darah';

      loadDataChart(label, dataSelect);
    }
    if (val === '2') {
      var dataSelect = dataFix.filter((r) => {
        return r.pendonorDarah == '';
      });
      label = 'Ibu Hamil belum memiliki Pendonor Darah';

      loadDataChart(label, dataSelect);
    }
    if (val === '3') {
      var dataSelect = dataFix.filter((r) => {
        return r.nikIbu == '' || r.nikIbu == '0';
      });
      label = 'Ibu Hamil belum meimiliki NIK';

      loadDataChart(label, dataSelect);
    }
    if (val === '4') {
      var dataSelect = dataFix.filter((r) => {
        return r.pkh == 'Tidak' || r.pkh == '';
      });
      label = 'Ibu Hamil bukan penerima Program Keluarga Harapan';

      loadDataChart(label, dataSelect);
    }
    if (val === '5') {
      var dataSelect = dataFix.filter((r) => {
        return r.bpnt == 'Tidak' || r.bpnt == '';
      });
      label = 'Ibu Hamil bukan penerima Bantuan Pangan Non Tunai';

      loadDataChart(label, dataSelect);
    }
    if (val === '6') {
      var dataSelect = dataFix.filter((r) => {
        return r.bst == 'Tidak' || r.bst == '';
      });
      label = 'Ibu Hamil bukan penerima Bantuan Sosial Tunai';

      loadDataChart(label, dataSelect);
    }
    if (val === '7') {
      var dataSelect = dataFix.filter((r) => {
        return r.askes == 'Tidak Ada';
      });
      label = 'Ibu Hamil belum memiliki Askes';

      loadDataChart(label, dataSelect);
    }
    if (val === '8') {
      var dataSelect = dataFix.filter((r) => {
        return r.statusKawin == 'Tidak Kawin';
      });
      label = 'Ibu Hamil di luar Nikah';

      loadDataChart(label, dataSelect);
    }
    if (val === '9') {
      var dataSelect = dataFix.filter((r) => {
        return r.status_Pj == '' || r.status_Pj == 'Pilih opsi';
      });
      label = 'Ibu Hamil belum dihitung status Pudji Rojati';

      loadDataChart(label, dataSelect);
    }
    if (val === '10') {
      var dataSelect = dataFix.filter((r) => {
        return r.status_Pj.toUpperCase() == 'KRR';
      });
      label = 'Ibu Hamil KRR';

      loadDataChart(label, dataSelect);
    }
    if (val === '11') {
      var dataSelect = dataFix.filter((r) => {
        return r.status_Pj.toUpperCase() == 'KRT';
      });
      label = 'Ibu Hamil KRT';

      loadDataChart(label, dataSelect);
    }
    if (val === '12') {
      var dataSelect = dataFix.filter((r) => {
        return r.status_Pj.toUpperCase() == 'KRST';
      });
      label = 'Ibu Hamil KRST';

      loadDataChart(label, dataSelect);
    }
    if (val === '13') {
      var dataSelect = data2M;
      label = 'Ibu Hamil 2 Minggu Partus';

      loadDataChart(label, dataSelect);
    }
    if (val === '14') {
      var dataSelect = data40H;
      label = 'Ibu Hamil 40 Hari Tidak Berkunjung';

      loadDataChart(label, dataSelect);
    }

    if (val === '15') {
      var dataSelect = dataFix.filter((r) => {
        return r.tinggi && r.tinggi <= 145;
      });
      label = 'Ibu Hamil Dengan Tinggi Badan Dibawah 145 cm';

      loadDataChart(label, dataSelect);
    }
    if (val === '16') {
      var dataSelect = dataFix.filter((r) => {
        return r.lila && r.lila <= 23;
      });
      label = 'Ibu Hamil KEK';

      loadDataChart(label, dataSelect);
    }
  }

  useEffect(() => {
    load();
    load2();
    load3();
  }, []);

  // const getDataIbu = async () => {
  //   try {
  //     const response = await axios.get('https://test.mamaboi.net/api/kohort');

  //     setDataIbus(response.data.data);
  //     if (data !== 'Kabupaten') {
  //       const dataIbusaring = dataIbus.filter((r) => {
  //         return (
  //           r.puskesmas === data &&
  //           (r.statusIbu === 'Ibu hamil' ||
  //             r.statusIbu ===
  //               'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)')
  //         );
  //       });
  //       setDataIbusaring(dataIbusaring);
  //     } else {
  //       setDataIbusaring(dataIbus);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const deleteProduct = async (id) => {
    await axios.delete(API_MAMABOI + `kohort/${id}`);
    alert('data berhasil dihapus');
    // getDataIbu();
  };

  return (
    <div>
      <div className='detailsII'>
        <div className='dataIbu'>
          <div class='cardHeader'>
            <h2>
              #Data {label}
              <Badge bg='danger' className='badgeKu'>
                {jumlahData}
              </Badge>
            </h2>
            <ReactHTMLTableToExcel
              className='btn'
              table='dataIbu'
              filename='export Data Ibu'
              sheet='Data Ibu'
              buttonText='Export to Exl'
            />
          </div>
          <Form.Select
            aria-label='Default select example'
            onChange={pilihOpsi}
            className='selectKu'
          >
            <option value='0'>Buat pilihan untuk menganalisa data</option>
            <option value='1'>Ibu Hamil belum memiliki Golongan Darah</option>
            <option value='2'>Ibu Hamil belum memiliki Pendonor Darah</option>
            <option value='3'>Ibu Hamil belum meimiliki NIK</option>
            <option value='4'>
              Ibu Hamil bukan penerima Program Keluarga Harapan
            </option>
            <option value='5'>
              Ibu Hamil bukan penerima Bantuan Pangan Non Tunai
            </option>
            <option value='6'>
              Ibu Hamil bukan penerima Bantuan Sosial Tunai
            </option>
            <option value='7'>Ibu Hamil belum memiliki Askes</option>
            <option value='8'>Ibu Hamil di luar Nikah</option>
            <option value='9'>
              Ibu Hamil belum dihitung status Pudji Rojati
            </option>
            <option value='10'>Ibu Hamil KRR</option>
            <option value='11'>Ibu Hamil KRT</option>
            <option value='12'>Ibu Hamil KRST</option>
            <option value='13'>Ibu Hamil 2 Minggu Partus</option>
            <option value='14'>Ibu Hamil 40 Hari Tidak Berkunjung</option>
            <option value='15'>Ibu Hamil Tinggi di bawah 145 cm</option>
            <option value='16'>Ibu Hamil KEK</option>
          </Form.Select>

          <div>
            <ChartAnalitycs
              dataGrafikFix={dataGrafikFix}
              dataGrafik={dataChart}
              label1={'Data Ibu Hamil'}
              label2={label}
            ></ChartAnalitycs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analitycs;
