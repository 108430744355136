import axios from 'axios';
import { API_MAMABOI } from '../../utils/constant';

class LoadDataProfile {
  // mendapatkan data
  load = (setData, email) => {
    this.setData = setData;
    this.email = email;

    this.#proccessData();
  };

  #proccessData = async () => {
    var urlKu = [];

    try {
      const response = await axios.get(API_MAMABOI + `profile/${this.email}`);
      urlKu = response.data.data;

      // this.setData('https://mamaboi.net/mamaboi/ProfileMamaBoiKu.jpg');
    } catch (error) {
      console.error(error);
    }
    if (urlKu.length !== 0) {
      this.setData(urlKu[urlKu.length - 1].profile);
    } else {
      this.setData('https://mamaboi.net/mamaboi/ProfileMamaBoiKu.jpg');
    }

    //____________
  };
}

export default LoadDataProfile;
