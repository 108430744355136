import axios from 'axios';
import daftarPuskesmas from '../../assets/JsonData/daftarPuskesmas.json';
import dataPuskesDesa from '../../assets/JsonData/Puskes_Desa.json';
import { API_MAMABOI } from '../../utils/constant';

class LoadData {
  // mendapatkan data
  load = (setData, access) => {
    this.setData = setData;
    this.access = access;
    this.#proccessData();
  };

  #proccessData = async () => {
    var dataIbuku = [];
    var dataIbuHamilPus = [];

    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbuku = response.data.data;
    } catch (error) {
      console.error(error);
    }

    var krr = 0;
    var krt = 0;
    var krst = 0;

    if (this.access === 'Kabupaten') {
      //mencari jumlah ibu hamil perdesa
      var list = daftarPuskesmas.map((r) => {
        krr = 0;
        krt = 0;
        krst = 0;

        var ibuPus = dataIbuku.filter((ibu) => {
          return ibu['puskesmas'].toUpperCase() == r['puskesmas'].toUpperCase();
        });

        ibuPus.map((r) => {
          if (r.status_Pj === 'KRR') {
            krr = krr + 1;
          }
          if (r.status_Pj === 'KRT') {
            krt = krt + 1;
          }
          if (r.status_Pj === 'KRST') {
            krst = krst + 1;
          }
        });

        return [r['puskesmas'], r['kecamatan'], ibuPus.length, krr, krt, krst];
      });

      //menetapkan warna  pada desa yang memiliki ibu hamil, menggabungkan dua data tabel desa dan kohort ibu hamil
      var statusHamil = '';
      dataIbuHamilPus = list.map((r) => {
        if (r[2] > 0) {
          statusHamil = 'none';
        }

        if (r[3] > 0) {
          statusHamil = 'pantau';
        }
        if (r[4] > 0) {
          statusHamil = 'waspada';
        }
        if (r[5] > 0) {
          statusHamil = 'siaga';
        }
        return [r[0], r[1], r[2], r[3], r[4], r[5], statusHamil];
      });
    } else {
      const puskesDesa = dataPuskesDesa.filter((pus) => {
        return pus.Puskesmas === this.access;
      });

      let com1 = 'Ibu hamil';
      let com2 = 'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)';

      const k2Filter = dataIbuku.filter((k2) => {
        let com3 = k2.statusIbu;
        return (
          k2.puskesmas === this.access &&
          (com3.toUpperCase() === com1.toUpperCase() ||
            com3.toUpperCase() === com2.toUpperCase())
        );
      });

      const listDesa = puskesDesa.map((r) => {
        const totDesa = k2Filter.filter((f) => {
          return f.desaIbu.toUpperCase() === r.Desa.toUpperCase();
        });

        const krrDesa = k2Filter.filter((f) => {
          return (
            f.desaIbu.toUpperCase() === r.Desa.toUpperCase() &&
            f.status_Pj === 'KRR'
          );
        });

        const krtDesa = k2Filter.filter((f) => {
          return (
            f.desaIbu.toUpperCase() === r.Desa.toUpperCase() &&
            f.status_Pj === 'KRT'
          );
        });
        const krstDesa = k2Filter.filter((f) => {
          return (
            f.desaIbu.toUpperCase() === r.Desa.toUpperCase() &&
            f.status_Pj === 'KRST'
          );
        });

        const jmlKrr = krrDesa.length;
        const jmlKrt = krtDesa.length;
        const jmlKrst = krstDesa.length;
        let color = '';
        if (totDesa.length > 0) {
          color = 'none';
        }

        if (jmlKrr > 0) {
          color = 'pantau';
        }

        if (jmlKrt > 0) {
          color = 'waspada';
        }
        if (jmlKrst > 0) {
          color = 'siaga';
        }

        return [r.Desa, jmlKrr, jmlKrt, jmlKrst, totDesa.length, color];
      });
      dataIbuHamilPus = listDesa;
    }

    this.setData(dataIbuHamilPus);
  };
}

export default LoadData;
