import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import './chart.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import LoadGrafik from '../task/LoadGrafik';

const ChartAnalitycs = ({ dataGrafikFix, dataGrafik, label1, label2 }) => {
  const [year, setYear] = useState('');

  // const load = () => {
  //   const loadDataGrafik = new LoadGrafik();
  //   loadDataGrafik.load(setDataGrafik, setYear, access);
  // };

  // useEffect(load, [access]);

  return (
    <div className='box'>
      <Bar
        plugins={[ChartDataLabels]}
        data={{
          labels: [
            'Baa',
            'Batutua',
            'Busalangga',
            'Delha',
            'Eahun',
            'Feapopi',
            'Korbafo',
            'Ndao',
            'Oelaba',
            'Oele',
            'Sonimanu',
            'Sotimori',
          ],
          datasets: [
            {
              label: label1 + ' per Puskesmas',
              data: dataGrafikFix,
              backgroundColor: ['rgba(54, 162, 235, 0.2)'],
              borderColor: ['rgba(54, 162, 235, 1)'],
              borderWidth: 1,
            },
            {
              label: label2 + ' per Puskesmas',
              data: dataGrafik,
              backgroundColor: ['rgba(255, 99, 132, 0.2)'],
              borderColor: ['rgba(255, 99, 132, 1)'],
              borderWidth: 1,
            },
          ],
        }}
        options={{
          options: {
            responsive: false,
            plugins: {
              datalabels: {
                anchor: 'start',
                align: 'start',
                labels: {
                  value: {
                    color: 'blue',
                  },
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default ChartAnalitycs;
