import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';

import daftarPuskes from '../../assets/JsonData/daftarPuskesmas.json';
import daftarDesa from '../../assets/JsonData/daftarDesa.json';
import { Modal } from 'react-bootstrap';
import Progres from '../progressBar/Progres';
import { Button } from 'react-bootstrap';
import desaPuskes from '../../assets/JsonData/Puskes_Desa.json';
import { API_MAMABOI } from '../../utils/constant';

const Edit = ({ data }) => {
  const { id } = useParams();
  //progress bar
  const [uploadPercentage, setUploadPercentage] = useState(0);
  //

  const [button, setButton] = useState(false);

  //MODAL
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //MODAL
  const [puskesmasList, setPuskesmasList] = useState([]);

  const [puskesmas, setPuskesmas] = useState('');
  const [bidan, setBidan] = useState('');
  const [noHpBidan, setNoHpBidan] = useState('');
  const [desaBidan, setDesaBidan] = useState('');
  const [tempatData, setTempatData] = useState('');
  const [namaIbu, setNamaIbu] = useState('');
  const [hpht, setHpht] = useState('');
  const [fotoIbu, setFotoIbu] = useState([]);
  const [tanggalLahirIbu, setTanggalLahirIbu] = useState('');
  const [nikIbu, setNikIbu] = useState('');
  const [nomorHpIbu, setNomorHpIbu] = useState('');
  const [pendidikanIbu, setPendidikanIbu] = useState('');
  const [pekerjaanIbu, setPekerjaanIbu] = useState('');
  const [desaIbu, setDesaIbu] = useState('');
  const [golDarahIbu, setGolDarahIbu] = useState('');
  const [pendonorDarah, setPendonorDarah] = useState('');
  const [hpPendonor, setHpPendonor] = useState('');
  const [statusKawin, setStatusKawin] = useState('');
  const [fotoRumah, setFotoRumah] = useState([]);
  const [namaSuami, setNamaSuami] = useState('');
  const [noHpSuami, setNoHpSuami] = useState('');
  const [pekerjaanSuami, setPekerjaanSuami] = useState('');
  const [pendidikanSuami, setPendidikanSuami] = useState('');
  const [alamatSuami, setAlamatSuami] = useState('');
  const [suamiRokok, setSuamiRokok] = useState('');
  const [pkh, setPkh] = useState('');
  const [bpnt, setBpnt] = useState('');
  const [bst, setBst] = useState('');
  const [askes, setAskes] = useState('');
  const [jumlahAnak, setJumlahAnak] = useState('');
  const [status, setStatus] = useState('');
  const [alasan, setAlasan] = useState('');
  const [fotoBukti, setFotoBukti] = useState([]);
  const [gpa, setGpa] = useState('');
  const [pernahGugur, setPernahGugur] = useState('');
  const [tahunGugur, setTahunGugur] = useState('');
  const [bayiMeninggal, setBayiMeninggal] = useState('');
  const [tahunMeninggal, setTahunMeninggal] = useState('');
  const [tinggi, setTinggi] = useState('');
  const [kunjunganBumilKe, setKunjunganBumulKe] = useState('');
  const [totalKunjungan, setTotalKunjungan] = useState('');

  const [aksesIbu, setAksesIbu] = useState('');
  const [transportIbu, setTransportIbu] = useState('');
  const [biayaIbu, setBiayaIbu] = useState('');
  const [kendalaIntervensiBumil, setKendalaIntervensiBumil] = useState('');
  const [pilihanFaskes, setPilihanFaskes] = useState('');

  const [hamilTerakhir, setHamilTerakhir] = useState('');
  const [hamilKe, setHamilKe] = useState('');
  const [tahunNikah, setTahunNikah] = useState('');
  const [lahirTarikanTang, setLahirTarikanTang] = useState('');
  const [lahirUriRogoh, setLahirUriRogoh] = useState('');
  const [lahirInfus, setLahirInfus] = useState('');
  const [sesar, setSesar] = useState('');
  const [kurangDarah, setKurangDarah] = useState('');
  const [malaria, setMalaria] = useState('');
  const [tbcParu, setTbcParu] = useState('');
  const [payahJantung, setPayahJantung] = useState('');
  const [diabetes, setDiabetes] = useState('');
  const [pms, setPms] = useState('');
  const [bengkak, setBengkak] = useState('');
  const [kembar, setKembar] = useState('');
  const [hydromnion, setHydromnion] = useState('');
  const [bayiMatiKandungan, setBayiMatiKandungan] = useState('');
  const [lebihBulan, setLebihBulan] = useState('');
  const [letakSunsang, setLetakSunsang] = useState('');
  const [letakLintang, setLetakLintang] = useState('');
  const [perdarahan, setPerdarahan] = useState('');
  const [kejang, setKejang] = useState('');

  const [dataDesa, setDataDesa] = useState([]);

  const [urlFile1, setUrlFile1] = useState('https://fakeimg.pl/350x200/');
  const [urlFile2, setUrlFile2] = useState('https://fakeimg.pl/350x200/');
  const [urlFile3, setUrlFile3] = useState('https://fakeimg.pl/350x200/');

  //SIO SODAK
  const [namaTA, setNamaTA] = useState('');
  const [nomorTA, setNomorTA] = useState('');
  const [namaTB, setNamaTB] = useState('');
  const [nomorTB, setNomorTB] = useState('');
  const [namaKader, setNamaKader] = useState('');
  const [nomorKader, setNomorKader] = useState('');

  const history = useHistory();

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setFotoIbu(e.target.files[0]);
  }

  function onChange2(e) {
    let uploaded = e.target.files[0];
    setUrlFile2(URL.createObjectURL(uploaded));
    setFotoRumah(e.target.files[0]);
  }

  function onChange3(e) {
    let uploaded = e.target.files[0];
    setUrlFile3(URL.createObjectURL(uploaded));
    setFotoBukti(e.target.files[0]);
  }

  //PUJI RODJATI
  const [pujiRojati, setPujiRojati] = useState(0);
  const [pujiRojatiE, setPujiRojatiE] = useState('');
  const [hT, setHT] = useState('');
  const [tani, setTani] = useState('');
  const [ting, setTing] = useState('');

  const onChangeHT = (e) => {
    var j = e.target.value;
    var k = j.length;
    setHamilTerakhir(e.target.value);

    if (j.length < 4) {
      return;
    }

    if (k == 4) {
      var tahun = parseInt(j);
      const d = new Date();
      let year = d.getFullYear();
      var jarak = year - tahun;
      var pr = pujiRojati;

      if ((jarak >= 10) | (jarak <= 2) && hT === '') {
        setPujiRojati(pujiRojati + 4);
        setHT(j);
      } else {
        var tahun2 = parseInt(hT);
        var jarak2 = year - tahun2;

        if ((jarak2 >= 10) | (jarak2 <= 2)) {
          pr = pr - 4;
        }

        if ((jarak >= 10) | (jarak <= 2)) {
          pr = pr + 4;
        }
        setHT(j);
        setPujiRojati(pr);
      }
    }
  };

  const onChangeTB = (e) => {
    setTinggi(e.target.value);
    var tB = parseInt(e.target.value);
    var j = e.target.value;
    var k = j.length;

    var pr = pujiRojati;

    if (k == 3) {
      if (tB < 145 && ting === '') {
        setPujiRojati(pujiRojati + 4);
        setTing(j);
      } else {
        if (parseInt(ting) < 145) {
          pr = pr - 4;
        }
        if (tB < 145) {
          pr = pr + 4;
        }
        setTing(j);
        setPujiRojati(pr);
      }
    }
  };

  const onChangeHK = (e) => {
    var ke = parseInt(e.target.value);
    var tL = new Date(tanggalLahirIbu);
    var tahunLahir = tL.getFullYear();
    const d = new Date();
    let year = d.getFullYear();
    var umur = year - tahunLahir;

    var pr = pujiRojati;

    if (hamilKe != '') {
      var hk = parseInt(hamilKe);
      if (hk > 3) {
        pr = pr - 4;
      } else {
        pr = pr - 2;
      }
    }

    if (ke == 1) {
      if (umur <= 16) {
        pr = pr + 4;
      } else if (umur >= 35) {
        pr = pr + 4;
      } else {
        pr = pr + 2;
      }
    } else if (ke >= 4) {
      pr = pr + 4;
    } else if (ke === 2 || ke === 3) {
      pr = pr + 2;
    }
    setPujiRojati(pr);
    setHamilKe(e.target.value);
  };

  const onChangeTN = (e) => {
    setTahunNikah(e.target.value);

    var j = e.target.value;
    var k = j.length;
    if (k != 4) {
      return;
    }

    var ke = parseInt(hamilKe);

    if (k == 4) {
      var tahun = parseInt(j);
      const d = new Date();
      let year = d.getFullYear();
      var jarak = year - tahun;
      var pr = pujiRojati;

      if (ke === 1 && jarak >= 4 && tani === '') {
        pr = pr + 4;
        setTani(j);
        setPujiRojati(pr);
      } else {
        var tahun2 = parseInt(tani);
        var jarak2 = year - tahun2;

        if (ke === 1 && jarak2 >= 4) {
          pr = pr - 4;
        }

        if (ke === 1 && jarak >= 4) {
          pr = pr + 4;
        }
        setTani(j);
        setPujiRojati(pr);
      }
    }
  };

  const onChangeLTT = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && lahirTarikanTang === '') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && lahirTarikanTang === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && lahirTarikanTang === 'Tidak') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Tidak' && lahirTarikanTang === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    if (j === 'Pilih opsi' && lahirTarikanTang === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    setLahirTarikanTang(e.target.value);
  };

  const onChangeLUR = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && lahirUriRogoh === '') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && lahirUriRogoh === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && lahirUriRogoh === 'Tidak') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Tidak' && lahirUriRogoh === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    if (j === 'Pilih opsi' && lahirUriRogoh === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    setLahirUriRogoh(e.target.value);
  };

  const onChangeLI = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && lahirInfus === '') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && lahirInfus === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && lahirInfus === 'Tidak') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Tidak' && lahirInfus === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    if (j === 'Pilih opsi' && lahirInfus === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    setLahirInfus(e.target.value);
  };

  const onChangeS = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && sesar === '') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Ya' && sesar === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Ya' && sesar === 'Tidak') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Tidak' && sesar === 'Ya') {
      setPujiRojati(pujiRojati - 8);
    }

    if (j === 'Pilih opsi' && sesar === 'Ya') {
      setPujiRojati(pujiRojati - 8);
    }

    setSesar(e.target.value);
  };

  const onChangeKD = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && kurangDarah === '') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && kurangDarah === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && kurangDarah === 'Tidak') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Tidak' && kurangDarah === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    if (j === 'Pilih opsi' && kurangDarah === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    setKurangDarah(e.target.value);
  };

  const onChangeM = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && malaria === '') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && malaria === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && malaria === 'Tidak') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Tidak' && malaria === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    if (j === 'Pilih opsi' && malaria === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    setMalaria(e.target.value);
  };

  const onChangeTP = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && tbcParu === '') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && tbcParu === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && tbcParu === 'Tidak') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Tidak' && tbcParu === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    if (j === 'Pilih opsi' && tbcParu === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    setTbcParu(e.target.value);
  };

  const onChangePJ = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && payahJantung === '') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && payahJantung === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && payahJantung === 'Tidak') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Tidak' && payahJantung === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    if (j === 'Pilih opsi' && payahJantung === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    setPayahJantung(e.target.value);
  };

  const onChangeD = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && diabetes === '') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && diabetes === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && diabetes === 'Tidak') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Tidak' && diabetes === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    if (j === 'Pilih opsi' && diabetes === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    setDiabetes(e.target.value);
  };

  const onChangePMS = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && pms === '') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && pms === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && pms === 'Tidak') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Tidak' && pms === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    if (j === 'Pilih opsi' && pms === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    setPms(e.target.value);
  };

  const onChangeBK = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && bengkak === '') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && bengkak === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && bengkak === 'Tidak') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Tidak' && bengkak === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    if (j === 'Pilih opsi' && bengkak === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    setBengkak(e.target.value);
  };

  const onChangeKR = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && kembar === '') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && kembar === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && kembar === 'Tidak') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Tidak' && kembar === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    if (j === 'Pilih opsi' && kembar === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    setKembar(e.target.value);
  };

  const onChangeHN = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && hydromnion === '') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && hydromnion === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && hydromnion === 'Tidak') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Tidak' && hydromnion === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    if (j === 'Pilih opsi' && hydromnion === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    setHydromnion(e.target.value);
  };

  const onChangeBMK = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && bayiMatiKandungan === '') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && bayiMatiKandungan === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && bayiMatiKandungan === 'Tidak') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Tidak' && bayiMatiKandungan === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    if (j === 'Pilih opsi' && bayiMatiKandungan === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    setBayiMatiKandungan(e.target.value);
  };

  const onChangeLB = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && lebihBulan === '') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && lebihBulan === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Ya' && lebihBulan === 'Tidak') {
      setPujiRojati(pujiRojati + 4);
    }

    if (j === 'Tidak' && lebihBulan === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }

    if (j === 'Pilih opsi' && lebihBulan === 'Ya') {
      setPujiRojati(pujiRojati - 4);
    }
    setLebihBulan(e.target.value);
  };

  const onChangeLS = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && letakSunsang === '') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Ya' && letakSunsang === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Ya' && letakSunsang === 'Tidak') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Tidak' && letakSunsang === 'Ya') {
      setPujiRojati(pujiRojati - 8);
    }

    if (j === 'Pilih opsi' && letakSunsang === 'Ya') {
      setPujiRojati(pujiRojati - 8);
    }

    setLetakSunsang(e.target.value);
  };

  const onChangeLL = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && letakLintang === '') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Ya' && letakLintang === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Ya' && letakLintang === 'Tidak') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Tidak' && letakLintang === 'Ya') {
      setPujiRojati(pujiRojati - 8);
    }

    if (j === 'Pilih opsi' && letakLintang === 'Ya') {
      setPujiRojati(pujiRojati - 8);
    }

    setLetakLintang(e.target.value);
  };

  const onChangePN = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && perdarahan === '') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Ya' && perdarahan === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Ya' && perdarahan === 'Tidak') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Tidak' && perdarahan === 'Ya') {
      setPujiRojati(pujiRojati - 8);
    }

    if (j === 'Pilih opsi' && perdarahan === 'Ya') {
      setPujiRojati(pujiRojati - 8);
    }
    setPerdarahan(e.target.value);
  };

  const onChangeKG = (e) => {
    var j = e.target.value;

    if (j === 'Ya' && kejang === '') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Ya' && kejang === 'Pilih opsi') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Ya' && kejang === 'Tidak') {
      setPujiRojati(pujiRojati + 8);
    }

    if (j === 'Tidak' && kejang === 'Ya') {
      setPujiRojati(pujiRojati - 8);
    }

    if (j === 'Pilih opsi' && kejang === 'Ya') {
      setPujiRojati(pujiRojati - 8);
    }

    setKejang(e.target.value);
  };

  //AGA
  //https://test.mamaboi.net
  const getProductById = async () => {
    try {
      const response = await axios.get(API_MAMABOI + `kohort/${id}`);

      setPuskesmas(response.data.data.puskesmas);
      setBidan(response.data.data.bidan);
      setNoHpBidan(response.data.data.noHpBidan);
      setDesaBidan(response.data.data.desaBidan);
      setTempatData(response.data.data.tempatData);
      setNamaIbu(response.data.data.namaIbu);

      setTanggalLahirIbu(response.data.data.tanggalLahirIbu);

      setNikIbu(response.data.data.nikIbu);
      setNomorHpIbu(response.data.data.nomorHpIbu);
      setPendidikanIbu(response.data.data.pendidikanIbu);
      setPekerjaanIbu(response.data.data.pekerjaanIbu);
      setDesaIbu(response.data.data.desaIbu);
      setGolDarahIbu(response.data.data.golDarahIbu);
      setPendonorDarah(response.data.data.pendonorDarah);
      setHpPendonor(response.data.data.hpPendonor);
      setStatusKawin(response.data.data.statusKawin);
      setNamaSuami(response.data.data.namaSuami);
      setNoHpSuami(response.data.data.noHpSuami);
      setPekerjaanSuami(response.data.data.pekerjaanSuami);
      setPendidikanSuami(response.data.data.pendidikanSuami);
      setAlamatSuami(response.data.data.alamatSuami);
      setSuamiRokok(response.data.data.suamiRokok);
      setPkh(response.data.data.pkh);
      setBpnt(response.data.data.bpnt);
      setBst(response.data.data.bst);
      setAskes(response.data.data.askes);
      setJumlahAnak(response.data.data.jumlahAnak);
      setStatus(response.data.data.statusIbu);
      setAlasan(response.data.data.alasan);
      setGpa(response.data.data.gpa);
      setPernahGugur(response.data.data.pernahGugur);
      setTahunGugur(response.data.data.tahunGugur);
      setBayiMeninggal(response.data.data.bayiMeninggal);
      setTahunMeninggal(response.data.data.tahunMeninggal);
      setTinggi(response.data.data.tinggi);
      setKunjunganBumulKe(response.data.data.kunjunganBumilKe);
      setTotalKunjungan(response.data.data.totalKunjungan);
      setHpht(response.data.data.hpht);
      setAksesIbu(response.data.data.aksesIbu);
      setTransportIbu(response.data.data.transportIbu);
      setBiayaIbu(response.data.data.biayaIbu);
      setKendalaIntervensiBumil(response.data.data.kendalaIntervensi);
      setPilihanFaskes(response.data.data.pilihanFaskes);

      setHamilTerakhir(response.data.data.hamilTerakhir);

      setHamilKe(response.data.data.hamilKe);
      setTahunNikah(response.data.data.tahunNikah);
      setLahirTarikanTang(response.data.data.lahirTarikanTang);
      setLahirUriRogoh(response.data.data.lahirUriRogoh);
      setLahirInfus(response.data.data.lahirInfus);
      setSesar(response.data.data.sesar);
      setKurangDarah(response.data.data.kurangDarah);
      setMalaria(response.data.data.malaria);
      setTbcParu(response.data.data.tbcParu);
      setPayahJantung(response.data.data.payahJantung);
      setDiabetes(response.data.data.diabetes);
      setPms(response.data.data.pms);
      setBengkak(response.data.data.bengkak);
      setKembar(response.data.data.kembar);
      setHydromnion(response.data.data.hydromnion);
      setBayiMatiKandungan(response.data.data.bayiMatiKandungan);
      setLebihBulan(response.data.data.lebihBulan);
      setLetakSunsang(response.data.data.letakSunsang);
      setLetakLintang(response.data.data.letakLintang);
      setPerdarahan(response.data.data.perdarahan);
      setKejang(response.data.data.kejang);
      setPujiRojati(response.data.data.skor_pj);

      //SIO SODAK
      setNamaTA(response.data.data.namaTA);
      setNomorTA(response.data.data.nomorTA);
      setNamaTB(response.data.data.namaTB);
      setNomorTB(response.data.data.nomorTB);
      setNamaKader(response.data.data.namaKader);
      setNomorKader(response.data.data.nomorKader);

      //setPujiRojati(parseInt(response.data.tinggi));

      setUrlFile1(response.data.data.fotoIbu);
      setUrlFile2(response.data.data.fotoRumah);
      setUrlFile3(response.data.data.fotoBukti);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDesaList();
    getDesaList();
    if (data != 'Kabupaten') {
      setPuskesmas(data);
    } else {
      setPuskesmas('');
    }
    getPuskesmasList();
    getProductById();
  }, [data]);

  const getDesaList = () => {
    if (data != 'Kabupaten') {
      const dP = desaPuskes.filter((r) => {
        return r.Puskesmas === data;
      });
      setDataDesa(dP);
    } else {
      setDataDesa(desaPuskes);
    }
  };
  const getPuskesmasList = () => {
    setPuskesmasList(daftarPuskes);
  };

  const saveProduct = async (e) => {
    e.preventDefault();
    setButton(true);

    const formData = new FormData();
    formData.append('photo', fotoIbu);
    formData.append('photo', fotoRumah);
    formData.append('photo', fotoBukti);
    formData.append('bidan', bidan);

    formData.append('puskesmas', puskesmas);
    formData.append('noHpBidan', noHpBidan);
    formData.append('desaBidan', desaBidan);
    formData.append('tempatData', tempatData);
    formData.append('namaIbu', namaIbu);
    formData.append('hpht', hpht);

    formData.append('tanggalLahirIbu', tanggalLahirIbu);
    formData.append('nikIbu', nikIbu);
    formData.append('nomorHpIbu', nomorHpIbu);
    formData.append('pendidikanIbu', pendidikanIbu);
    formData.append('pekerjaanIbu', pekerjaanIbu);
    formData.append('desaIbu', desaIbu);
    formData.append('golDarahIbu', golDarahIbu);
    formData.append('pendonorDarah', pendonorDarah);
    formData.append('hpPendonor', hpPendonor);
    formData.append('statusKawin', statusKawin);
    formData.append('namaSuami', namaSuami);
    formData.append('noHpSuami', noHpSuami);
    formData.append('pekerjaanSuami', pekerjaanSuami);
    formData.append('pendidikanSuami', pendidikanSuami);
    formData.append('alamatSuami', alamatSuami);
    formData.append('suamiRokok', suamiRokok);
    formData.append('pkh', pkh);
    formData.append('bpnt', bpnt);
    formData.append('bst', bst);
    formData.append('askes', askes);
    formData.append('jumlahAnak', jumlahAnak);
    formData.append('statusIbu', status);
    formData.append('alasan', alasan);

    formData.append('gpa', gpa);
    formData.append('pernahGugur', pernahGugur);
    formData.append('tahunGugur', tahunGugur);
    formData.append('bayiMeninggal', bayiMeninggal);
    formData.append('tahunMeninggal', tahunMeninggal);
    formData.append('tinggi', tinggi);
    formData.append('kunjunganBumilKe', kunjunganBumilKe);
    formData.append('totalKunjungan', totalKunjungan);

    formData.append('aksesIbu', aksesIbu);
    formData.append('transportIbu', transportIbu);
    formData.append('biayaIbu', biayaIbu);
    formData.append('kendalaIntervensi', kendalaIntervensiBumil);
    formData.append('pilihanFaskes', pilihanFaskes);

    formData.append('hamilTerakhir', hamilTerakhir);
    formData.append('hamilKe', hamilKe);
    formData.append('tahunNikah', tahunNikah);
    formData.append('lahirTarikanTang', lahirTarikanTang);
    formData.append('lahirUriRogoh', lahirUriRogoh);
    formData.append('lahirInfus', lahirInfus);
    formData.append('sesar', sesar);
    formData.append('kurangDarah', kurangDarah);
    formData.append('malaria', malaria);
    formData.append('tbcParu', tbcParu);
    formData.append('payahJantung', payahJantung);
    formData.append('diabetes', diabetes);
    formData.append('pms', pms);
    formData.append('bengkak', bengkak);
    formData.append('kembar', kembar);
    formData.append('hydromnion', hydromnion);
    formData.append('bayiMatiKandungan', bayiMatiKandungan);
    formData.append('lebihBulan', lebihBulan);
    formData.append('letakSunsang', letakSunsang);
    formData.append('letakLintang', letakLintang);
    formData.append('perdarahan', perdarahan);
    formData.append('kejang', kejang);
    formData.append('skor_pj', pujiRojati);
    if (pujiRojati < 6) {
      formData.append('status_pj', 'KRR');
    } else if (pujiRojati >= 6 && pujiRojati < 12) {
      formData.append('status_pj', 'KRT');
    } else if (pujiRojati >= 12) {
      formData.append('status_pj', 'KRST');
    }

    formData.append('namaTA', namaTA);
    formData.append('nomorTA', nomorTA);
    formData.append('namaTB', namaTB);
    formData.append('nomorTB', nomorTB);
    formData.append('namaKader', namaKader);
    formData.append('nomorKader', nomorKader);

    const response = await axios.patch(API_MAMABOI + `kohort/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (ProgressEvent) => {
        setUploadPercentage(
          parseInt(
            Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
          )
        );

        // console.log(uploadPercentage);
        // //clear percentage
        // setTimeout(() => setUploadPercentage(0), 1000);
      },
    });

    if (response.data.success === 1) {
      alert('Data Ibu berhasil diedit');
      history.push('/layout/dataibu');
      setButton(false);
    }
  };
  return (
    <div>
      <form onSubmit={saveProduct}>
        {data === 'Kabupaten' ? (
          <div className='mb-3'>
            <label for='exampleFormControlInput1' class='form-label'>
              Pilih Puskesmas
            </label>
            <select
              className='form-select'
              aria-label='Default select example'
              value={puskesmas}
              onChange={(e) => setPuskesmas(e.target.value)}
              required
            >
              <option>Pilih Puskesmas</option>
              {puskesmasList.map((puskesmas, index) => (
                <option>{puskesmas.puskesmas}</option>
              ))}
            </select>
          </div>
        ) : (
          <div></div>
        )}

        <div className='mb-3'>
          <label for='exampleFormControlInput1' class='form-label'>
            Nama Bidan
          </label>
          <input
            type='text'
            class='form-control'
            id='exampleFormControlInput1'
            placeholder='Nama Bidan'
            value={bidan}
            onChange={(e) => setBidan(e.target.value)}
            required
          />
        </div>

        <div className='mb-3'>
          <label for='exampleFormControlInput1' class='form-label'>
            Nomor HP Bidan
          </label>
          <input
            type='text'
            class='form-control'
            id='exampleFormControlInput1'
            placeholder='No HP Bidan'
            value={noHpBidan}
            onChange={(e) => setNoHpBidan(e.target.value)}
            required
          />
        </div>
        <div className='mb-3'>
          <label for='exampleFormControlInput1' class='form-label'>
            Asal Desa bidan
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={desaBidan}
            onChange={(e) => setDesaBidan(e.target.value)}
          >
            <option>Pilih Desa tempat tinggal bidan</option>
            {daftarDesa.map((desa, index) => (
              <option>{desa.desa}</option>
            ))}
          </select>
        </div>

        <div className='mb-3'>
          <label for='exampleFormControlInput1' class='form-label'>
            Tempat pendataan
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={tempatData}
            onChange={(e) => setTempatData(e.target.value)}
          >
            <option>Pilih nama Tempat pendataan</option>
            <option>Rumah</option>
            <option>Posyandu</option>
            <option>Puskesmas</option>
            <option>Rumah Sakit</option>
            <option>Lainya</option>
          </select>
        </div>
        <div className='kolom'>
          <div className='bagiKolo,'>
            <div className='mb-3'>
              <label for='exampleFormControlInput1' className='form-label'>
                Nama Ibu
              </label>
              <input
                type='text'
                className='form-control'
                id='exampleFormControlInput1'
                placeholder='Nama'
                value={namaIbu}
                onChange={(e) => setNamaIbu(e.target.value)}
                required
              />
            </div>

            <div className='mb-3'>
              <label for='exampleFormControlInput1' className='form-label'>
                Tanggal Lahir Ibu
              </label>
              <input
                className='form-control'
                type='date'
                value={tanggalLahirIbu}
                onChange={(e) => setTanggalLahirIbu(e.target.value)}
                required
              ></input>
            </div>

            <div className='mb-3'>
              <label for='exampleFormControlInput1' className='form-label'>
                Nomor Induk Kependudukan Ibu
              </label>
              <input
                className='form-control'
                type='number'
                placeholder='input NIK Ibu'
                value={nikIbu}
                onChange={(e) => setNikIbu(e.target.value)}
              ></input>
            </div>
            <div className='mb-3'>
              <label for='exampleFormControlInput1' className='form-label'>
                Nomor HP Ibu
              </label>
              <input
                className='form-control'
                type='number'
                placeholder='input Nomor HP Ibu'
                value={nomorHpIbu}
                onChange={(e) => setNomorHpIbu(e.target.value)}
              ></input>
            </div>
          </div>
          <div className='mb-3'>
            <label>Pilih File Foto Ibu</label>
            <div>
              <img src={urlFile1} alt='' width={'100%'} />
            </div>

            <label className='file-label'>
              <input
                className='form-control'
                type='file'
                name='resume'
                onChange={onChange1}
                accept={'image/*'}
              />
              <span className='file-cta'>
                <span class='file-icon'>
                  <i class='fas fa-upload'></i>
                </span>
              </span>
              <span class='file-name'>{fotoIbu.name}</span>
            </label>
          </div>
        </div>
        <div className='mb-3'>
          <label for='exampleFormControlInput1' class='form-label'>
            Pendidikan Ibu
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={pendidikanIbu}
            onChange={(e) => setPendidikanIbu(e.target.value)}
          >
            <option>Pilih Pendidikan Ibu</option>
            <option>Tidak tamat SD</option>
            <option>Tamat SD</option>
            <option>Tamat SMP</option>
            <option>Tamat SMA</option>
            <option>D1 - D3</option>
            <option>D4 - S1</option>
            <option>S2</option>
            <option>S3</option>
            <option>Lainya</option>
          </select>
        </div>
        <div className='mb-3'>
          <label for='exampleFormControlInput1' class='form-label'>
            Pekerjaan Ibu
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={pekerjaanIbu}
            onChange={(e) => setPekerjaanIbu(e.target.value)}
          >
            <option>Pilih Pekerjaan Ibu</option>
            <option>PNS</option>
            <option>Tenaga Kontrak Daerah (TKD)</option>
            <option>Karyawan swasta</option>
            <option>Wirausaha</option>
            <option>Ibu Rumah Tangga</option>
            <option>Nelayan</option>
            <option>Petani</option>
            <option>Petani rumput laut</option>
            <option>Lainya</option>
          </select>
        </div>
        <div className='mb-3'>
          <label for='exampleFormControlInput1' class='form-label'>
            Asal Desa Ibu
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={desaIbu}
            onChange={(e) => setDesaIbu(e.target.value)}
          >
            <option>Pilih Desa Ibu</option>
            {dataDesa.map((desa, index) => (
              <option>{desa.Desa}</option>
            ))}
          </select>
        </div>
        <div className='mb-3'>
          <label for='exampleFormControlInput1' class='form-label'>
            Golongan darah Ibu
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={golDarahIbu}
            onChange={(e) => setGolDarahIbu(e.target.value)}
          >
            <option>Pilih Gol Darah Ibu</option>
            <option>A</option>
            <option>B</option>
            <option>O</option>
            <option>AB</option>
            <option>Belum tahu</option>
          </select>
        </div>

        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Nama pendonor darah ibu
          </label>
          <input
            className='form-control'
            type='text'
            placeholder='Nama pendonor'
            value={pendonorDarah}
            onChange={(e) => setPendonorDarah(e.target.value)}
          ></input>
        </div>
        {pendonorDarah !== '' ? (
          <div className='mb-3'>
            <label for='exampleFormControlInput1' className='form-label'>
              Nomor HP Pendonor Darah
            </label>
            <input
              className='form-control'
              type='number'
              placeholder='input No HP Pendonor'
              value={hpPendonor}
              onChange={(e) => setHpPendonor(e.target.value)}
            ></input>
          </div>
        ) : (
          <div></div>
        )}
        <div className='mb-3'>
          <label for='exampleFormControlInput1' class='form-label'>
            Status perkawinan
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={statusKawin}
            onChange={(e) => setStatusKawin(e.target.value)}
          >
            <option>Pilih status Perkawinan</option>
            <option>Kawin Negara</option>
            <option>Kawin Adat</option>
            <option>Tidak Kawin</option>
          </select>
        </div>
        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Nama Suami
          </label>
          <input
            className='form-control'
            type='text'
            placeholder='input Nama Suami'
            value={namaSuami}
            onChange={(e) => setNamaSuami(e.target.value)}
          ></input>
        </div>

        <div className='kolom'>
          <div className='bagikolom'>
            <div className='field'>
              <label className='label'>Pilih File Foto Rumah Tinggal</label>
              <div>
                <img src={urlFile2} className='' alt='' width={'100%'} />
              </div>
              <div className='file has-name is-fullwidth'>
                <label className='file-label'>
                  <input
                    className='file-input'
                    type='file'
                    name='resume'
                    onChange={onChange2}
                    accept={'image/*'}
                  />
                  <span className='file-cta'>
                    <span class='file-icon'>
                      <i class='fas fa-upload'></i>
                    </span>
                  </span>
                  <span class='file-name'>{fotoRumah.name}</span>
                </label>
              </div>
            </div>
          </div>
          <div className='bagikolom'>
            <div className='mb-3'>
              <label for='exampleFormControlInput1' className='form-label'>
                Nomor HP Suami
              </label>
              <input
                className='form-control'
                type='number'
                placeholder='input Nomor HP Suami'
                value={noHpSuami}
                onChange={(e) => setNoHpSuami(e.target.value)}
              ></input>
            </div>
            <div className='mb-3'>
              <label for='exampleFormControlInput1' class='form-label'>
                Pendidikan Suami
              </label>
              <select
                className='form-select'
                aria-label='Default select example'
                value={pendidikanSuami}
                onChange={(e) => setPendidikanSuami(e.target.value)}
              >
                <option>Pilih pendidikan suami</option>
                <option>Tidak tamat SD</option>
                <option>Tamat SD</option>
                <option>Tamat SMP</option>
                <option>Tamat SMA</option>
                <option>D1 - D3</option>
                <option>D4 - S1</option>
                <option>S2</option>
                <option>S3</option>
                <option>Lainya</option>
              </select>
            </div>
            <div className='mb-3'>
              <label for='exampleFormControlInput1' class='form-label'>
                Pekerjaan Suami
              </label>
              <select
                className='form-select'
                aria-label='Default select example'
                value={pekerjaanSuami}
                onChange={(e) => setPekerjaanSuami(e.target.value)}
              >
                <option>Pilih Pekerjaan Suami</option>
                <option>PNS</option>
                <option>Tenaga Kontrak Daerah (TKD)</option>
                <option>Karyawan swasta</option>
                <option>Wirausaha</option>
                <option>Ibu Rumah Tangga</option>
                <option>Nelayan</option>
                <option>Petani</option>
                <option>Petani rumput laut</option>
                <option>Lainya</option>
              </select>
            </div>
            <div className='mb-3'>
              <label for='exampleFormControlInput1' class='form-label'>
                Alamat Suami
              </label>
              <select
                className='form-select'
                aria-label='Default select example'
                value={alamatSuami}
                onChange={(e) => setAlamatSuami(e.target.value)}
              >
                <option>Pilih Alamat Suami</option>
                <option>Se-desa dengan ibu</option>
                <option>Berbeda desa di Rote</option>
                <option>Di luar Rote di NTT</option>
                <option>Di luar NTT</option>
                <option>Di luar negeri</option>
              </select>
            </div>
            <div className='mb-3'>
              <label for='exampleFormControlInput1' class='form-label'>
                Apakah Suami merokok di rumah ?
              </label>
              <select
                className='form-select'
                aria-label='Default select example'
                value={suamiRokok}
                onChange={(e) => setSuamiRokok(e.target.value)}
              >
                <option>Pilih opsi</option>
                <option>Ya</option>
                <option>Tidak</option>
              </select>
            </div>
          </div>
        </div>

        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Apakah Ibu dan Suami (atau salah satunya) adalah penerima bantuan
            PKH (Program Keluarga Harapan)
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={pkh}
            onChange={(e) => setPkh(e.target.value)}
          >
            <option>Pilih opsi</option>
            <option>Ya</option>
            <option>Tidak</option>
          </select>
        </div>
        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Apakah Ibu dan Suami (atau salah satunya) adalah penerima bantuan
            BPNT (Bantuan Pangan Non Tunai)?
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={bpnt}
            onChange={(e) => setBpnt(e.target.value)}
          >
            <option>Pilih opsi</option>
            <option>Ya</option>
            <option>Tidak</option>
          </select>
        </div>
        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Apakah Ibu dan Suami (atau salah satunya) adalah penerima bantuan
            BST (Bantuan Sosial Tunai)?
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={bst}
            onChange={(e) => setBst(e.target.value)}
          >
            <option>Pilih opsi</option>
            <option>Ya</option>
            <option>Tidak</option>
          </select>
        </div>
        <div className='kolom'>
          <div className='bagikolom'>
            <div className='mb-3'>
              <label for='exampleFormControlInput1' className='form-label'>
                Jenis Asuransi Kesehatan yang dimiliki
              </label>
              <select
                className='form-select'
                aria-label='Default select example'
                value={askes}
                onChange={(e) => setAskes(e.target.value)}
              >
                <option>Pilih opsi</option>
                <option>Tidak Ada</option>
                <option>KIS/BPJS</option>
                <option>Asuransi lainya</option>
              </select>
            </div>
            <div className='mb-3'>
              <label for='exampleFormControlInput1' className='form-label'>
                Jumlah anak kandung
              </label>
              <select
                className='form-select'
                aria-label='Default select example'
                value={jumlahAnak}
                onChange={(e) => setJumlahAnak(e.target.value)}
              >
                <option>Pilih opsi</option>
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>lebih dari 4</option>
              </select>
            </div>
            <div className='mb-3'>
              <label
                for='exampleFormControlInput1'
                className='form-label'
              ></label>
              <select
                className='form-select'
                aria-label='Default select example'
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                required
              >
                <option>Pilih opsi</option>
                <option>
                  Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)
                </option>
                <option>Ibu hamil</option>
                <option>Ibu bersalin</option>
              </select>
            </div>

            {status ===
            'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)' ? (
              <div>
                <div className='mb-3'>
                  <label for='exampleFormControlInput1' className='form-label'>
                    Jika ibu hamil menolak diperiksa, apa alasannya
                  </label>
                  <select
                    className='form-select'
                    aria-label='Default select example'
                    value={alasan}
                    onChange={(e) => setAlasan(e.target.value)}
                  >
                    <option>Pilih opsi</option>
                    <option>Menolak untuk memberi alasan</option>
                    <option>Hamil di luar nikah</option>
                    <option>jarak yang jauh</option>
                    <option>Ibu hamil tidak berdomisili di Rote</option>
                    <option>
                      Tidak punya cukup uang transport ke fasilitas kesehatan
                      terdekat
                    </option>
                    <option>Dilarang oleh keluarga</option>
                    <option>
                      Sudah ada keluarga (dukun) yang sedang memeriksa
                    </option>
                    <option>Lainya</option>
                  </select>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className='mb-3'>
            {status ===
            'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)' ? (
              <div>
                <label for='exampleFormControlInput1' className='form-label'>
                  Foto bukti surat pernyataan menolak diperiksa
                </label>
                <div>
                  <img src={urlFile3} className='' alt='' width={'300px'} />
                </div>
                <div className='file has-name is-fullwidth'>
                  <label className='file-label'>
                    <input
                      className='file-input'
                      type='file'
                      name='resume'
                      onChange={onChange3}
                      accept={'image/*'}
                    />
                    <span className='file-cta'>
                      <span class='file-icon'>
                        <i class='fas fa-upload'></i>
                      </span>
                    </span>
                    <span class='file-name'>{fotoBukti.name}</span>
                  </label>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            GPA
          </label>
          <input
            className='form-control'
            type='text'
            placeholder='__,__,__'
            value={gpa}
            onChange={(e) => setGpa(e.target.value)}
            required
          ></input>
        </div>

        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Pernah mengalami keguguran
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={pernahGugur}
            onChange={(e) => setPernahGugur(e.target.value)}
          >
            <option>Pilih opsi</option>
            <option>Ya</option>
            <option>Tidak</option>
          </select>
        </div>

        {pernahGugur === 'Ya' ? (
          <div className='mb-3'>
            <label for='exampleFormControlInput1' className='form-label'>
              Tahun keguguran
            </label>
            <input
              className='form-control'
              type='number'
              placeholder='Tahun'
              value={tahunGugur}
              onChange={(e) => setTahunGugur(e.target.value)}
            ></input>
          </div>
        ) : (
          <div></div>
        )}

        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Pernah mengalami kematian bayi
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={bayiMeninggal}
            onChange={(e) => setBayiMeninggal(e.target.value)}
          >
            <option>Pilih opsi</option>
            <option>Ya</option>
            <option>Tidak</option>
          </select>
        </div>

        {bayiMeninggal === 'Ya' ? (
          <div className='mb-3'>
            <label for='exampleFormControlInput1' className='form-label'>
              Tahun kematian bayi
            </label>
            <input
              className='form-control'
              type='number'
              placeholder='Tahun'
              value={tahunMeninggal}
              onChange={(e) => setTahunMeninggal(e.target.value)}
            ></input>
          </div>
        ) : (
          <div></div>
        )}

        {/* <div className='field'>
        <label className='label'>Kunjungan Bumil ke</label>
        <div
          className='select is-fullwidth'
          value={kunjunganBumilKe}
          onChange={(e) => setKunjunganBumulKe(e.target.value)}
        >
          <select>
            <option>Pilih opsi</option>
            <option>K1</option>
            <option>K4</option>
          </select>
        </div>
      </div>

      <div className='field'>
        <label className='label'>Total Kunjungan</label>
        <div
          className='select is-fullwidth'
          value={totalKunjungan}
          onChange={(e) => setTotalKunjungan(e.target.value)}
        >
          <select>
            <option>Pilih opsi</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
            <option>11</option>
          </select>
        </div>
      </div> */}

        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Tanggal Hari pertama haid terakhir (HPHT)
          </label>
          <input
            className='form-control'
            type='date'
            value={hpht}
            onChange={(e) => setHpht(e.target.value)}
            data-date-format='YYYY MM DD'
            required
          ></input>
        </div>
        <div className='mb-3'>
          <Button variant='primary' onClick={handleShow}>
            HITUNG SKOR PUJI ROJATI
          </Button>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Skor Pudji Rochjati : {pujiRojati}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className='puji'>{pujiRojati}</div>
              {gpa === '1,0,0' ? (
                <div></div>
              ) : (
                <div className='mb-3'>
                  <label for='exampleFormControlInput1' className='form-label'>
                    Tahun kehamilan terkahir
                  </label>

                  <input
                    className='form-control'
                    type='number'
                    placeholder='Tahun '
                    value={hamilTerakhir}
                    onChange={onChangeHT}
                    // onChange={(e) => setHamilTerakhir(e.target.value)}
                  ></input>
                </div>
              )}

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Kehamilan ke
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={hamilKe}
                  onChange={onChangeHK}
                  // onChange={(e) => setHamilKe(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                </select>
              </div>
              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Tinggi badan ibu
                </label>
                <input
                  className='form-control'
                  type='number'
                  placeholder='Tinggi (cm)'
                  value={tinggi}
                  onChange={onChangeTB}
                  // onChange={(e) => setTinggi(e.target.value)}
                ></input>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Tahun Menikah
                </label>
                <input
                  className='form-control'
                  type='number'
                  placeholder='Tahun '
                  value={tahunNikah}
                  onChange={onChangeTN}
                  // onChange={(e) => setTahunNikah(e.target.value)}
                ></input>
              </div>
              {gpa === '1,0,0' ? (
                <div></div>
              ) : (
                <div>
                  <div className='mb-3'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      Pernah melahirkan dengan tarikan tang / vakum
                    </label>
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      value={lahirTarikanTang}
                      onChange={onChangeLTT}
                      // onChange={(e) => setLahirTarikanTang(e.target.value)}
                    >
                      <option>Pilih opsi</option>
                      <option>Ya</option>
                      <option>Tidak</option>
                    </select>
                  </div>

                  <div className='mb-3'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      Pernah melahirkan dengan uri dirogoh
                    </label>
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      value={lahirUriRogoh}
                      onChange={onChangeLUR}
                      // onChange={(e) => setLahirUriRogoh(e.target.value)}
                    >
                      <option>Pilih opsi</option>
                      <option>Ya</option>
                      <option>Tidak</option>
                    </select>
                  </div>

                  <div className='mb-3'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      Pernah melahirkan dengan diinfus
                    </label>
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      value={lahirInfus}
                      onChange={onChangeLI}
                      // onChange={(e) => setLahirInfus(e.target.value)}
                    >
                      <option>Pilih opsi</option>
                      <option>Ya</option>
                      <option>Tidak</option>
                    </select>
                  </div>

                  <div className='mb-3'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      Pernah melahirkan dengan sesar
                    </label>
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      value={sesar}
                      onChange={onChangeS}
                      // onChange={(e) => setSesar(e.target.value)}
                    >
                      <option>Pilih opsi</option>
                      <option>Ya</option>
                      <option>Tidak</option>
                    </select>
                  </div>
                </div>
              )}
              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Kurang darah
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={kurangDarah}
                  onChange={onChangeKD}
                  // onChange={(e) => setKurangDarah(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Malaria{' '}
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={malaria}
                  onChange={onChangeM}
                  // onChange={(e) => setMalaria(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  TBC Paru
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={tbcParu}
                  onChange={onChangeTP}
                  // onChange={(e) => setTbcParu(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Payah Jantung{' '}
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={payahJantung}
                  onChange={onChangePJ}
                  // onChange={(e) => setPayahJantung(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Diabetes{' '}
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={diabetes}
                  onChange={onChangeD}
                  // onChange={(e) => setDiabetes(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Penyakit Menalur Seksual{' '}
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={pms}
                  onChange={onChangePMS}
                  // onChange={(e) => setPms(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Bengkak pada muka / tungkai dan tekanan darah tinggi
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={bengkak}
                  onChange={onChangeBK}
                  // onChange={(e) => setBengkak(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Hamil kembar
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={kembar}
                  onChange={onChangeKR}
                  // onChange={(e) => setKembar(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Hydromnion
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={hydromnion}
                  onChange={onChangeHN}
                  // onChange={(e) => setHydromnion(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Bayi mati dalam kandungan{' '}
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={bayiMatiKandungan}
                  onChange={onChangeBMK}
                  // onChange={(e) => setBayiMatiKandungan(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Kehamilan lebih bulan{' '}
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={lebihBulan}
                  onChange={onChangeLB}
                  // onChange={(e) => setLebihBulan(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Letak sunsang{' '}
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={letakSunsang}
                  onChange={onChangeLS}
                  // onChange={(e) => setLetakSunsang(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Letak lintang
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={letakLintang}
                  onChange={onChangeLL}
                  // onChange={(e) => setLetakLintang(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Pendarahan dalam kehamilan ini{' '}
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={perdarahan}
                  onChange={onChangePN}
                  // onChange={(e) => setPerdarahan(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>

              <div className='mb-3'>
                <label for='exampleFormControlInput1' className='form-label'>
                  Preeklamsia berat / kejang - kejang{' '}
                </label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={kejang}
                  onChange={onChangeKG}
                  // onChange={(e) => setKejang(e.target.value)}
                >
                  <option>Pilih opsi</option>
                  <option>Ya</option>
                  <option>Tidak</option>
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Jarak ibu hamil ke fasilitas kesehatan
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={aksesIbu}
            onChange={(e) => setAksesIbu(e.target.value)}
          >
            <option>Pilih opsi</option>
            <option>1 sd 5 km</option>
            <option>lebih dari 5 km </option>
          </select>
        </div>

        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Fasilitas yang dipakai bumil ke faskes
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={transportIbu}
            onChange={(e) => setTransportIbu(e.target.value)}
          >
            <option>Pilih opsi</option>
            <option>Ambulance</option>
            <option>Kendaraan sendiri</option>
            <option>Ojek</option>
            <option>Transportasi umum</option>
          </select>
        </div>

        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Biaya yang dipakai ke faskes
          </label>
          <input
            className='form-control'
            type='number'
            placeholder='Biaya (Rupiah)'
            value={biayaIbu}
            onChange={(e) => setBiayaIbu(e.target.value)}
          ></input>
        </div>

        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Kendala intervensi manual
          </label>
          <input
            className='form-control'
            type='text'
            placeholder='Kendala'
            value={kendalaIntervensiBumil}
            onChange={(e) => setKendalaIntervensiBumil(e.target.value)}
          ></input>
        </div>

        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Fasilitas kesehatan yang dipilih untuk melahirkan
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            value={pilihanFaskes}
            onChange={(e) => setPilihanFaskes(e.target.value)}
          >
            <option>Pilih opsi</option>
            <option>Puskesmas</option>
            <option>RSUD</option>
            <option>Rumah Sakit Swasta</option>
            <option>Lainya</option>
          </select>
        </div>

        <div className='mb-3'>
          <Button variant='primary'>Input data Sio Sodak</Button>
        </div>

        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Nama Tokoh Agama (Pendeta/Ulama/Pastor dll)
          </label>
          <input
            className='form-control'
            type='text'
            value={namaTA}
            onChange={(e) => setNamaTA(e.target.value)}
            required
          ></input>
        </div>
        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Nomor HP Tokoh Agama
          </label>
          <input
            className='form-control'
            type='text'
            value={nomorTA}
            onChange={(e) => setNomorTA(e.target.value)}
            required
          ></input>
        </div>
        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Nama Tokoh Budaya / Maneleo
          </label>
          <input
            className='form-control'
            type='text'
            value={namaTB}
            onChange={(e) => setNamaTB(e.target.value)}
            required
          ></input>
        </div>
        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Nomor HP Tokoh Budaya / Maneleo
          </label>
          <input
            className='form-control'
            type='text'
            value={nomorTB}
            onChange={(e) => setNomorTB(e.target.value)}
            required
          ></input>
        </div>
        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Nama Kader
          </label>
          <input
            className='form-control'
            type='text'
            value={namaKader}
            onChange={(e) => setNamaKader(e.target.value)}
            required
          ></input>
        </div>
        <div className='mb-3'>
          <label for='exampleFormControlInput1' className='form-label'>
            Nomor HP Kader
          </label>
          <input
            className='form-control'
            type='text'
            value={nomorKader}
            onChange={(e) => setNomorKader(e.target.value)}
            required
          ></input>
        </div>

        <div className='mb-3'>
          <Progres percentage={uploadPercentage} />
        </div>
        <div class='d-grid gap-2'>
          <button disabled={button} className='btn btn-primary' type='submit'>
            Simpan
          </button>
        </div>
      </form>
    </div>
  );
};

export default Edit;
