import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './util.css';
import './main.css';
import { Spinner } from 'reactstrap';
import swal from 'sweetalert';
import { API_MAMABOI } from '../../utils/constant';

const Login = () => {
  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const history = useHistory();
  const [gmail, setgmail] = useState('');
  const [access, setAccess] = useState('');
  const [porsesLogin, setProsesLogin] = useState(false);
  const masuk = async (e) => {
    e.preventDefault();
    setProsesLogin(true);

    //'https://test.mamaboi.net/api/users/login',
    //'http://localhost:4000/api/users/login'

    try {
      const response = await axios.post(API_MAMABOI + 'users/login', {
        email: email,
        password: password,
      });

      if (response.data.success == 0) {
        swal(
          'Failed',
          'Username dan password tidak cocok, periksa kembali',
          'error'
        );
      } else {
        history.push('/layout');
      }
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);

        console.log(error.response);
      }
      swal(
        'Failed',
        'Username dan password tidak cocok, periksa kembali',
        'error'
      );
    }
    setProsesLogin(false);
  };

  return (
    <div className='limiter'>
      <div className='container-login100'>
        <div className='wrap-login100'>
          <form className='login100-form' onSubmit={masuk}>
            <img
              className='logo'
              src='https://mamaboi.rotendaokab.go.id/mamaboi/logomamaboi2.png'
            ></img>

            <span className='login100-form-title p-b-34 p-t-27'>
              {' '}
              Mamabo'i 2.0{' '}
            </span>

            <div
              className='wrap-input100 validate-input'
              data-validate='Enter username'
            >
              <input
                className='input100'
                type='text'
                name='username'
                placeholder='Username'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <span className='focus-input100' data-placeholder='🧑🏻'></span>
            </div>

            <div
              className='wrap-input100 validate-input'
              data-validate='Enter password'
            >
              <input
                className='input100'
                type='password'
                name='pass'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className='focus-input100' data-placeholder='🔒'></span>
            </div>

            <div class='contact100-form-checkbox'>
              <input
                className='input-checkbox100'
                id='ckb1'
                type='checkbox'
                name='remember-me'
              />
              <label className='label-checkbox100' for='ckb1'>
                {' '}
                Remember me{' '}
              </label>
            </div>

            <div className='container-login100-form-btn'>
              {!porsesLogin ? (
                <button type='submit' className='login100-form-btn'>
                  Login
                </button>
              ) : (
                <button
                  type='submit'
                  className='login100-form-btn'
                  style={{ margin: 10 }}
                >
                  <Spinner size='sm' color='black' />
                  Loading ...
                </button>
              )}
            </div>

            <div className='text-center p-t-90'>
              <a className='txt1' href='#'>
                {' '}
                Forgot Password?{' '}
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
