import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadDataStatus from '../task/LoadDataStatus';
import LoadDataInformasi from '../task/LoadDataInformasi';

import './css/register.css';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Button,
  Badge,
  Accordion,
  Carousel,
  Card,
  Modal,
  Alert,
} from 'react-bootstrap';
import LoadDataProfile from '../task/LoadDataProfile';
import { API_MAMABOI } from '../../utils/constant';

const Forum = ({ name, access, id, email }) => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const history = useHistory();
  //progress bar
  const [uploadPercentage, setUploadPercentage] = useState(0);
  //
  const [dataStatus, setDataStatus] = useState([]);
  const [dataInformasi, setDataInformasi] = useState([]);
  const [urlProfil, setUrlProfil] = useState('');

  const load = () => {
    const LoadDataStatusku = new LoadDataStatus();
    LoadDataStatusku.load(setDataStatus, name);
  };

  const load2 = () => {
    const LoadDataInformasiKu = new LoadDataInformasi();
    LoadDataInformasiKu.load(setDataInformasi, name);
  };

  const load3 = () => {
    const LoadDataProfil = new LoadDataProfile();
    LoadDataProfil.load(setUrlProfil, name);
  };

  const [foto, setFoto] = useState([]);
  const [caption, setCaption] = useState('');
  const [tanggal, setTanggal] = useState('');
  const [idUser, setIdUser] = useState('');
  const [idStatus, setIdStatus] = useState('');
  const [idHapus, setIdHapus] = useState('');

  const [komen, setKomen] = useState('');
  const [info, setInfo] = useState('');
  const [judul, setJudul] = useState('');

  const [urlFile1, setUrlFile1] = useState('https://fakeimg.pl/350x300/');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = (e) => {
    setShow2(true);
    setIdStatus(e.target.id);
  };

  const handleClose3 = () => setShow3(false);
  const handleShow3 = (e) => {
    setShow3(true);
  };
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (e) => {
    setShow4(true);
    setIdHapus(e.target.id);
  };

  useEffect(() => {
    setIdUser(name);
    load();
    load2();
    load3();
  }, [name]);

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setFoto(e.target.files[0]);
  }

  const saveProduct = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('photo', foto);
    formData.append('userId', idUser);
    formData.append('caption', caption);

    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    var hour = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getUTCSeconds();
    var newdate = year + '-' + month + '-' + day;
    var newdatetime =
      year +
      '-' +
      month +
      '-' +
      day +
      ' ' +
      hour +
      ':' +
      minutes +
      ':' +
      seconds;

    formData.append('tanggal', newdate);
    formData.append('tanggalKomen', newdatetime);
    //'https://test.mamaboi.net/api/kohort'
    //http://localhost:4000/api/status
    const response = await axios.post(API_MAMABOI + 'status', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (ProgressEvent) => {
        setUploadPercentage(
          parseInt(
            Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
          )
        );

        // console.log(uploadPercentage);
        // //clear percentage
        // setTimeout(() => setUploadPercentage(0), 1000);
      },
    });

    if (response.data.success === 1) {
      alert('Cerita berhasil dibagi');
      history.push('/layout');
      history.push('/layout/forum');
    }
  };

  const saveKomentar = async (e) => {
    e.preventDefault();
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    var hour = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getUTCSeconds();
    var newdate = year + '-' + month + '-' + day;
    var newdatetime =
      year +
      '-' +
      month +
      '-' +
      day +
      ' ' +
      hour +
      ':' +
      minutes +
      ':' +
      seconds;

    const response = await axios.post(
      API_MAMABOI + 'komentar',

      {
        userId: idUser,
        idStatus: idStatus,
        komen: komen,
        tanggal: newdate,
      }
    );

    const response2 = await axios.patch(
      API_MAMABOI + `status/${idStatus}`,

      {
        tanggalKomen: newdatetime,
      }
    );

    if (response2.data.success === 1) {
      alert('komen berhasil diposting');
      history.push('/layout');
      history.push('/layout/forum');
    }
  };

  const saveInfo = async (e) => {
    e.preventDefault();

    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    var newdate = year + '-' + month + '-' + day;
    const response = await axios.post(
      API_MAMABOI + 'informasi',

      {
        userId: name,
        info: info,
        tanggal: newdate,
        judul: judul,
      }
    );

    if (response.data.success === 1) {
      alert('informasi berhasil diposting');
      history.push('/layout');
      history.push('/layout/forum');
    }
  };

  const deleteStatus = async (e) => {
    e.preventDefault();

    const response = await axios.delete(API_MAMABOI + `status/${idHapus}`);

    if (response.data.success === 1) {
      alert('status berhasil dihapus');
      history.push('/layout');
      history.push('/layout/forum');
    }
  };

  return (
    <div>
      <div className='detailsII'></div>
      <div className='details'>
        <div>
          <h2 className='heading'>Status</h2>
          {dataStatus.map((status, index) => {
            return (
              <div className='statusku'>
                <Card>
                  <Card.Img variant='top' src={status[2]} />
                  <Card.Body>
                    <Card.Text>
                      <table>
                        <tr>
                          <td width='60px'>
                            <div className='imgPr'>
                              <img
                                className='profileKu'
                                src={status[7]}
                                alt=''
                              />
                            </div>
                          </td>
                          <td>
                            <table>
                              <tr>
                                <h5>
                                  <span>{status[1]} </span>
                                </h5>
                              </tr>
                              <tr>
                                <h7>{status[4]}</h7>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>{status[3]}</td>
                        </tr>
                      </table>
                      <table className='likes'>
                        <tr>
                          <td className='like'>
                            <span className='icon'>
                              <i className='bx bxs-like'></i>
                            </span>{' '}
                            Like
                          </td>
                          <td
                            className='like'
                            id={status[0]}
                            onClick={handleShow2}
                          >
                            <span className='icon'>
                              <i className='bx bxs-comment'></i>
                            </span>{' '}
                            Comment
                          </td>
                          {status[1] === name ? (
                            <td
                              className='like'
                              id={status[0]}
                              onClick={handleShow4}
                            >
                              <span className='icon'>
                                <i className='bx bxs-trash'></i>
                              </span>{' '}
                              delete
                            </td>
                          ) : (
                            <div></div>
                          )}
                        </tr>
                      </table>
                      {status[5].map((row) => (
                        <div className='komentars'>
                          <table>
                            <tr>
                              <td width='60px'>
                                <div className='imgPrKomen'>
                                  <img
                                    className='profileKomen'
                                    src={row.urlKomen}
                                    alt=''
                                  />
                                </div>
                              </td>
                              <td>
                                <p className='namaKomen'>{row.userId}</p>
                                <p className='tanggalKomen'>{row.tanggal}</p>
                                <p>{row.komen}</p>
                              </td>
                            </tr>
                          </table>
                        </div>
                      ))}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            );
          })}

          <div className='floatKiri' onClick={handleShow}>
            <img className='addBtn' src='https://mamaboi.net/mamaboi/add.png' />
          </div>
          {access === 'Kabupaten' ? (
            <div className='floatKiri2' onClick={handleShow3}>
              <img
                className='addBtn'
                src='https://mamaboi.net/mamaboi/infoKu.png'
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div>
          <h2 className='heading'>Sekretariat Info</h2>

          <Accordion defaultActiveKey={['1', '2', '3', '4', '5']} alwaysOpen>
            {dataInformasi.map((row) => (
              <Accordion.Item eventKey={row.index}>
                <Accordion.Header>
                  <div className='imgPrInfo'>
                    <img className='profileInfo' src={row.urlInfo} alt='' />
                  </div>
                  <span className='judulInfo'>{row.judul}</span>
                  <span className='icon'>
                    <i className='bx bx-volume-full'></i>
                  </span>{' '}
                  #{row.index}
                </Accordion.Header>
                <Accordion.Body>
                  {row.info}
                  <br></br>
                  <br></br>
                  <p className='tanggalKomen'>Sender : {row.userId}</p>

                  <p className='tanggalKomen'>Tanggal : {row.tanggal}</p>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} animation={false}>
        <form onSubmit={saveProduct}>
          <Modal.Header closeButton>
            <table>
              <tr>
                <td width='60px'>
                  <div className='imgPr'>
                    <img className='profileKu' src={urlProfil} alt='' />
                  </div>
                </td>
                <td>
                  <table>
                    <tr>
                      <h5>
                        <span>{idUser} </span>
                      </h5>
                    </tr>
                    <tr>
                      <h7></h7>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-3'>
              <label>Upload Foto</label>
              <div>
                <img src={urlFile1} alt='' width={'100%'} />
              </div>

              <label className='file-label'>
                <input
                  className='form-control'
                  type='file'
                  name='resume'
                  onChange={onChange1}
                  accept={'image/*'}
                  required
                />
                <span className='file-cta'>
                  <span class='file-icon'>
                    <i class='fas fa-upload'></i>
                  </span>
                </span>
              </label>
            </div>
            <Form.Group
              className='mb-3'
              controlId='exampleForm.ControlTextarea1'
            >
              <Form.Label>ceritakan sesuatu tentang foto ini</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Batal
            </Button>
            <Button variant='primary' type='submit'>
              Post
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={show2} onHide={handleClose2} animation={false}>
        <form onSubmit={saveKomentar}>
          <Modal.Header closeButton>Komentar</Modal.Header>
          <Modal.Body>
            <Form.Group
              className='mb-3'
              controlId='exampleForm.ControlTextarea1'
            >
              <Form.Control
                as='textarea'
                rows={3}
                value={komen}
                onChange={(e) => setKomen(e.target.value)}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose2}>
              Batal
            </Button>
            <Button variant='primary' type='submit'>
              Post
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={show3} onHide={handleClose3} animation={false}>
        <form onSubmit={saveInfo}>
          <Modal.Header closeButton>Informasi</Modal.Header>
          <Modal.Body>
            <Form.Group
              className='mb-3'
              controlId='exampleForm.ControlTextarea1'
            >
              <Form.Label>Judul informasi</Form.Label>
              <Form.Control
                type='text'
                placeholder='Judul informasi'
                value={judul}
                onChange={(e) => setJudul(e.target.value)}
                required
              />
              <Form.Label>Informasi</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                value={info}
                onChange={(e) => setInfo(e.target.value)}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose3}>
              Batal
            </Button>
            <Button variant='primary' type='submit'>
              Post
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Hapus status</Modal.Title>
        </Modal.Header>
        <Modal.Body>Apakah anda ingin menghapus status ini ?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose4}>
            cancel
          </Button>
          <Button variant='primary' onClick={deleteStatus}>
            delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Forum;
