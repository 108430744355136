import './card.css';

const Card = (props) => {
  return (
    <div>
      <div className='card'>
        <div>
          <div className='number'>{props.count}</div>
          <div className='cardName'>{props.title}</div>
        </div>
        <div className='iconBx'>
          <i className={props.icon}></i>
        </div>
      </div>
    </div>
  );
};

export default Card;
