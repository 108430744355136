import './table2.css';
import LoadDataPend from '../task/LoadDataPartus';
import { useState, useEffect } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Table, Button, Badge } from 'react-bootstrap';

const Table2 = ({ access }) => {
  const [dataPartus2m, setdataPartus2m] = useState([]);

  const load = () => {
    const loadData = new LoadDataPend();
    loadData.load(setdataPartus2m, access);
  };

  useEffect(load, [access]);

  return (
    <div>
      <div className='recentCustomers'>
        <div className='cardHeader'>
          <h2>
            2 Minggu Partus <Badge bg='danger'>{dataPartus2m.length}</Badge>
          </h2>

          <ReactHTMLTableToExcel
            className='btn'
            table='dataIbu2M'
            filename='2 Minggu melahirkan'
            sheet='tidak berkunjung'
            buttonText='Export to Exl '
          />
        </div>
        <div className='table-wrapper'>
          <Table id='dataIbu2M'>
            {dataPartus2m.map((r, index) => (
              <tr key={index}>
                <td width='60px'>
                  <div className='imgBx'>
                    <img src={r.foto} alt='' />
                  </div>
                </td>
                <td>
                  <h4>
                    {r.namaIbu} <br />
                    <span>{r.puskesmas}</span>
                    <span>{'        ' + r.usiaHamil + ' minggu'}</span>
                    <span>{'        ' + r.desa}</span>
                    <span>{' ☎️ ' + r.noHp}</span>
                  </h4>
                </td>
              </tr>
            ))}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Table2;
