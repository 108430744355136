import axios from 'axios';
import { API_MAMABOI } from '../../utils/constant';

class LoadDataKunjunganIbu {
  // mendapatkan data
  load = (setData, access) => {
    this.setData = setData;
    this.access = access;
    this.#proccessData();
  };

  #proccessData = async () => {
    var dataIbuku = [];
    var dataIbusaring = [];

    try {
      const response = await axios.get(API_MAMABOI + 'kohort');

      dataIbuku = response.data.data;

      let com1 = 'Ibu hamil';
      let com2 = 'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)';
      let com3 = '';
      var equal = '';

      if (this.access !== 'Kabupaten') {
        dataIbusaring = dataIbuku.filter((r) => {
          com3 = r.statusIbu;
          return (
            r.puskesmas.toUpperCase() == this.access.toUpperCase() &&
            (com3.toUpperCase() == com1.toUpperCase() ||
              com3.toUpperCase() == com2.toUpperCase())
          );
        });
      } else {
        dataIbusaring = dataIbuku.filter((r) => {
          com3 = r.statusIbu;

          return (
            com3.toUpperCase() == com1.toUpperCase() ||
            com3.toUpperCase() == com2.toUpperCase()
          );
        });
      }

      dataIbusaring.sort((a, b) => {
        if (a.puskesmas < b.puskesmas) return -1;
        return a.puskesmas > b.puskesmas ? 1 : 0;
      });
    } catch (error) {
      console.error(error);
    }

    var dataKunjungan;
    try {
      const response = await axios.get(API_MAMABOI + `kunjungan`);

      dataKunjungan = response.data.data;
    } catch (error) {
      console.error(error);
    }

    var dataKunjunganIbu = dataIbusaring.map((r) => {
      var kunjungan = dataKunjungan.filter(function (item) {
        return item.idIbu === r.id;
      });

      var row = kunjungan.length - 1;
      if (kunjungan.length > 0) {
        return [
          r.id,
          r.namaIbu,
          r.puskesmas,
          r.fotoIbu,
          r.nomorHpIbu,
          kunjungan.length,
          kunjungan[row].tanggalKun,
          kunjungan[row].usiaHamil,
          kunjungan[row].tekananDarah,
          kunjungan[row].tinggiFundus,
          kunjungan[row].lila,
          kunjungan[row].kek,
          kunjungan[row].jantung,
          kunjungan[row].kepala,
          kunjungan[row].berat,
          kunjungan[row].persentase,
          kunjungan[row].statusPasien,
          kunjungan[row].bumilResiko,
          kunjungan[row].bukuKia,
          kunjungan[row].keluhan,
          kunjungan[row].tataLaksana,
          kunjungan[row].konselingP4K,
          kunjungan[row].imunisasiTT,
          kunjungan[row].tabletTD,
          kunjungan[row].hb,
          kunjungan[row].tripleEliminasi,
          kunjungan[row].hepatitis,
          kunjungan[row].hiv,
          kunjungan[row].malaria,
          kunjungan[row].tb,
          kunjungan[row].ims,
          kunjungan[row].golDarah,
          kunjungan[row].komplikasi,
          kunjungan[row].alasanRujuk,
          kunjungan[row].posterTinggi,
          kunjungan[row].fotoPoster,
          kunjungan[row].posterTinggi,
          kunjungan[row].dukunganKeluarga,
          kunjungan[row].uploadDukungan,
        ];
      } else {
        return [
          r.id,
          r.namaIbu,
          r.puskesmas,
          r.fotoIbu,
          r.nomorHpIbu,
          kunjungan.length,
        ];
      }
    });

    this.setData(dataKunjunganIbu);
  };
}

export default LoadDataKunjunganIbu;
