import axios from 'axios';
import { API_MAMABOI } from '../../utils/constant';

class LoadDataPesanTidakBerkunjung {
  // mendapatkan data
  load = (setData, access) => {
    this.setData = setData;
    this.access = access;
    this.#proccessData();
  };

  #proccessData = async () => {
    var dataIbuku = [];
    var dataKunjungan = [];

    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbuku = response.data.data;
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await axios.get(API_MAMABOI + 'kunjungan');
      dataKunjungan = response.data.data;
    } catch (error) {
      console.error(error);
    }

    //mencari ibu 2 minggu partus
    let com1 = 'Ibu hamil';
    let com2 = 'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)';
    let com3 = '';

    var dataIbuTidakKunjung = dataIbuku.map((item) => {
      com3 = item.statusIbu;
      if (
        com3.toUpperCase() === com1.toUpperCase() ||
        com3.toUpperCase() === com2.toUpperCase()
      ) {
        var tglKunTer = '';

        var kunjungan = dataKunjungan.filter((filter) => {
          return filter.idIbu === item.id;
        });

        if (kunjungan.length === 0) {
          tglKunTer = item.tanggalKun;
        } else {
          var kunjunganAkhir = kunjungan[kunjungan.length - 1];
          tglKunTer = kunjunganAkhir.tanggalKun;
        }

        //perhitungan selisih tanggal utk menentukan minggu partus
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();
        var newdate = year + '-' + month + '-' + day;
        var d1 = new Date(tglKunTer);
        var d2 = new Date(newdate);

        var jarakWaktu = Math.round((d2 - d1) / 1000 / 60 / 60 / 24);

        if (jarakWaktu >= 40) {
          var tidakKunjung = {};
          tidakKunjung.namaIbu = item.namaIbu;
          tidakKunjung.puskesmas = item.puskesmas;
          tidakKunjung.jarakKunjung = jarakWaktu;
          tidakKunjung.desa = item.desaIbu;
          tidakKunjung.hp = item.nomorHpIbu;
          tidakKunjung.status = item.status_Pj;
          tidakKunjung.foto = item.fotoIbu;
          tidakKunjung.hpSuami = item.noHpSuami;
          tidakKunjung.hpBidan = item.noHpBidan;
          tidakKunjung.bidan = item.bidan;
          tidakKunjung.suami = item.namaSuami;

          //sio sodak
          tidakKunjung.namaTA = item.namaTA;
          tidakKunjung.nomorTA = item.nomorTA;
          tidakKunjung.namaTB = item.namaTB;
          tidakKunjung.nomorTB = item.nomorTB;
          tidakKunjung.namaKader = item.namaKader;
          tidakKunjung.nomorKader = item.nomorKader;

          return tidakKunjung;
        }
      }
    });

    let tidakBerkunjung = dataIbuTidakKunjung.filter((el) => {
      return el != null && el != '';
    });

    if (this.access != 'Kabupaten') {
      tidakBerkunjung = tidakBerkunjung.filter((r) => {
        return r.puskesmas == this.access;
      });
    }

    this.setData(tidakBerkunjung);
  };
}

export default LoadDataPesanTidakBerkunjung;
