import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import Login from './components/login/Login';

import React, { useRef, useState, useEffect } from 'react';

import Layout2 from './components/layout/Layout2';

function App() {
  let tog = useRef(null);
  let navigation = useRef(null);
  let main = useRef(null);

  let navLi = useRef(null);

  function onClick() {
    navigation.current.classList.toggle('active');
    main.current.classList.toggle('active');
  }

  let list = document.querySelectorAll('.navigation .navi li');
  function activeLink() {
    list.forEach((item) => item.classList.remove('hovered'));
    this.classList.add('hovered');
  }
  list.forEach((item) => item.addEventListener('mouseover', activeLink));

  return (
    <HashRouter>
      <Switch>
        <Route exact path='/'>
          <Login />
        </Route>
        <Route path='/layout'>
          <Layout2 />
        </Route>
      </Switch>
    </HashRouter>
  );
}
export default App;
