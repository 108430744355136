import axios from 'axios';

import { API_MAMABOI } from '../../utils/constant';

class LoadDataCardKematian {
  // mendapatkan data
  load = (setData, access) => {
    this.setData = setData;
    this.access = access;
    this.#proccessData();
  };

  #proccessData = async () => {
    var dataIbuBersalin = [];
    var dataIbu = [];

    try {
      const response = await axios.get(API_MAMABOI + 'bersalin');
      dataIbuBersalin = response.data.data;
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbu = response.data.data;
    } catch (error) {
      console.error(error);
    }

    var ibuMeninggal = 0;
    var bayiMeninggal = 0;

    if (this.access === 'Kabupaten') {
      //mencari jumlah ibu hamil perdesa
      let com1 = 'Meninggal';
      let com2 = 'Lahir mati';
      dataIbuBersalin.map((r) => {
        if (
          r.kondisiSetelahMelahirkan.toUpperCase() === com1.toUpperCase() &&
          r.alasanMeninggal.toUpperCase() !== 'LAINYA'
        ) {
          ibuMeninggal = ibuMeninggal + 1;
        }
        if (r.kondisiBayi.toUpperCase() === com2.toUpperCase()) {
          if (r.idHamil !== '') {
            bayiMeninggal = bayiMeninggal + 1;
          }
        }
      });
    } else {
      let com1 = 'Meninggal';
      let com2 = 'Lahir mati';

      const k2Filter = dataIbuBersalin.filter((k2) => {
        var cek = false;

        if (k2.idHamil !== '') {
          var dataIbuKu = dataIbu.filter((item) => {
            return item.id == k2.idHamil;
          });

          if (dataIbuKu.length > 0) {
            if (dataIbuKu[0].puskesmas == this.access) {
              cek = true;
            }
          }
        }
        return cek == true;
      });

      //console.log(k2Filter);

      k2Filter.map((r) => {
        if (
          r.kondisiSetelahMelahirkan.toUpperCase() === com1.toUpperCase() &&
          r.alasanMeninggal.toUpperCase() !== 'LAINYA'
        ) {
          ibuMeninggal = ibuMeninggal + 1;
        }
        if (r.kondisiBayi.toUpperCase() === com2.toUpperCase()) {
          bayiMeninggal = bayiMeninggal + 1;
        }
      });
    }

    var listStatus = [
      {
        icon: 'bx bxs-user-pin',
        count: 0,
        title: 'Ibu Meninggal (Kab)',
      },
      {
        icon: 'bx bxs-user-rectangle',
        count: 7,
        title: 'Bayi Meninggal (Kab)',
      },
    ];

    this.setData(listStatus);
  };
}

export default LoadDataCardKematian;
