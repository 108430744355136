import React, { useState, useEffect } from 'react';
import './css/dataIbu.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Table, Button } from 'react-bootstrap';

//import LoadDataIbu from '../task/LoadDataIbu';

import LoadDataRujukanIbu from '../task/LoadDataRujukanIbu';

const DataRujukanIbu = ({ data }) => {
  const [kecamatan, setKecamatan] = useState('');
  const [dataIbus, setDataIbus] = useState([]);
  const [dataIbusaring, setDataIbusaring] = useState([]);

  const load = () => {
    const LoadDataIbuku = new LoadDataRujukanIbu();
    LoadDataIbuku.load(setDataIbusaring, data);
  };

  useEffect(() => {
    load();
  }, []);

  // const getDataIbu = async () => {
  //   try {
  //     const response = await axios.get('https://test.mamaboi.net/api/kohort');

  //     setDataIbus(response.data.data);
  //     if (data !== 'Kabupaten') {
  //       const dataIbusaring = dataIbus.filter((r) => {
  //         return (
  //           r.puskesmas === data &&
  //           (r.statusIbu === 'Ibu hamil' ||
  //             r.statusIbu ===
  //               'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)')
  //         );
  //       });
  //       setDataIbusaring(dataIbusaring);
  //     } else {
  //       setDataIbusaring(dataIbus);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const deleteProduct = async (id) => {
    alert('Data detail ibu belum bisa ditampilkan');
    // getDataIbu();
  };

  return (
    <div>
      <div className='detailsII'>
        <div className='dataIbu'>
          <div class='cardHeader'>
            <h2>#Data Rujukan Ibu</h2>
            <ReactHTMLTableToExcel
              className='btn'
              table='dataIbu'
              filename='export Data Rujukan Ibu'
              sheet='Data Rujukan Ibu'
              buttonText='Export to Exl'
            />
          </div>

          <div className='table-wrapper'>
            <Table id='dataIbu' striped bordered hover size='sm'>
              <thead>
                <tr>
                  <th>No</th>
                  <th width='150px'>Action</th>
                  <th>Nama</th>
                  <th>Puskesmas</th>
                  <th>tanggal Rujuk</th>
                  <th>Waktu Rujuk</th>
                  <th>Alasan Rujuk</th>
                  <th>Tujuan Rujuk</th>
                  <th>Dokumen Pendukung</th>
                  <th>Tindakan</th>
                  <th>Perkiraan Tiba</th>
                  <th>kondisi Ibu</th>

                  {/* <th>Tanggal Akhir Mens</th> */}
                </tr>
              </thead>
              <tbody>
                {dataIbusaring.map((dataibu, index) => (
                  <tr key={dataibu[0]}>
                    <td> {index + 1}</td>
                    <td>
                      <Button
                        variant='danger'
                        onClick={() => deleteProduct(dataibu.id)}
                      >
                        detail
                      </Button>
                    </td>
                    <td> {dataibu[1]}</td>
                    <td> {dataibu[2]}</td>
                    <td> {dataibu[3]}</td>
                    <td> {dataibu[4]}</td>
                    <td> {dataibu[5]}</td>
                    <td> {dataibu[6]}</td>
                    <td> {dataibu[7]}</td>
                    <td> {dataibu[8]}</td>
                    <td> {dataibu[9]}</td>
                    <td> {dataibu[10]}</td>
                    {/* <td> {dataibu.tanggalMensAkhir}</td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataRujukanIbu;
