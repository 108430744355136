import axios from 'axios';
import { features } from '../../assets/JsonData/kab_Rote.json';
import dataDesa from '../../assets/JsonData/daftarDesa.json';
import { API_MAMABOI } from '../../utils/constant';

class LoadMapModif {
  // mendapatkan data
  load = (setDesa) => {
    this.setDesa = setDesa;
    this.#proccessData();
  };

  #proccessData = async () => {
    var dataIbuku = [];
    var datadesaku = dataDesa;
    var dataIbuHamil = [];

    var com1 = 'Ibu hamil';
    var com2 = 'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)';
    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbuku = response.data.data;
    } catch (error) {
      console.error(error);
    }

    var ibuDesaHamil = dataIbuku.filter((ibu) => {
      return (
        ibu.statusIbu.toUpperCase() === com1.toUpperCase() ||
        ibu.statusIbu === com2.toUpperCase()
      );
    });

    // console.log(dataIbuku);

    // var list = datadesaku.map((r) => {
    //   var ibuDesa = ibuDesaHamil.filter((ibu) => {
    //     return ibu['desaIbu'] == r.desa;
    //   });

    //   var krr = ibuDesaHamil.filter((ibu) => {
    //     return ibu['desaIbu'] == r.desa && ibu.status_Pj === 'KRR';
    //   });

    //   var krt = ibuDesaHamil.filter((ibu) => {
    //     return ibu['desaIbu'] == r.desa && ibu.status_Pj === 'KRT';
    //   });

    //   var krst = ibuDesaHamil.filter((ibu) => {
    //     return ibu['desaIbu'] == r.desa && ibu.status_Pj === 'KRST';
    //   });

    //   var colorHamil = 'blue';

    //   var jmlKrr = krr.length;
    //   var jmlKrt = krt.length;
    //   var jmlKrst = krst.length;

    //   if (jmlKrr > 0) {
    //     colorHamil = 'green';
    //   }
    //   if (jmlKrt > 0) {
    //     colorHamil = 'yellow';
    //   }
    //   if (jmlKrst > 0) {
    //     colorHamil = 'red';
    //   }

    //   return [r['desa'], jmlKrr, jmlKrt, jmlKrst, ibuDesa.length, colorHamil];
    // });

    //console.log(dataIbuHamil)
    // console.log('features bro', features);

    var desaku = [];
    var desa;
    for (let i = 0; i < features.length; i++) {
      desa = features[i];

      // const hamilDesa = dataIbuHamil.find(
      //   (hamilDesa) => desa.properties.DESA === hamilDesa[0]
      // );
      // let perDesa = list.filter((r) => {
      //   return r[0] === desa.properties.DESA;
      // });

      var ibuDesa = ibuDesaHamil.filter((ibu) => {
        return (
          ibu['desaIbu'].toUpperCase() === desa.properties.DESA.toUpperCase()
        );
      });

      var krr = ibuDesaHamil.filter((ibu) => {
        return (
          ibu['desaIbu'].toUpperCase() === desa.properties.DESA.toUpperCase() &&
          ibu.status_Pj === 'KRR'
        );
      });

      var krt = ibuDesaHamil.filter((ibu) => {
        return (
          ibu['desaIbu'].toUpperCase() === desa.properties.DESA.toUpperCase() &&
          ibu.status_Pj === 'KRT'
        );
      });

      var krst = ibuDesaHamil.filter((ibu) => {
        return (
          ibu['desaIbu'].toUpperCase() === desa.properties.DESA.toUpperCase() &&
          ibu.status_Pj === 'KRST'
        );
      });

      var colorHamil = 'blue';

      var jmlKrr = krr.length;
      var jmlKrt = krt.length;
      var jmlKrst = krst.length;

      if (ibuDesa.length > 0) {
        colorHamil = 'green';
      }
      if (jmlKrt > 0) {
        colorHamil = 'orange';
      }
      if (jmlKrst > 0) {
        colorHamil = 'red';
      }

      // return [r['desa'], jmlKrr, jmlKrt, jmlKrst, ibuDesa.length, colorHamil];

      desa.properties.KRR = jmlKrr;
      desa.properties.KRT = jmlKrt;
      desa.properties.KRST = jmlKrst;
      desa.properties.HAMIL = ibuDesa.length;
      desa.properties.COLOR = colorHamil;

      // if (hamilDesa != null) {
      //   let angkaHamil = Number(hamilDesa[1]);

      //   desa.properties.HAMIL = angkaHamil;
      //   desa.properties.COLOR = hamilDesa[2];
      // }

      desaku.push(desa);
    }
    //console.log('ini desa ku bro', desaku);
    //return desaku;
    this.setDesa(desaku);
  };
}

export default LoadMapModif;
