import './topbar.css';
import { Nav, Accordion, Badge } from 'react-bootstrap';

const NavigationBootsrap = ({ access }) => {
  return (
    <div>
      <img
        id='imgKu'
        src='https://mamaboi.rotendaokab.go.id/mamaboi/logomamaboi2.png'
      ></img>
      <Nav defaultActiveKey='#/layout' className='flex-column'>
        <Nav.Link href='#/layout'>Dashboard</Nav.Link>
        <Nav.Link href='#layout/forum'>
          {' '}
          <span className='icon'>
            <i className='bx bx-volume-full'></i>
          </span>{' '}
          Forum
          <Badge bg='danger' className='badgeKu'>
            {2}
          </Badge>
        </Nav.Link>
      </Nav>

      <Accordion>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            <span className='icon'>
              <i className='bx bxs-dashboard'></i>
            </span>
            Ibu Hamil{' '}
          </Accordion.Header>
          <Accordion.Body>
            <Nav defaultActiveKey='/layout' className='flex-column'>
              <Nav.Link href='#layout/input'>Input data dasar</Nav.Link>
              <Nav.Link href='#layout/kunjungan'>Kunjungan Ibu Hamil</Nav.Link>
              <Nav.Link href='#layout/dataibu'>Data Dasar Ibu hamil</Nav.Link>
              <Nav.Link href='#layout/datakunjunganibu'>
                Data Kunjungan Ibu
              </Nav.Link>
              <Nav.Link href='#layout/laporan'>Data Triple Eliminasi</Nav.Link>
              <Nav.Link href='#layout/terminate'>Akhiri Data Ibu</Nav.Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='1'>
          <Accordion.Header>
            {' '}
            <span className='icon'>
              <i className='bx bxs-user'></i>
            </span>
            Ibu Bersalin
          </Accordion.Header>
          <Accordion.Body>
            <Nav defaultActiveKey='' className='flex-column'>
              <Nav.Link href='#layout/bersalin'>
                Input data Ibu bersalin
              </Nav.Link>
              <Nav.Link href='#layout/kunjunganbersalin'>
                Kunjungan Ibu bersalin
              </Nav.Link>
              <Nav.Link href='#layout/dataibubersalin'>
                Data Ibu bersalin
              </Nav.Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='2'>
          <Accordion.Header>
            <span className='icon'>
              <i className='bx bxs-check-shield'></i>
            </span>
            Data Bayi
          </Accordion.Header>

          <Accordion.Body>
            <Nav defaultActiveKey='' className='flex-column'>
              <Nav.Link href=''>Input data bayi</Nav.Link>
              <Nav.Link href=''>Kunjungan bayi</Nav.Link>
              <Nav.Link href=''>Data bayi</Nav.Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='3'>
          <Accordion.Header>
            {' '}
            <span className='icon'>
              <i className='bx bxs-hotel'></i>
            </span>{' '}
            Rujukan
          </Accordion.Header>

          <Accordion.Body>
            <Nav defaultActiveKey='' className='flex-column'>
              <Nav.Link href='#layout/rujukanibu'>Rujukan Ibu</Nav.Link>
              <Nav.Link href='#layout/rujukanbayi'>Rujukan Bayi</Nav.Link>
              <Nav.Link href='#layout/datarujukanibu'>
                Laporan Rujukan Ibu
              </Nav.Link>
              <Nav.Link href='#layout/datarujukanbayi'>
                Laporan Rujukan Bayi
              </Nav.Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>

        {access === 'Kabupaten' ? (
          <Accordion.Item eventKey='4'>
            <Accordion.Header>
              {' '}
              <span className='icon'>
                <i className='bx bxl-whatsapp-square'></i>
              </span>{' '}
              Kirim Pesan
            </Accordion.Header>

            <Accordion.Body>
              <Nav defaultActiveKey='' className='flex-column'>
                <Nav.Link href='#layout/pesan40hari'>
                  40 hari tidak berkunjung
                </Nav.Link>
                <Nav.Link href='#/layout/pesan2minggu'>
                  2 Minggu Melahirkan
                </Nav.Link>
                <Nav.Link href='#layout/pesanedukasi'>
                  Edukasi/Informasi
                </Nav.Link>
              </Nav>
            </Accordion.Body>
          </Accordion.Item>
        ) : (
          <div></div>
        )}
        <Accordion.Item eventKey='5'>
          <Accordion.Header>
            {' '}
            <span className='icon'>
              <i className='bx bxl-medium-old'></i>
            </span>{' '}
            Analitycs
          </Accordion.Header>

          <Accordion.Body>
            <Nav defaultActiveKey='' className='flex-column'>
              <Nav.Link href='#layout/analitycs'>Analisa Data Ibu</Nav.Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Nav defaultActiveKey='#/layout' className='flex-column'>
        {access === 'Kabupaten' ? (
          <Nav.Link href='#layout/register'>Register</Nav.Link>
        ) : (
          <div></div>
        )}
        <Nav.Link eventKey='link-1'>Log Out</Nav.Link>
      </Nav>
    </div>
  );
};

export default NavigationBootsrap;
