import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './css/input.css';
import { Form, Card, Button, Modal } from 'react-bootstrap';
import LoadDataIbu from '../task/LoadDataIbu';
import { API_MAMABOI } from '../../utils/constant';

const Terminate = ({ data }) => {
  const [nama, setNama] = useState('');
  const [desa, setDesa] = useState('');
  const [button, setButton] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState('');
  const [fotoIbu, setFoto] = useState('https://fakeimg.pl/250x250/');
  const [tglTerminate, setTglTerminate] = useState('');
  const [alasanTerminate, setalasanTerminate] = useState('');
  const [ibuHamil, setIbuHamil] = useState([]);
  const load = () => {
    const loadData = new LoadDataIbu();
    loadData.load(setIbuHamil, data);
  };

  useEffect(() => {
    load();
  }, [data]);

  const [idIbu, setIdIbu] = useState('');

  // Add/Remove checked item from list

  //handling chencked

  const history = useHistory();
  // function cariData() {
  //   const response = axios.get('http://localhost:5000/products');
  //   const dataIbu = response.data;
  //   console.log(dataIbu);
  // }

  const cariData = async (e) => {
    var dataIbus;
    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbus = response.data.data;
    } catch (error) {
      console.error(error);
    }

    if (dataIbus == null) {
      alert('data tidak ditemukan');
      setNama('');
      return;
    }
    let temukanIbu = dataIbus.find((item) => {
      var a = item.namaIbu.split(' ').join('');
      var b = e.target.value.split(' ').join('');
      return a === b;
    });

    if (temukanIbu != null) {
      setNama(temukanIbu.namaIbu);

      setDesa(temukanIbu.desaIbu);
      setFoto(temukanIbu.fotoIbu);
      setStatus(temukanIbu.statusIbu);
      setIdIbu(temukanIbu.id);

      // const date = new Date(temukanIbu.hpht);
      // addDays(date, 280);
    } else {
      alert('Data Ibu tidak ditemukan');
    }
  };

  function konfirmasi(e) {
    e.preventDefault();
    handleShow();
  }

  const handleClose = () => {
    setShow(false);
    setShow2(false);
  };
  const handleShow = () => setShow(true);

  const saveKunjungan = async () => {
    setButton(true);

    const formData = new FormData();

    formData.append('statusIbu', alasanTerminate);

    const response = await axios.patch(
      API_MAMABOI + `kohort/bersalin/${idIbu}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    if (response.data.success === 1) {
      alert(`Data Status Kehamilan  Ibu ` + nama + ` berhasil diakhiri`);
      history.push('/layout/dataibu');
      history.push('/layout/terminate');
      setButton(true);
    }
    handleClose();
    setShow2(false);
  };

  return (
    <div>
      <div className='detailsII'>
        <div className='dataIbu'>
          <div class='mb-3'>
            <label for='inputState' className='form-label'>
              Nama Ibu yang ingin diakhiri statusnya
            </label>
            <select id='inputState' className='form-select' onChange={cariData}>
              <option value='' disabled selected>
                Pilih nama ibu
              </option>
              {ibuHamil.map((ibu, index) => (
                <option>{ibu.namaIbu}</option>
              ))}
            </select>
          </div>
        </div>
        {/* <div className='dataIbu'>
          <div className='field'>
            <label className='label'>Desa</label>
            <div
            // className='select is-fullwidth'
            // value={kecamatan}
            // onChange={(e) => setKecamatan(e.target.value)}
            >
              <select>
                <option>Pilih nama Desa</option>
                <option>Rumah</option>
                <option>Posyandu</option>
                <option>Puskesmas</option>
                <option>Rumah Sakit</option>
                <option>Lainya</option>
              </select>
            </div>
          </div>
        </div> */}
      </div>
      {nama === '' ? (
        <div></div>
      ) : (
        <div>
          <div className='detailsI'>
            <div className='dataIbu'>
              <div className='field'>
                <Card>
                  <Card.Header>Featured</Card.Header>
                  <Card.Body>
                    <Card.Title>Special title treatment</Card.Title>
                    <Card.Text>
                      <table>
                        <tr>
                          <td>
                            <label className='label'>Nama Ibu</label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{nama}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className='label'>Alamat</label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{desa}</label>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <label className='label'>Status Ibu</label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{status}</label>
                          </td>
                        </tr>
                      </table>
                    </Card.Text>
                    <Button variant='primary' onClick={() => setShow2(true)}>
                      Akhiri data ibu
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>

            <Card style={{ width: '18rem' }}>
              <Card.Img variant='top' src={fotoIbu} alt='profile' />
              <Card.Body>
                <Card.Title>Ny. {nama}</Card.Title>
                <Card.Text>status : {status}</Card.Text>
                <Button variant='primary'>Lihat detail</Button>
              </Card.Body>
            </Card>
          </div>

          <div className='detailsII'>
            <div className='dataIbu'>
              <Modal
                show={show2}
                onHide={() => setShow2(false)}
                dialogClassName='modal-90w'
                aria-labelledby='example-custom-modal-styling-title'
              >
                <Modal.Header closeButton>
                  <Modal.Title id='example-custom-modal-styling-title'>
                    Catat Perubahan Status
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form onSubmit={konfirmasi}>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Tanggal Status Ibu Hamil berakhir
                      </label>
                      <input
                        className='form-control'
                        type='date'
                        value={tglTerminate}
                        onChange={(e) => setTglTerminate(e.target.value)}
                        required
                      ></input>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Alasan status kehamilan berakhir
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={alasanTerminate}
                        onChange={(e) => setalasanTerminate(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Abortus </option>
                        <option>Mola Hidatidosa</option>
                        <option>KET </option>
                        <option>Pindah Luar Kabupaten</option>
                      </select>
                    </div>

                    <div class='d-grid gap-2'>
                      <button
                        disabled={button}
                        className='btn btn-primary'
                        type='submit'
                      >
                        Simpan
                      </button>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Rubah Status Ibu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Apakah anda ingin mengakhiri status Ibu Hamil {nama} menjadi{' '}
                  {alasanTerminate}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={handleClose}>
                    Batal
                  </Button>
                  <Button variant='primary' onClick={saveKunjungan}>
                    Simpan
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Terminate;
