import './topbar.css';
import { Modal, Card } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import LoadDataIbu from '../task/LoadDataIbu';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import hpBidan from '../../assets/JsonData/noHpBidan.json';
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Button,
  Badge,
} from 'react-bootstrap';
import LoadDataProfile from '../task/LoadDataProfile';
import { API_MAMABOI } from '../../utils/constant';

const TopNav = ({ access, nama, email }) => {
  const [show, setShow] = useState(false);
  const [profileKu, setProfileKu] = useState('');
   


  const handleClose = () => {
    setShow(false);
    setNamaIbu('');
  };
  const handleShow = () => {
    setShow(true);
    setNamaIbu('');
  };

  const kirimPesan = () => {
    //'https://teras.wablas.com/api/send-message'
    var url = 'https://pati.wablas.com/api/send-message';
    var Tokenwhatsap =
      'IONVgbV7rm3bzVoDI4jjm9zRFwDmA6j1AzgzTDkjDAQBPgNSf1CTSZ3AtORKVfls';

    var nohp = '081239619532';

    var hpBidanTarget = hpBidan.filter((r) => {
      return r.puskesmas === puskesmas;
    });

    hpBidanTarget.map((r) => {
      var data = {
        phone: nohp,
        message:
          `🆘🆘🆘 MAMA BOI 🆘🆘🆘  \n\ \n\*IBU HAMIL KONDISI KRITIS* \n\ \n\Nama : *` +
          namaIbu +
          `*\n\Desa : *` +
          desa +
          `*\n\Golongan Darah : *` +
          golDarah +
          `*\n\Puskesmas : *` +
          puskesmas +
          `*\n\Nomor Hp Pendonor : *` +
          hpDonor +
          `*\n\Penyebab kritis : *` +
          alasanKritis +
          `*\n\Status : *` +
          status +
          `*\n\Foto ibu : *` +
          fotoIbu +
          `*\n\Sender : *` +
          nama +
          `* \n\ *Mohon tindakan segera*\n\ \n\ 🙏🙏🙏`,
      };

      var options_user = {
        method: 'POST',
        headers: {
          Authorization: Tokenwhatsap,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };

      var response = fetch(url, options_user)
        .then((response) => response.text())
        .then((data) => console.log(data));
    });
    handleClose();
    alert(
      'pesan terkirim atas nama pengirim ' +
        nama +
        '. Ibu hamil kritis : ' +
        namaIbu
    );
  };

  const [namaIbu, setNamaIbu] = useState('');
  const [fotoIbu, setFotoIbu] = useState('');
  const [status, setStatus] = useState('');
  const [alasanKritis, setAlasanKritis] = useState('');
  const [desa, setDesa] = useState('');
  const [golDarah, setGolDarah] = useState('');
  const [hpDonor, setHpDonor] = useState('');
  const [puskesmas, setPuskesmas] = useState('');
  const history = useHistory();

  const [ibuHamil, setIbuHamil] = useState([]);
  const load = () => {
    const loadData = new LoadDataIbu();
    loadData.load(setIbuHamil, access);
  };

  const load2 = () => {
    const loadDataProf = new LoadDataProfile();
    loadDataProf.load(setProfileKu, nama);
  };

  const cariData = async (e) => {
    setNamaIbu('');
    var dataIbus;
    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbus = response.data.data;
    } catch (error) {
      console.error(error);
    }

    if (dataIbus == null) {
      alert('Koneksi error, ulangi');

      return;
    }
    let temukanIbu = dataIbus.find((item) => {
      return item.namaIbu === e.target.value;
    });

    setNamaIbu(temukanIbu.namaIbu);
    setFotoIbu(temukanIbu.fotoIbu);
    setStatus(temukanIbu.status_Pj);
    setDesa(temukanIbu.desaIbu);
    setGolDarah(temukanIbu.golDarahIbu);
    setHpDonor(temukanIbu.hpPendonor);
    setPuskesmas(temukanIbu.puskesmas);
  };

  useEffect(() => {
    load();
    load2();
  }, [access]);

  const [checked, setChecked] = useState([]);
  const changeCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }

    setChecked(updatedList);
    setAlasanKritis(updatedList.join());
    console.log(updatedList.join());
  };

  //https://test.mamaboi.net/api/users

  const Logout = async () => {
    history.push('/');
    try {
      await axios.delete(API_MAMABOI + 'users');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Navbar collapseOnSelect expand='lg' bg='dark' variant='dark'>
        <Container>
          <Button variant='danger' className='danger' onClick={handleShow}>
            Panic Button <Badge bg='secondary'>9</Badge>
            <span className='visually-hidden'>unread messages</span>
          </Button>
          <Navbar.Brand href='#/layout'>Mama Bo'i 2.0</Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='me-auto'>
              <NavDropdown title='Data' id='collasible-nav-dropdown'>
                <NavDropdown.Item href='#layout/input'>
                  Input data dasar
                </NavDropdown.Item>
                <NavDropdown.Item href='#layout/kunjungan'>
                  Kunjungan Ibu Hamil
                </NavDropdown.Item>

                <NavDropdown.Item href='#layout/dataibu'>
                  Data Dasar Ibu hamil
                </NavDropdown.Item>
                <NavDropdown.Item href='#layout/datakunjunganibu'>
                  Data Kunjungan Ibu
                </NavDropdown.Item>
                <NavDropdown.Item href='#layout/laporan'>
                  Data Triple Eliminasi
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href='#layout/bersalin'>
                  Input data Ibu bersalin
                </NavDropdown.Item>
                <NavDropdown.Item href='#layout/kunjungan'>
                  Kunjungan Ibu bersalin
                </NavDropdown.Item>
                <NavDropdown.Item href='#layout/dataibubersalin'>
                  Data Ibu bersalin
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href=''>Input data bayi</NavDropdown.Item>
                <NavDropdown.Item href=''>Kunjungan bayi</NavDropdown.Item>
                <NavDropdown.Item href=''>Data bayi</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href='#layout/rujukanibu'>
                  Rujukan Ibu
                </NavDropdown.Item>

                <NavDropdown.Item href='#layout/rujukanbayi'>
                  Rujukan Bayi
                </NavDropdown.Item>

                <NavDropdown.Item href='#layout/datarujukanibu'>
                  Laporan Rujukan Ibu
                </NavDropdown.Item>
                <NavDropdown.Item href='#layout/datarujukanbayi'>
                  Laporan Rujukan Bayi
                </NavDropdown.Item>
              </NavDropdown>

              {access === 'Kabupaten' ? (
                <NavDropdown title='Pesan' id='collasible-nav-dropdown'>
                  <NavDropdown.Item href='#layout/pesan40hari'>
                    40 hari tidak berkunjung
                  </NavDropdown.Item>
                  <NavDropdown.Item href='#/layout/pesan2minggu'>
                    2 Minggu Melahirkan
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href='#layout/pesanedukasi'>
                    Edukasi/Informasi
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <div></div>
              )}
              <Nav.Link href='#layout/forum'>
                {' '}
                <span className='icon'>
                  <i className='bx bx-volume-full'></i>
                </span>{' '}
                Forum
                <Badge bg='danger' className='badgeKu'>
                  {2}
                </Badge>
              </Nav.Link>
              <Nav.Link href='#layout/analitycs'>
                {' '}
                <span className='icon'>
                  <i className='bx bxl-medium-old'></i>
                </span>{' '}
                Analitycs
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link href='#'>{access}</Nav.Link>

              <div className='imgPrTop'>
                <img className='profileKuTop' src={profileKu} alt='' />
              </div>
              <Nav.Link eventKey={2} href='#layout/user'>
                {nama}
              </Nav.Link>
              <Button variant='primary' onClick={Logout}>
                sign out
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Kondisi Darurat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class='mb-3'>
            <label for='inputState' className='form-label'>
              Nama Ibu hamil kondisi kritis
            </label>
            <select id='inputState' className='form-select' onChange={cariData}>
              <option value='' disabled selected>
                Pilih nama ibu hamil
              </option>
              {ibuHamil.map((ibu, index) => (
                <option>{ibu.namaIbu}</option>
              ))}
            </select>
            <label id='labelInput' for='inputState2' className='form-label'>
              Penyebab ibu dalam kondisi kritis
            </label>

            <div class='form-check'>
              <input
                class='form-check-input'
                type='checkbox'
                onChange={changeCheck}
                value='Ketuban pecah dini'
                name='elapan'
                id='flexCheckDefault'
              />
              <label class='form-check-label' for='flexCheckDefault'>
                Ketuban pecah dini
              </label>
            </div>
            <div class='form-check'>
              <input
                class='form-check-input'
                type='checkbox'
                onChange={changeCheck}
                value='Bagian terkecil janin keluar dari jalan lahir'
                name='elapan'
                id='flexCheckDefault'
              />
              <label class='form-check-label' for='flexCheckDefault'>
                Bagian terkecil janin keluar dari jalan lahir
              </label>
            </div>
            <div class='form-check'>
              <input
                class='form-check-input'
                type='checkbox'
                onChange={changeCheck}
                value='Kelainan letak'
                name='elapan'
                id='flexCheckDefault'
              />
              <label class='form-check-label' for='flexCheckDefault'>
                Kelainan letak
              </label>
            </div>
            <div class='form-check'>
              <input
                class='form-check-input'
                type='checkbox'
                onChange={changeCheck}
                value='Partus macet (kala dua lama)'
                name='elapan'
                id='flexCheckDefault'
              />
              <label class='form-check-label' for='flexCheckDefault'>
                Partus macet (kala dua lama)
              </label>
            </div>
            <div class='form-check'>
              <input
                class='form-check-input'
                type='checkbox'
                onChange={changeCheck}
                value='Intrauterin fetal death (IuFD)'
                name='elapan'
                id='flexCheckDefault'
              />
              <label class='form-check-label' for='flexCheckDefault'>
                Intrauterin fetal death (IuFD)
              </label>
            </div>
            <div class='form-check'>
              <input
                class='form-check-input'
                type='checkbox'
                onChange={changeCheck}
                value='Gawat Janin'
                name='elapan'
                id='flexCheckDefault'
              />
              <label class='form-check-label' for='flexCheckDefault'>
                Gawat Janin
              </label>
            </div>
            <div class='form-check'>
              <input
                class='form-check-input'
                type='checkbox'
                onChange={changeCheck}
                value='Eklamsia'
                name='elapan'
                id='flexCheckDefault'
              />
              <label class='form-check-label' for='flexCheckDefault'>
                Eklamsia
              </label>
            </div>
            <div class='form-check'>
              <input
                class='form-check-input'
                type='checkbox'
                onChange={changeCheck}
                value='Preeklamsia Berat'
                name='elapan'
                id='flexCheckDefault'
              />
              <label class='form-check-label' for='flexCheckDefault'>
                Preeklamsia Berat
              </label>
            </div>
            <div class='form-check'>
              <input
                class='form-check-input'
                type='checkbox'
                onChange={changeCheck}
                value='Hipertensi'
                name='elapan'
                id='flexCheckDefault'
              />
              <label class='form-check-label' for='flexCheckDefault'>
                Hipertensi
              </label>
            </div>
            <div class='form-check'>
              <input
                class='form-check-input'
                type='checkbox'
                onChange={changeCheck}
                value='Perdarahan'
                name='elapan'
                id='flexCheckDefault'
              />
              <label class='form-check-label' for='flexCheckDefault'>
                Perdarahan
              </label>
            </div>
          </div>

          {namaIbu !== '' ? (
            <Card style={{ width: '18rem' }}>
              <Card.Img variant='top' src={fotoIbu} alt='profile' />
              <Card.Body>
                <Card.Title>Ny. {namaIbu}</Card.Title>
                <Card.Text>status Puji Rochjati : {status}</Card.Text>
              </Card.Body>
            </Card>
          ) : (
            <div></div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Batal
          </Button>
          <Button variant='danger' className='danger' onClick={kirimPesan}>
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TopNav;
