import React from 'react';
import { useState, useEffect } from 'react';
import './tableKunjungan.css';

const TableKunjungan = (props) => {
  let kunjungan = props.data;
  return (
    <div className='table-wrapper'>
      <div class='recentOrders'>
        <div class='cardHeader'>
          <h2>Rekapan data Kunjungan</h2>
          <a href='#' class='btn'>
            View All
          </a>
        </div>
        <div className='table-wrapper'>
          <table>
            <thead>
              <tr>
                <th>Parameter</th>
                {kunjungan.map((r, index) => (
                  <th>kun {index + 1}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr key={'kek'}>
                <td>KEK</td>
                {kunjungan.map((r) => (
                  <td>
                    <span className={r.kek}>{r.kek}</span>
                  </td>
                ))}
              </tr>
              <tr key={'persentase'}>
                <td>Persentase</td>
                {kunjungan.map((r) => (
                  <td>
                    <span className={r.persentase}>{r.persentase}</span>
                  </td>
                ))}
              </tr>
              <tr key={'statusPasien'}>
                <td>Status Pasien</td>
                {kunjungan.map((r) => (
                  <td>
                    <span className={r.statusPasien}>{r.statusPasien}</span>
                  </td>
                ))}
              </tr>
              <tr key={'tabletTD'}>
                <td>Tablet Tambah Darah</td>
                {kunjungan.map((r) => (
                  <td>
                    <span className={r.tabletTD}>{r.tabletTD}</span>
                  </td>
                ))}
              </tr>
              <tr key={'hb'}>
                <td>HB</td>
                {kunjungan.map((r) => (
                  <td>
                    <span className={r.hb}>{r.hb}</span>
                  </td>
                ))}
              </tr>
              <tr key={'hepatitis'}>
                <td>Hepatitis</td>
                {kunjungan.map((r) => (
                  <td>
                    <span className={r.hepatitis}>{r.hepatitis}</span>
                  </td>
                ))}
              </tr>
              <tr key={'hiv'}>
                <td>HIV</td>
                {kunjungan.map((r) => (
                  <td>
                    <span className={r.hiv}>{r.hiv}</span>
                  </td>
                ))}
              </tr>
              <tr key={'malaria'}>
                <td>Malaria</td>
                {kunjungan.map((r) => (
                  <td>
                    <span className={r.malaria}>{r.malaria}</span>
                  </td>
                ))}
              </tr>
              <tr key={'tb'}>
                <td>TB</td>
                {kunjungan.map((r) => (
                  <td>
                    <span className={r.tb}>{r.tb}</span>
                  </td>
                ))}
              </tr>
              <tr key={'ims'}>
                <td>IMS</td>
                {kunjungan.map((r) => (
                  <td>
                    <span className={r.ims}>{r.ims}</span>
                  </td>
                ))}
              </tr>
              <tr key={'tanggalKun'}>
                <td>Tanggal Kunjungan</td>
                {kunjungan.map((r) => (
                  <td>
                    <span className={r.tanggalKun}>{r.tanggalKun}</span>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableKunjungan;
