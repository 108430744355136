import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './css/input.css';
import { Form, Card, Button, Modal } from 'react-bootstrap';
import LoadDataIbu from '../task/LoadDataIbu';
import { API_MAMABOI } from '../../utils/constant';

const Bersalin = ({ data }) => {
  const [cariIbu, setCariIbu] = useState('');
  const [nama, setNama] = useState('');
  const [desa, setDesa] = useState('');

  const [button, setButton] = useState(false);
  const [show2, setShow2] = useState(false);

  const [perkiraanLahir, setPerkiraanLahir] = useState('');
  const [status, setStatus] = useState('');
  const [fotoIbu, setFoto] = useState('https://fakeimg.pl/250x250/');

  const [tglBersalin, setTglBersalin] = useState('');
  const [anakKe, setAnakKe] = useState('');
  const [tempatSalin, setTempatSalin] = useState('');
  const [penolongBersalin, setPenolongBersalin] = useState('');
  const [caraBersalin, setCaraBersalin] = useState('');
  const [kondisiSetelahMelahirkan, setKondisiSetelahMelahirkan] = useState('');
  const [usiaMeninggal, setUsiaMeninggal] = useState('');
  const [alasanMeninggal, setAlasanMeninggal] = useState('');
  const [kondisiBayi, setKondisiBayi] = useState('');
  const [kelaminBayi, setKelaminBayi] = useState('');
  const [namaBayi, setNamaBayi] = useState('');

  const [ibuHamil, setIbuHamil] = useState([]);

  const load = () => {
    const loadData = new LoadDataIbu();
    loadData.load(setIbuHamil, data);
  };

  useEffect(() => {
    load();
  }, [data]);

  const [fotoPenolong, setFotoPenolong] = useState('');

  const [urlFotoPenolong, setUrlFotoPenolong] = useState(
    'https://fakeimg.pl/250x250/'
  );
  const [alasanBayiMeninggal, setAlasanBayiMeninggal] = useState('');
  const [bbl, setBbl] = useState('');
  const [bayiDapatLayanan, setBayiDapatLayanan] = useState('');
  const [idHamil, setIdHamil] = useState('');
  const [alasanBayiRujuk, setAlasanBayiRujuk] = useState('');

  const [idIbu, setIdIbu] = useState('');

  // Add/Remove checked item from list
  const [checked, setChecked] = useState([]);
  const changeCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }

    setChecked(updatedList);
    setBayiDapatLayanan(updatedList.join());
    // console.log(updatedList.join());
  };

  //handling chencked

  const history = useHistory();
  // function cariData() {
  //   const response = axios.get('http://localhost:5000/products');
  //   const dataIbu = response.data;
  //   console.log(dataIbu);
  // }

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFotoPenolong(URL.createObjectURL(uploaded));
    setFotoPenolong(e.target.files[0]);
  }

  const addDays = (date, days) => {
    console.log(date);
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    setPerkiraanLahir(result.toDateString());
  };

  const cariData = async (e) => {
    var dataIbus;
    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbus = response.data.data;
    } catch (error) {
      console.error(error);
    }

    if (dataIbus == null) {
      alert('data tidak ditemukan');
      setNama('');
      return;
    }
    let temukanIbu = dataIbus.find((item) => {
      var a = item.namaIbu.split(' ').join('');
      var b = e.target.value.split(' ').join('');
      return a === b;
    });

    if (temukanIbu != null) {
      setNama(temukanIbu.namaIbu);
      setNamaBayi('Bayi ' + temukanIbu.namaIbu);
      setDesa(temukanIbu.desaIbu);
      setFoto(temukanIbu.fotoIbu);
      setStatus(temukanIbu.statusIbu);
      setIdIbu(temukanIbu.id);
      setIdHamil(temukanIbu.id);

      // const date = new Date(temukanIbu.hpht);
      // addDays(date, 280);
    } else {
      alert('Data Ibu tidak ditemukan');
    }
  };

  const saveKunjungan = async (e) => {
    e.preventDefault();

    setButton(true);

    const formData = new FormData();
    const formData2 = new FormData();

    formData.append('tglBersalin', tglBersalin);
    formData.append('anakKe', anakKe);
    formData.append('tempatSalin', tempatSalin);
    formData.append('penolongBersalin', penolongBersalin);
    formData.append('caraBersalin', caraBersalin);
    formData.append('kondisiSetelahMelahirkan', kondisiSetelahMelahirkan);
    formData.append('usiaMeninggal', usiaMeninggal);
    formData.append('alasanMeninggal', alasanMeninggal);
    formData.append('kondisiBayi', kondisiBayi);
    formData.append('kelaminBayi', kelaminBayi);
    formData.append('namaBayi', namaBayi);
    formData.append('photo', fotoPenolong);

    formData.append('alasanBayiMeninggal', alasanBayiMeninggal);
    formData.append('bbl', bbl);

    formData.append('bayiDapatLayanan', bayiDapatLayanan);
    formData.append('idHamil', idHamil);
    formData.append('alasanBayiRujuk', alasanBayiRujuk);

    //https://test.mamaboi.net/api/bersalin
    //'http://localhost:4000/api/bersalin'

    const response = await axios.post(API_MAMABOI + 'bersalin', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    //https://test.mamaboi.net/api/kohort/${idIbu}

    if (response.data.success === 1) {
      formData2.append('statusIbu', 'Ibu bersalin');

      const response2 = await axios.patch(
        API_MAMABOI + `kohort/bersalin/${idIbu}`,
        formData2,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      alert('Data Ibu bersalin berhasil diinput');
      history.push('/layout/dataibu');
      history.push('/layout/bersalin');
      setButton(true);
    }
  };

  return (
    <div>
      <div className='detailsII'>
        <div className='dataIbu'>
          <div class='mb-3'>
            <label for='inputState' className='form-label'>
              Nama Ibu bersalin
            </label>
            <select id='inputState' className='form-select' onChange={cariData}>
              <option value='' disabled selected>
                Pilih nama ibu bersalin
              </option>
              {ibuHamil.map((ibu, index) => (
                <option>{ibu.namaIbu}</option>
              ))}
            </select>
          </div>
        </div>
        {/* <div className='dataIbu'>
          <div className='field'>
            <label className='label'>Desa</label>
            <div
            // className='select is-fullwidth'
            // value={kecamatan}
            // onChange={(e) => setKecamatan(e.target.value)}
            >
              <select>
                <option>Pilih nama Desa</option>
                <option>Rumah</option>
                <option>Posyandu</option>
                <option>Puskesmas</option>
                <option>Rumah Sakit</option>
                <option>Lainya</option>
              </select>
            </div>
          </div>
        </div> */}
      </div>
      {nama === '' ? (
        <div></div>
      ) : (
        <div>
          <div className='detailsI'>
            <div className='dataIbu'>
              <div className='field'>
                <Card>
                  <Card.Header>Featured</Card.Header>
                  <Card.Body>
                    <Card.Title>Special title treatment</Card.Title>
                    <Card.Text>
                      <table>
                        <tr>
                          <td>
                            <label className='label'>Nama Ibu</label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{nama}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className='label'>Alamat</label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{desa}</label>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <label className='label'>Status Ibu</label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{status}</label>
                          </td>
                        </tr>
                      </table>
                    </Card.Text>
                    <Button variant='primary' onClick={() => setShow2(true)}>
                      catat ibu bersalin
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>

            <Card style={{ width: '18rem' }}>
              <Card.Img variant='top' src={fotoIbu} alt='profile' />
              <Card.Body>
                <Card.Title>Ny. {nama}</Card.Title>
                <Card.Text>status : {status}</Card.Text>
                <Button variant='primary'>Lihat detail</Button>
              </Card.Body>
            </Card>
          </div>

          <div className='detailsII'>
            <div className='dataIbu'>
              <Modal
                show={show2}
                onHide={() => setShow2(false)}
                dialogClassName='modal-90w'
                aria-labelledby='example-custom-modal-styling-title'
              >
                <Modal.Header closeButton>
                  <Modal.Title id='example-custom-modal-styling-title'>
                    Catat Ibu bersalin
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form onSubmit={saveKunjungan}>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Tanggal Persalinan
                      </label>
                      <input
                        className='form-control'
                        type='date'
                        value={tglBersalin}
                        onChange={(e) => setTglBersalin(e.target.value)}
                        required
                      ></input>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Tempat persalinan
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={tempatSalin}
                        onChange={(e) => setTempatSalin(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Non fasilitas kesehatan</option>
                        <option>Rumah Sakit</option>
                        <option>Puskesmas</option>
                        <option>Rumah Sakit Swasta</option>
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Penolong persalinan
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={penolongBersalin}
                        onChange={(e) => setPenolongBersalin(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Bidan</option>
                        <option>Dokter</option>
                        <option>Dukun</option>
                        <option>Keluarga</option>
                        <option>Lainya</option>
                      </select>
                    </div>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Kondisi ibu setelah melahirkan
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={kondisiSetelahMelahirkan}
                        onChange={(e) =>
                          setKondisiSetelahMelahirkan(e.target.value)
                        }
                      >
                        <option>Pilih opsi</option>
                        <option>Sehat</option>
                        <option>Sakit</option>
                        <option>Meninggal</option>
                        <option>Lainya</option>
                      </select>
                    </div>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Nama bayi
                      </label>
                      <input
                        className='form-control'
                        type='text'
                        value={namaBayi}
                        onChange={(e) => setNamaBayi(e.target.value)}
                        required
                      ></input>
                    </div>

                    {kondisiSetelahMelahirkan === 'Meninggal' ? (
                      <div></div>
                    ) : (
                      <div className='mb-3'>
                        <label
                          for='exampleFormControlInput1'
                          className='form-label'
                        >
                          Foto tenaga penolong bersama ibu
                        </label>
                        <div>
                          <img
                            src={urlFotoPenolong}
                            className=''
                            alt=''
                            width={'300px'}
                          />
                        </div>
                        <div className='mb-3'>
                          <label
                            for='exampleFormControlInput1'
                            className='form-label'
                          >
                            <input
                              className='form-control'
                              type='file'
                              name='resume'
                              onChange={onChange1}
                              accept={'image/*'}
                            />
                            <span className='file-cta'>
                              <span class='file-icon'>
                                <i class='fas fa-upload'></i>
                              </span>
                            </span>
                            {/* <span class='file-name'>{fotoBukti.name}</span> */}
                          </label>
                        </div>
                      </div>
                    )}
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Cara persalinan
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={caraBersalin}
                        onChange={(e) => setCaraBersalin(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Normal</option>
                        <option>Sesar</option>
                        <option>Vakum</option>
                        <option>Lainya</option>
                      </select>
                    </div>

                    {kondisiSetelahMelahirkan === 'Meninggal' ? (
                      <div className='mb-3'>
                        <label
                          for='exampleFormControlInput1'
                          className='form-label'
                        >
                          Penyebab kematian ibu
                        </label>
                        <select
                          className='form-select'
                          aria-label='Default select example'
                          value={alasanMeninggal}
                          onChange={(e) => setAlasanMeninggal(e.target.value)}
                        >
                          <option>Pilih opsi</option>
                          <option>KPD</option>
                          <option>Perdarahan post partum</option>
                          <option>Partus lama</option>
                          <option>Eklamsia</option>
                          <option>Infeksi</option>
                          <option>Eklamsia</option>
                          <option>Pre eklamsia</option>
                          <option>Lainya</option>
                        </select>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Kondisi bayi setelah lahir
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={kondisiBayi}
                        onChange={(e) => setKondisiBayi(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Lahir hidup</option>
                        <option>Lahir mati</option>
                      </select>
                    </div>
                    {kondisiBayi === 'Lahir mati' ? (
                      <div className='mb-3'>
                        <label
                          for='exampleFormControlInput1'
                          className='form-label'
                        >
                          Alasan bayi meninggal
                        </label>
                        <select
                          className='form-select'
                          aria-label='Default select example'
                          value={alasanBayiMeninggal}
                          onChange={(e) =>
                            setAlasanBayiMeninggal(e.target.value)
                          }
                        >
                          <option>Pilih opsi</option>
                          <option>IUFD</option>
                          <option>Asfiksia</option>
                          <option>BBLR</option>
                          <option>Sepsis</option>
                          <option>Atresia ani</option>
                          <option>Ikterus</option>
                          <option>Hipotermi</option>
                          <option>Trauma Lahir</option>
                          <option>Infeksi</option>
                          <option>Lainnya</option>
                        </select>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Jenis kelamin bayi
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={kelaminBayi}
                        onChange={(e) => setKelaminBayi(e.target.value)}
                      >
                        <option>Pilih opsi</option>
                        <option>Laki - laki</option>
                        <option>Perempuan</option>
                      </select>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Berat badan bayi
                      </label>
                      <input
                        className='form-control'
                        type='number'
                        placeholder='input Nomor HP Ibu'
                        value={bbl}
                        onChange={(e) => setBbl(e.target.value)}
                        required
                      ></input>
                    </div>
                    {kondisiBayi === 'Lahir mati' ? (
                      <div></div>
                    ) : (
                      <div>
                        <div className='mb-3'>
                          <label
                            for='exampleFormControlInput1'
                            className='form-label'
                          >
                            Bayi mendapatkan pelayanan
                          </label>

                          <div class='form-check'>
                            <input
                              class='form-check-input'
                              type='checkbox'
                              onChange={changeCheck}
                              value='Saleb mata'
                              name='saleb'
                              id='flexCheckDefault'
                            />
                            <label
                              class='form-check-label'
                              for='flexCheckDefault'
                            >
                              Salep mata
                            </label>
                          </div>

                          <div class='form-check'>
                            <input
                              class='form-check-input'
                              type='checkbox'
                              onChange={changeCheck}
                              value='HB 0'
                              name='hb'
                              id='flexCheckDefault'
                            />
                            <label
                              class='form-check-label'
                              for='flexCheckDefault'
                            >
                              Hb 0
                            </label>
                          </div>

                          <div class='form-check'>
                            <input
                              class='form-check-input'
                              type='checkbox'
                              onChange={changeCheck}
                              value='Neo Vit K'
                              name='neo'
                              id='flexCheckDefault'
                            />
                            <label
                              class='form-check-label'
                              for='flexCheckDefault'
                            >
                              Neo vit k
                            </label>
                          </div>
                          <div class='form-check'>
                            <input
                              class='form-check-input'
                              type='checkbox'
                              onChange={changeCheck}
                              value='HBIG'
                              name='hbig'
                              id='flexCheckDefault'
                            />
                            <label
                              class='form-check-label'
                              for='flexCheckDefault'
                            >
                              HBIG
                            </label>
                          </div>
                        </div>

                        <div className='mb-3'>
                          <label
                            for='exampleFormControlInput1'
                            className='form-label'
                          >
                            Alasan bayi dirujuk
                          </label>
                          <select
                            className='form-select'
                            aria-label='Default select example'
                            value={alasanBayiRujuk}
                            onChange={(e) => setAlasanBayiRujuk(e.target.value)}
                          >
                            <option>Pilih opsi</option>
                            <option>Prematur</option>
                            <option>BBLR</option>
                            <option>Kelainan kongenital</option>
                            <option>Lainya</option>
                          </select>
                        </div>
                      </div>
                    )}
                    <div class='d-grid gap-2'>
                      <button
                        disabled={button}
                        className='btn btn-primary'
                        type='submit'
                      >
                        Simpan
                      </button>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Bersalin;
