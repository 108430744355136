import axios from 'axios';
import daftarPuskesmas from '../../assets/JsonData/daftarPuskesmas.json';
import { API_MAMABOI } from '../../utils/constant';

class LoadDataCard {
  // mendapatkan data
  load = (setDataCard, setTable, access) => {
    this.setDataCard = setDataCard;
    this.setDataTable = setTable;
    this.access = access;

    this.#proccessData();
  };

  #proccessData = async () => {
    var dataIbuku = [];

    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbuku = response.data.data;
    } catch (error) {
      console.error(error);
    }

    var listPuskesmas = daftarPuskesmas;

    if (this.access != 'Kabupaten') {
      listPuskesmas = [];
      listPuskesmas = [
        {
          id: 1,
          puskesmas: this.access,
          kecamatan: '',
          createdAt: '2021-09-23',
          updatedAt: '2021-09-23',
        },
      ];
    }
    var com1 = '';
    var com2 = '';
    var com3 = '';
    var com4 = '';
    var totIbuhamil = 0;

    var listStatusIbu = listPuskesmas.map((r) => {
      com1 = r['puskesmas'];

      var ibuResti = dataIbuku.filter((ibu) => {
        var tglCreate = ibu.createdAt;
        var arr = tglCreate.split('-');
        var thn = parseInt(arr[0]);

        com2 = ibu['puskesmas'];
        com3 = ibu['statusIbu'];
        com4 = 'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)';

        return (
          com2.toUpperCase() === com1.toUpperCase() &&
          com3.toUpperCase() === com4.toUpperCase()
        );
      });

      var ibuHamil = dataIbuku.filter((ibu) => {
        com2 = ibu['puskesmas'];
        com3 = ibu['statusIbu'];
        com4 = 'Ibu hamil';

        var tglCreate = ibu.createdAt;
        var arr = tglCreate.split('-');
        var thn = parseInt(arr[0]);

        return (
          com2.toUpperCase() === com1.toUpperCase() &&
          com3.toUpperCase() === com4.toUpperCase()
        );
      });
      totIbuhamil = totIbuhamil + ibuHamil.length;

      var ibuBersalin = dataIbuku.filter((ibu) => {
        var tglCreate = ibu.createdAt;
        var arr = tglCreate.split('-');
        var thn = parseInt(arr[0]);
        com2 = ibu['puskesmas'];
        com3 = ibu['statusIbu'];
        com4 = 'Ibu bersalin';

        return (
          com2.toUpperCase() === com1.toUpperCase() &&
          com3.toUpperCase() === com4.toUpperCase()
        );
      });

      return [
        r['puskesmas'],
        ibuResti.length,
        ibuHamil.length,
        ibuBersalin.length,
      ];
    });

    //menetapkan warna  pada desa yang memiliki ibu hamil, menggabungkan dua data tabel desa dan kohort ibu hamil
    var countIbuResti = listStatusIbu.reduce((currentTotal, item) => {
      return item[1] + currentTotal;
    }, 0);

    var countIbuHamil = listStatusIbu.reduce((currentTotal, item) => {
      return item[2] + currentTotal;
    }, 0);
    var countIbuBersalin = listStatusIbu.reduce((currentTotal, item) => {
      return item[3] + currentTotal;
    }, 0);

    var listStatus = [
      {
        icon: 'bx bxs-user-pin',
        count: countIbuResti,
        title: 'Tolak pelayanan',
      },
      {
        icon: 'bx bxs-user-rectangle',
        count: countIbuHamil,
        title: 'Hamil',
      },
      {
        icon: 'bx bxs-user-minus',
        count: countIbuBersalin,
        title: 'Bersalin',
      },
      {
        icon: 'bx bx-female',
        count: countIbuBersalin + countIbuHamil + countIbuResti,
        title: 'Total',
      },
    ];

    this.setDataCard(listStatus);
    this.setDataTable(listStatusIbu);
  };
}

export default LoadDataCard;
