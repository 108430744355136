import React, { useEffect, useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import './chart.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import LoadGrafik from '../task/LoadGrafik';

const ChartHamilBulan = ({ access }) => {
  const dataBersalin = [126, 199, 18, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const [dataGrafik, setDataGrafik] = useState([]);
  const [year, setYear] = useState('');

  const load = () => {
    const loadDataGrafik = new LoadGrafik();
    loadDataGrafik.load(setDataGrafik, setYear, access);
  };

  useEffect(load, [access]);

  return (
    <div className='box'>
      <Line
        plugins={[ChartDataLabels]}
        data={{
          labels: [
            'Januari',
            'Februari',
            'Maret',
            'April',
            'Mei',
            'Juni',
            'July',
            'Agustus',
            'September',
            'Oktober',
            'November',
            'Desember',
          ],
          datasets: [
            {
              label: '# Ibu Hamil per Bulan ' + year,
              data: dataGrafik.hamil,
              backgroundColor: ['rgba(54, 162, 235, 0.2)'],
              borderColor: ['rgba(54, 162, 235, 1)'],
              borderWidth: 1,
            },
            {
              label: '# Ibu Bersalin per Bulan ' + year,
              data: dataGrafik.bersalin,
              backgroundColor: ['rgba(255, 99, 132, 0.2)'],
              borderColor: ['rgba(255, 99, 132, 1)'],
              borderWidth: 1,
            },
          ],
        }}
        options={{
          options: {
            responsive: false,
            plugins: {
              datalabels: {
                anchor: 'start',
                align: 'start',
                labels: {
                  value: {
                    color: 'blue',
                  },
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default ChartHamilBulan;
