import axios from 'axios';
import daftarPuskesmas from '../../assets/JsonData/daftarPuskesmas.json';
import dataPuskesDesa from '../../assets/JsonData/Puskes_Desa.json';
import { API_MAMABOI } from '../../utils/constant';

class LoadDataCard2 {
  // mendapatkan data
  load = (setData, access) => {
    this.setData = setData;
    this.access = access;
    this.#proccessData();
  };

  #proccessData = async () => {
    var dataIbuku = [];
    var dataIbuHamilPus = [];
    var dataKunjungan = [];

    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbuku = response.data.data;
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await axios.get(API_MAMABOI + `kunjungan`);
      dataKunjungan = response.data.data;
    } catch (error) {
      console.error(error);
    }

    var krr = 0;
    var krt = 0;
    var krst = 0;

    if (this.access === 'Kabupaten') {
      //mencari jumlah ibu hamil perdesa

      let com1 = 'Ibu hamil';
      let com2 = 'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)';

      var dataIbusaring = dataIbuku.filter((ibu) => {
        let com3 = ibu.statusIbu;
        return (
          com3.toUpperCase() === com1.toUpperCase() ||
          com3.toUpperCase() === com2.toUpperCase()
        );
      });

      var dataIbuHamil = dataIbusaring.map((r) => {
        if (r.status_Pj == '') {
          var dataFilter = dataKunjungan.filter((row) => {
            return r.id == row.idIbu;
          });

          if (dataFilter.length > 0) {
            dataFilter.map((rr) => {
              if (rr.statusPasien !== '') {
                r.status_Pj = rr.statusPasien;
              }
            });
          }
        }
        return r;
      });

      var list = daftarPuskesmas.map((r) => {
        krr = 0;
        krt = 0;
        krst = 0;

        var ibuPus = dataIbuHamil.filter((ibu) => {
          return ibu['puskesmas'].toUpperCase() == r['puskesmas'].toUpperCase();
        });

        ibuPus.map((r) => {
          if (r.status_Pj.toUpperCase() === 'KRR') {
            krr = krr + 1;
          }
          if (r.status_Pj.toUpperCase() === 'KRT') {
            krt = krt + 1;
          }
          if (r.status_Pj.toUpperCase() === 'KRST') {
            krst = krst + 1;
          }
        });

        return [krr, krt, krst];
      });
      dataIbuHamilPus = list;
    } else {
      const puskesDesa = dataPuskesDesa.filter((pus) => {
        return pus.Puskesmas === this.access;
      });

      let com1 = 'Ibu hamil';
      let com2 = 'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)';

      var k2Filter = dataIbuku.filter((k2) => {
        let com3 = k2.statusIbu;
        return (
          k2.puskesmas === this.access &&
          (com3.toUpperCase() === com1.toUpperCase() ||
            com3.toUpperCase() === com2.toUpperCase())
        );
      });

      var dataIbuHamil = k2Filter.map((r) => {
        if (r.status_Pj == '') {
          var dataFilter = dataKunjungan.filter((row) => {
            return r.id == row.idIbu;
          });

          if (dataFilter.length > 0) {
            dataFilter.map((rr) => {
              if (rr.statusPasien !== '') {
                r.status_Pj = rr.statusPasien;
              }
            });
          }
        }
        return r;
      });

      k2Filter = dataIbuHamil;

      const listDesa = puskesDesa.map((r) => {
        const totDesa = k2Filter.filter((f) => {
          return f.desaIbu.toUpperCase() === r.Desa.toUpperCase();
        });

        const krrDesa = k2Filter.filter((f) => {
          return (
            f.desaIbu.toUpperCase() === r.Desa.toUpperCase() &&
            f.status_Pj === 'KRR'
          );
        });

        const krtDesa = k2Filter.filter((f) => {
          return (
            f.desaIbu.toUpperCase() === r.Desa.toUpperCase() &&
            f.status_Pj === 'KRT'
          );
        });
        const krstDesa = k2Filter.filter((f) => {
          return (
            f.desaIbu.toUpperCase() === r.Desa.toUpperCase() &&
            f.status_Pj === 'KRST'
          );
        });

        const jmlKrr = krrDesa.length;
        const jmlKrt = krtDesa.length;
        const jmlKrst = krstDesa.length;

        return [jmlKrr, jmlKrt, jmlKrst];
      });
      dataIbuHamilPus = listDesa;
    }

    //menetapkan warna  pada desa yang memiliki ibu hamil, menggabungkan dua data tabel desa dan kohort ibu hamil
    var countKrr = dataIbuHamilPus.reduce((currentTotal, item) => {
      return item[0] + currentTotal;
    }, 0);

    var countKrt = dataIbuHamilPus.reduce((currentTotal, item) => {
      return item[1] + currentTotal;
    }, 0);
    var countKrst = dataIbuHamilPus.reduce((currentTotal, item) => {
      return item[2] + currentTotal;
    }, 0);

    var listStatus = [
      {
        icon: 'bx bxs-user-pin',
        count: countKrr,
        title: 'KRR',
      },
      {
        icon: 'bx bxs-user-rectangle',
        count: countKrt,
        title: 'KRT',
      },
      {
        icon: 'bx bxs-user-minus',
        count: countKrst,
        title: 'KRST',
      },
    ];

    this.setData(listStatus);
  };
}

export default LoadDataCard2;
