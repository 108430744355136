import LoadDataTidakBerkunjung from '../task/LoadDataTidakBerkunjung';
import { useState, useEffect } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Table, Button, Badge } from 'react-bootstrap';
import '../table/table.css';

const TableTidakBerkunjung = ({ access }) => {
  const [dataTidakBerkunjung, setdataTidakBerkunjung] = useState([]);

  const load = () => {
    const loadData = new LoadDataTidakBerkunjung();
    loadData.load(setdataTidakBerkunjung, access);
  };

  useEffect(load, [access]);

  return (
    <div>
      <div className='recentCustomers'>
        <div className='cardHeader'>
          <h2 color='Blue' margin='2rem'>
            Tidak Berkunjung dalam 40 Hari Terakhir{' '}
            <Badge bg='danger'>{dataTidakBerkunjung.length}</Badge>
          </h2>

          <ReactHTMLTableToExcel
            className='btn'
            table='dataIbu'
            filename='40 hari tidak berkunjung'
            sheet='tidak berkunjung'
            buttonText='Export to Exl'
          />
        </div>
        <div className='table-wrapper'>
          <Table id='dataIbu' striped bordered hover size='sm'>
            <thead color='blue'>
              <tr>
                <th>Profil</th>
                <th>Nama</th>
                <th>Puskesmas</th>
                <th>Kunjung Terakhir</th>
                <th>Desa</th>
                <th>No Hp</th>
                <th>Status</th>
              </tr>
            </thead>
            {dataTidakBerkunjung.map((r, index) => (
              <tr key={index}>
                <td width='60px'>
                  <div className='imgBx'>
                    <img src={r.foto} alt='' />
                  </div>
                </td>
                <td>
                  <h4>
                    {r.namaIbu} <br />
                  </h4>
                </td>
                <td>
                  <span>{r.puskesmas}</span>
                </td>
                <td>
                  <span>{r.jarakKunjung + ' hari'}</span>
                </td>
                <td>
                  <span>{r.desa}</span>
                </td>
                <td>
                  <span>{r.hp}</span>
                </td>
                <td className={r.status}>
                  <span>{r.status}</span>
                </td>
              </tr>
            ))}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default TableTidakBerkunjung;
