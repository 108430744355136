import axios from 'axios';
import dataDesa from '../../assets/JsonData/daftarDesa.json';
import desaPuskes from '../../assets/JsonData/Puskes_Desa.json';
import { API_MAMABOI } from '../../utils/constant';

class LoadDataLap {
  // mendapatkan data
  load = (
    setDataLaporan,
    setDataDesa,
    access,
    setDataLaporan1,
    setDataDesa1
  ) => {
    this.setDataLaporan = setDataLaporan;
    this.setDataDesa = setDataDesa;
    this.setDataDesa1 = setDataDesa1;
    this.setDataLaporan1 = setDataLaporan1;
    this.access = access;
    this.#proccessData();
  };

  #proccessData = async () => {
    var dataIbuku = [];
    var datadesaku = desaPuskes;
    var dataKunjungan = [];

    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbuku = response.data.data;
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await axios.get(API_MAMABOI + 'kunjungan');
      dataKunjungan = response.data.data;
    } catch (error) {
      console.error(error);
    }

    if (this.access !== 'Kabupaten') {
      datadesaku = desaPuskes.filter((r) => {
        return r.Puskesmas.toUpperCase() === this.access.toUpperCase();
      });
    }
    var com1 = 'Ibu hamil';
    var com2 = 'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)';

    var result = datadesaku.map((r) => {
      var result2 = dataIbuku.filter((ibu) => {
        return (
          ibu['desaIbu'].toUpperCase() == r.Desa.toUpperCase() &&
          (ibu['statusIbu'].toUpperCase() == com1.toUpperCase() ||
            ibu['statusIbu'] == com2.toUpperCase())
        );
      });

      var krst = 0;

      for (var i = 0; i < result2.length; i++) {
        if (result2[i]['status_Pj'] == 'KRST') {
          krst = krst + 1;
        }
      }

      var result3 = result2.map((row) => {
        var result4 = dataKunjungan.filter((fil) => {
          return row['id'] == fil['idIbu'];
        });

        var hivI = 1;
        var malariaI = 1;
        var imsI = 1;
        if (result4.length != 0) {
          for (var i = 0; i < result4.length; i++) {
            if (result4[i]['hiv'] != null) {
              hivI = 0;
            }
          }
        }

        if (result4.length != 0) {
          for (var i = 0; i < result4.length; i++) {
            if (result4[i]['malaria'] != null) {
              malariaI = 0;
            }
          }
        }

        if (result4.length != 0) {
          for (var i = 0; i < result4.length; i++) {
            if (result4[i]['ims'] != null) {
              imsI = 0;
            }
          }
        }

        return [hivI, imsI, malariaI];
      });

      var belumIms = 0;
      var belumHiv = 0;
      var belumMalaria = 0;

      for (var i = 0; i < result3.length; i++) {
        belumHiv = belumHiv + result3[i][0];
        belumIms = belumIms + result3[i][1];
        belumMalaria = belumMalaria + result3[i][2];
      }

      return [
        r['Kecamatan'],
        r['Desa'],
        result2.length,
        krst,
        belumHiv,
        belumIms,
        belumMalaria,
        r['Puskesmas'],
      ];
    });

    this.setDataLaporan(result);
    this.setDataLaporan1(result);
    this.setDataDesa(datadesaku);
    this.setDataDesa1(datadesaku);
  };
}

export default LoadDataLap;
