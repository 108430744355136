import React, { useState, useEffect } from 'react';
import './css/kunjungan.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import TableKunjungan from '../tableKunjungan/TableKunjungan';
import ChartKunjungan from '../chartKunjungan/ChartKunjungan';
import ChartKunjunganBerat from '../chartKunjungan/ChartKunjunganBerat';
import ChartKunjunganTD from '../chartKunjungan/ChartKunjungaTD';
import { Card, Button, Modal } from 'react-bootstrap';
import Progres from '../progressBar/Progres';
import LoadDataIbu from '../task/LoadDataIbu';
import daftarPuskes from '../../assets/JsonData/daftarPuskesmas.json';
import { API_MAMABOI } from '../../utils/constant';

const RujukanIbu = ({ data }) => {
  const [cariIbu, setCariIbu] = useState('');
  const [nama, setNama] = useState('');
  const [desa, setDesa] = useState('');
  const [hpht, setHpht] = useState('');
  const [statusPj, setStatusPj] = useState('');
  const [jumlahKunjungan, setJumlahKunjungan] = useState(0);
  const [kunjunganIbu, setKunjunganIbu] = useState([]);
  const [tanggalKun, setTanggalKun] = useState('');

  const [ibuHamil, setIbuHamil] = useState([]);
  const load = () => {
    const loadData = new LoadDataIbu();
    loadData.load(setIbuHamil, data);
  };

  useEffect(() => {
    load();
  }, [data]);

  const [perkiraanLahir, setPerkiraanLahir] = useState('');
  const [status, setStatus] = useState('');
  const [fotoIbu, setFoto] = useState('https://fakeimg.pl/250x250/');

  const [bumilResiko, setBumilResiko] = useState('');

  //RUJUKAN IBU
  const [tanggalRujuk, setTanggalRujuk] = useState('');
  const [waktuRujuk, setWaktuRujuk] = useState('');
  const [alasanRujuk, setAlasanRujuk] = useState('');
  const [tujuanRujuk, setTujuanRujuk] = useState('');
  const [dokPendukung, setDokPendukung] = useState('');
  const [tindakan, setTindakan] = useState('');
  const [perkiraanTiba, setPerkiraanTiba] = useState('');
  const [idHamil, setIdHamil] = useState('');
  const [kondisiIbu, setKondisiIbu] = useState('');

  //RUJUKAN IBU

  const [uploadPercentage, setUploadPercentage] = useState(0);

  //MODAL
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  //MODAL

  const [urlFotoPoster, setUrlFotoPoster] = useState(
    'https://fakeimg.pl/250x250/'
  );
  const [fotoPoster, setFotoPoster] = useState('');
  const [dukunganKeluarga, setDukunganKeluarga] = useState('');

  const [urlUploadDukungan, setUrlUploadDukungan] = useState(
    'https://fakeimg.pl/250x250/'
  );
  const [uploadDukungan, setUploadDukungan] = useState('');

  const [idIbu, setIdIbu] = useState('');

  // Add/Remove checked item from list
  const [checked, setChecked] = useState([]);
  const changeCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }

    setChecked(updatedList);
    setAlasanRujuk(updatedList.join());
    //console.log(updatedList.join());
  };

  const [checkedDok, setCheckedDok] = useState([]);
  const changeCheck2 = (event) => {
    var updatedList = [...checkedDok];
    if (event.target.checked) {
      updatedList = [...checkedDok, event.target.value];
    } else {
      updatedList.splice(checkedDok.indexOf(event.target.value), 1);
    }

    setCheckedDok(updatedList);
    setDokPendukung(updatedList.join());
  };
  //handling checked

  const history = useHistory();
  // function cariData() {
  //   const response = axios.get('http://localhost:5000/products');
  //   const dataIbu = response.data;
  //   console.log(dataIbu);
  // }

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFotoPoster(URL.createObjectURL(uploaded));
    setFotoPoster(e.target.files[0]);
  }
  function onChange2(e) {
    let uploaded = e.target.files[0];
    setUrlUploadDukungan(URL.createObjectURL(uploaded));
    setUploadDukungan(e.target.files[0]);
  }

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    setPerkiraanLahir(result.toDateString());
  };

  const cariDataKunjungan = async (idIbu) => {
    var dataKunjungan;
    try {
      const response = await axios.get(API_MAMABOI + `kunjungan`);

      dataKunjungan = response.data.data;
    } catch (error) {
      console.error(error);
    }

    const kunjungan = dataKunjungan.filter(function (item) {
      return item.idIbu === idIbu;
    });

    setJumlahKunjungan(kunjungan.length);
    setKunjunganIbu(kunjungan);
  };

  const cariData = async (e) => {
    var dataIbus;
    try {
      const response = await axios.get(API_MAMABOI + 'kohort');

      dataIbus = response.data.data;
    } catch (error) {
      console.error(error);
    }

    if (dataIbus == null) {
      alert('data tidak ditemukan');
      setNama('');
      return;
    }

    let temukanIbu = dataIbus.find((item) => {
      var a = item.namaIbu.split(' ').join('');
      var b = e.target.value.split(' ').join('');
      return a === b;
    });

    if (temukanIbu != null) {
      setNama(temukanIbu.namaIbu);
      setDesa(temukanIbu.desaIbu);
      setHpht(temukanIbu.hpht);
      setFoto(temukanIbu.fotoIbu);
      setStatus(temukanIbu.statusIbu);
      setStatusPj(temukanIbu.statusPj);
      setIdHamil(temukanIbu.id);
      setIdIbu(temukanIbu.id);
      cariDataKunjungan(temukanIbu.id);
      const date = new Date(temukanIbu.hpht);
      addDays(date, 280);
    } else {
      alert('Data Ibu tidak ditemukan. Periksa kembali Nama atau NIK');
    }
  };

  const saveKunjungan = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('tanggalRujuk', tanggalRujuk);
    formData.append('waktuRujuk', waktuRujuk);
    formData.append('alasanRujuk', alasanRujuk);
    formData.append('tujuanRujuk', tujuanRujuk);
    formData.append('dokPendukung', dokPendukung);
    formData.append('tindakan', tindakan);
    formData.append('perkiraanTiba', perkiraanTiba);
    formData.append('idHamil', idHamil);
    formData.append('kondisiIbu', kondisiIbu);

    formData.append('alasanRujuk', alasanRujuk);
    formData.append('dokPendukung', dokPendukung);

    //https://test.mamaboi.net/api/kunjungan
    //http://localhost:4000/api/rujukibu

    const response = await axios.post(
      API_MAMABOI + 'rujukibu',
      {
        tanggalRujuk: tanggalRujuk,
        waktuRujuk: waktuRujuk,
        alasanRujuk: alasanRujuk,
        tujuanRujuk: tujuanRujuk,
        dokPendukung: dokPendukung,
        tindakan: tindakan,
        perkiraanTiba: perkiraanTiba,
        idHamil: idHamil,
        kondisiIbu: kondisiIbu,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        onUploadProgress: (ProgressEvent) => {
          setUploadPercentage(
            parseInt(
              Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
            )
          );

          // console.log(uploadPercentage);
          // //clear percentage
          // setTimeout(() => setUploadPercentage(0), 1000);
        },
      }
    );

    if (response.data.success === 1) {
      setShow2(false);
      alert('Data Rujukan Ibu berhasil diinput');
      history.push('/layout/dataibu');
      history.push('/layout/kunjungan');
    }
  };

  return (
    <div>
      <div className='detailsII'>
        <div className='dataIbu'>
          <div class='mb-3'>
            <label for='inputState' className='form-label'>
              Nama Ibu hamil yang dirujuk
            </label>
            <select id='inputState' className='form-select' onChange={cariData}>
              <option value='' disabled selected>
                Pilih nama ibu yang dirujuk
              </option>
              {ibuHamil.map((ibu, index) => (
                <option>{ibu.namaIbu}</option>
              ))}
            </select>
          </div>
        </div>
        {/* <div className='dataIbu'>
          <div className='field'>
            <label className='label'>Desa</label>
            <div
            // className='select is-fullwidth'
            // value={kecamatan}
            // onChange={(e) => setKecamatan(e.target.value)}
            >
              <select>
                <option>Pilih nama Desa</option>
                <option>Rumah</option>
                <option>Posyandu</option>
                <option>Puskesmas</option>
                <option>Rumah Sakit</option>
                <option>Lainya</option>
              </select>
            </div>
          </div>
        </div> */}
      </div>
      {nama === '' ? (
        <div></div>
      ) : (
        <div>
          <div className='detailsI'>
            <div className='dataIbu'>
              <div className='field'>
                <Card>
                  <Card.Header>Ibu Hamil</Card.Header>
                  <Card.Body>
                    <Card.Title>Data dasar</Card.Title>
                    <Card.Text>
                      <table>
                        <tr>
                          <td>
                            <label className='label'>Nama Ibu</label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{nama}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className='label'>Alamat</label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{desa}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className='label'>
                              Hari Pertama Haid Terakhir
                            </label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{hpht}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className='label'>Status Ibu</label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{status}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className='label'>
                              Perkiraan Melahirkan
                            </label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{perkiraanLahir}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className='label'>
                              Status Puji Rochjati
                            </label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{statusPj}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className='label'>
                              Jumlah kunjungan Ibu
                            </label>
                          </td>
                          <td>
                            <label className='label'>:</label>
                          </td>
                          <td>
                            <label className='label'>{jumlahKunjungan}</label>
                          </td>
                        </tr>
                      </table>
                    </Card.Text>
                    <Button variant='primary' onClick={() => setShow2(true)}>
                      Rujuk Ibu
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className='dataIbu'>
              <Card style={{ width: '18rem' }}>
                <Card.Img variant='top' src={fotoIbu} alt='profile' />
                <Card.Body>
                  <Card.Title>Ny. {nama}</Card.Title>
                  <Card.Text>status : {status}</Card.Text>
                  <Button variant='primary' onClick={() => setShow(true)}>
                    Lihat data kunjungan
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </div>

          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName='modal-90w'
            aria-labelledby='example-custom-modal-styling-title'
          >
            <Modal.Header closeButton>
              <Modal.Title id='example-custom-modal-styling-title'>
                Informasi data kunjungan
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='detailsII'>
                <div className='dataIbu'>
                  <TableKunjungan data={kunjunganIbu} />
                </div>
              </div>
              <div className='detailsII'>
                <div className='dataIbu'>
                  <ChartKunjungan data={kunjunganIbu} />
                </div>
                <div className='dataIbu'>
                  <ChartKunjunganBerat data={kunjunganIbu} />
                </div>
              </div>
              <div className='detailsII'>
                <ChartKunjunganTD data={kunjunganIbu} />
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={show2}
            onHide={() => setShow2(false)}
            dialogClassName='modal-90w'
            aria-labelledby='example-custom-modal-styling-title'
          >
            <Modal.Header closeButton>
              <Modal.Title id='example-custom-modal-styling-title'>
                Rujuk Ibu
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='detailsII'>
                <div className='dataIbu'>
                  <h2> Informasi Rujukan</h2>
                  <form onSubmit={saveKunjungan}>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Tanggal rujukan
                      </label>
                      <input
                        className='form-control'
                        type='date'
                        value={tanggalRujuk}
                        onChange={(e) => setTanggalRujuk(e.target.value)}
                        required
                      ></input>
                    </div>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Waktu rujukan
                      </label>
                      <input
                        className='form-control'
                        type='time'
                        value={waktuRujuk}
                        onChange={(e) => setWaktuRujuk(e.target.value)}
                        required
                      ></input>
                    </div>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Perkiraan Tiba
                      </label>
                      <input
                        className='form-control'
                        type='time'
                        value={perkiraanTiba}
                        onChange={(e) => setPerkiraanTiba(e.target.value)}
                        required
                      ></input>
                    </div>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Alasan ibu dirujuk
                      </label>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck}
                          name='satu'
                          id='flexCheckDefault'
                          value='Kondisi di Faskes'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Kondisi di Faskes
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck}
                          name='dua'
                          id='flexCheckDefault'
                          value='SDM di Faskes'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          SDM di Faskes
                        </label>
                      </div>

                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck}
                          name='tiga'
                          id='flexCheckDefault'
                          value='Fasilitas yang tersedia'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Fasilitas yang tersedia
                        </label>
                      </div>

                      {/* setBumilResiko(e.target.value)} */}
                    </div>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Tujuan Rujukan
                      </label>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        value={tujuanRujuk}
                        onChange={(e) => setTujuanRujuk(e.target.value)}
                      >
                        <option>Pilih tujuan rujuk</option>
                        {daftarPuskes.map((puskesmas, index) => (
                          <option>{puskesmas.puskesmas}</option>
                        ))}
                        <option>RSUD</option>
                      </select>
                    </div>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Dokumen Pendukung
                      </label>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck2}
                          value='Bidan'
                          name='atu'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Bidan
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck2}
                          value='Alat'
                          name='ua'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Alat
                        </label>
                      </div>

                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck2}
                          value='Keluarga'
                          name='iga'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Keluarga
                        </label>
                      </div>

                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck2}
                          value='Surat'
                          name='mpat'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Surat
                        </label>
                      </div>

                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck2}
                          value='Obat'
                          name='ima'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Obat
                        </label>
                      </div>

                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck2}
                          value='Kendaraan'
                          name='nam'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Kendaraan
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck2}
                          value='Uang'
                          name='ujuh'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Uang
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          onChange={changeCheck2}
                          value='Darah'
                          name='elapan'
                          id='flexCheckDefault'
                        />
                        <label class='form-check-label' for='flexCheckDefault'>
                          Darah
                        </label>
                      </div>

                      {/* setBumilResiko(e.target.value)} */}
                    </div>
                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Tindakan yang sudah dilakukan
                      </label>
                      <input
                        className='form-control'
                        type='text'
                        placeholder='Jelaskan Tindakan yang sudah dilakukan'
                        value={tindakan}
                        onChange={(e) => setTindakan(e.target.value)}
                      ></input>
                    </div>

                    <div className='mb-3'>
                      <label
                        for='exampleFormControlInput1'
                        className='form-label'
                      >
                        Kondisi ibu hamil
                      </label>
                      <input
                        className='form-control'
                        type='text'
                        placeholder='Jelaskan Kondisi ibu hamil'
                        value={kondisiIbu}
                        onChange={(e) => setKondisiIbu(e.target.value)}
                      ></input>
                    </div>

                    <div className='mb-3'>
                      <Progres percentage={uploadPercentage} />
                    </div>
                    <div class='d-grid gap-2'>
                      <button className='btn btn-primary' type='submit'>
                        Simpan
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default RujukanIbu;
