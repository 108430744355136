import axios from 'axios';
import { API_MAMABOI } from '../../utils/constant';

class LoadDataRujukanBayi {
  // mendapatkan data
  load = (setData, access) => {
    this.setData = setData;
    this.access = access;
    this.#proccessData();
  };

  #proccessData = async () => {
    var dataIbuku = [];
    var dataIbusaring = [];
    var dataIbuRujuk = [];

    try {
      const response = await axios.get(API_MAMABOI + 'kohort');

      dataIbuku = response.data.data;
    } catch (error) {
      console.error(error);
    }

    // ambil data ibu bersalin
    try {
      const response = await axios.get(API_MAMABOI + 'rujukbayi');
      dataIbuRujuk = response.data.data;
    } catch (error) {
      console.error(error);
    }

    // for (var i = 0; i < dataBersalin.length; i++) {
    // dataBersalin.push(DataIbuBersalin[i][6], DataIbuBersalin[i][1]);
    //}

    //_______
    let com1 = 'Ibu hamil';
    let com2 = 'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)';
    let com3 = '';

    if (this.access !== 'Kabupaten') {
      dataIbusaring = dataIbuku.filter((r) => {
        com3 = r.statusIbu;
        return (
          r.puskesmas.toUpperCase() == this.access.toUpperCase() &&
          (com3.toUpperCase() == com1.toUpperCase() ||
            com3.toUpperCase() == com2.toUpperCase())
        );
      });
    } else {
      dataIbusaring = dataIbuku.filter((r) => {
        com3 = r.statusIbu;

        return (
          com3.toUpperCase() == com1.toUpperCase() ||
          com3.toUpperCase() == com2.toUpperCase()
        );
      });
    }

    console.log(dataIbusaring);
    console.log(dataIbuRujuk);

    var rujukanIbu = dataIbuRujuk.map((r) => {
      var getData = dataIbuku.filter((f) => {
        return f.id === r.idHamil;
      });
      var dataku = getData[0];
      console.log(getData);
      return [
        r.id,
        'Bayi ' + dataku.namaIbu,
        dataku.puskesmas,
        r.tanggalRujuk,
        r.waktuRujuk,
        r.alasanRujuk,
        r.tujuanRujuk,
        r.dokPendukung,
        r.tindakan,
        r.perkiraanTiba,
        r.kondisiIbu,
        r.idHamil,
      ];
    });

    console.log(rujukanIbu);
    this.setData(rujukanIbu);
    //____________
  };
}

export default LoadDataRujukanBayi;
