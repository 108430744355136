import React, { useState, useEffect } from 'react';

import LoadMapModif from './LoadMapModif';
import MappingIbu from './MapingIbu';
import './myMap.css';

const MyMap = ({ access }) => {
  const [desas, setDesas] = useState([]);

  const load = () => {
    const loadDesaTask = new LoadMapModif();
    loadDesaTask.load(setDesas);
  };

  useEffect(load, []);

  return (
    <div>
      {desas.length === 0 ? (
        <div></div>
      ) : (
        <div>
          <MappingIbu desas={desas} access={access} />
        </div>
      )}
    </div>
  );
};

export default MyMap;
