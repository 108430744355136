import React from 'react';
import { useState, useEffect } from 'react';
import LoadDataLap from '../Laporan/LoadDataLap';
import './css/dataIbu.css';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Table } from 'react-bootstrap';
import { API_MAMABOI } from '../../utils/constant';

const User = ({ data }) => {
  const [dataLaporan, setDataLaporan] = useState([]);
  const [dataLaporan1, setDataLaporan1] = useState([]);
  const [dataDesa, setDataDesa] = useState([]);
  const [dataDesa1, setDataDesa1] = useState([]);
  const [kecamatan, setKecamatan] = useState('Kabupaten');
  const load = () => {
    const loadData = new LoadDataLap();
    loadData.load(
      setDataLaporan,
      setDataDesa,
      data,
      setDataLaporan1,
      setDataDesa1
    );
  };

  const onSelectChange = (e) => {
    var data = dataLaporan1;
    var desa = dataDesa1;
    setKecamatan(e);
    if (e !== 'Kabupaten') {
      var laporan = data.filter((r) => {
        return r[7] == e;
      });
      setDataLaporan(laporan);

      var desaTampil = desa.filter((row) => {
        return row.Puskesmas === e;
      });
      setDataDesa(desaTampil);
    }
  };

  const onDesaChange = (e) => {
    var data = dataLaporan1;

    if (e !== 'Pilih Desa') {
      var laporan = data.filter((r) => {
        return r[1] == e;
      });
      setDataLaporan(laporan);
    }
  };

  useEffect(load, []);

  return (
    <div>
      {data === 'Kabupaten' ? (
        <div className='detailsI'>
          <div className='dataIbu'>
            <div className='mb-3'>
              <label for='exampleFormControlInput1' className='form-label'>
                Pilih Puskesmas
              </label>

              <select
                className='form-select'
                aria-label='Default select example'
                onChange={(e) => onSelectChange(e.target.value)}
                value={kecamatan}
              >
                <option>Kabupaten</option>
                <option>Ba'a</option>
                <option>Batutua</option>
                <option>Busalangga</option>
                <option>Delha</option>
                <option>Eahun</option>
                <option>Feapopi</option>
                <option>Korbafo</option>
                <option>Ndao</option>
                <option>Oelaba</option>
                <option>Oele</option>
                <option>Sonimanu</option>
                <option>Sotimori</option>
              </select>
            </div>
          </div>
          <div className='dataIbu'>
            <div className='mb-3'>
              <label for='exampleFormControlInput1' className='form-label'>
                Desa
              </label>
              <select
                className='form-select'
                aria-label='Default select example'
                onChange={(e) => onDesaChange(e.target.value)}
              >
                <option>Pilih Desa</option>
                {dataDesa.map((list, index) => (
                  <option>{list.Desa}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className='detailsII'>
        <div className='dataIbu'>
          <div class='cardHeader'>
            <h2>#REPORT</h2>
            <ReactHTMLTableToExcel
              className='btn'
              table='laporan'
              filename='Laporan'
              sheet={kecamatan}
              buttonText='Export to Exl'
            />
          </div>
          <div className='table-wrapper'>
            <Table id='laporan' striped bordered hover size='sm'>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Puskesmas</th>
                  <th>Desa</th>
                  <th>Ibu Hamil</th>
                  <th>KRST</th>
                  <th>Belum periksa HIV</th>
                  <th>Belum periksa Ims</th>
                  <th>Belum periksa Malaria</th>

                  {/* <th>Tanggal Akhir Mens</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {dataLaporan.map((dataLap, index) => (
                  <tr key={index}>
                    <td> {index + 1}</td>
                    <td> {dataLap[7]}</td>
                    <td> {dataLap[1]}</td>
                    <td> {dataLap[2]}</td>
                    <td> {dataLap[3]}</td>
                    <td> {dataLap[4]}</td>
                    <td> {dataLap[5]}</td>
                    <td> {dataLap[6]}</td>

                    {/* <td> {dataibu.tanggalMensAkhir}</td> */}
                    <td>
                      {/* <Link to={`/edit/${dataibu.id}`} className='btn'>
                        Edit
                      </Link> */}
                      <button className='btn'>Detail</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
