import React, { useState, useEffect } from 'react';
import './css/dataIbu.css';
import axios from 'axios';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Table, Button } from 'react-bootstrap';
import { API_MAMABOI } from '../../utils/constant';

//import LoadDataIbu from '../task/LoadDataIbu';

import LoadDataKunjunganIbu from '../task/LoadDataKunjunganIbu';

const DataKunjunganIbu = ({ data }) => {
  const [kecamatan, setKecamatan] = useState('');
  const [dataIbus, setDataIbus] = useState([]);
  const [dataIbusaring, setDataIbusaring] = useState([]);

  const load = () => {
    const LoadDataIbuku = new LoadDataKunjunganIbu();
    LoadDataIbuku.load(setDataIbusaring, data);
  };

  useEffect(() => {
    load();
  }, []);

  // const getDataIbu = async () => {
  //   try {
  //     const response = await axios.get('https://test.mamaboi.net/api/kohort');

  //     setDataIbus(response.data.data);
  //     if (data !== 'Kabupaten') {
  //       const dataIbusaring = dataIbus.filter((r) => {
  //         return (
  //           r.puskesmas === data &&
  //           (r.statusIbu === 'Ibu hamil' ||
  //             r.statusIbu ===
  //               'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)')
  //         );
  //       });
  //       setDataIbusaring(dataIbusaring);
  //     } else {
  //       setDataIbusaring(dataIbus);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const deleteProduct = async (id) => {
    alert('Data detail ibu belum bisa ditampilkan');
    // getDataIbu();
  };

  return (
    <div>
      <div className='detailsII'>
        <div className='dataIbu'>
          <div class='cardHeader'>
            <h2>#Data Kunjungan Terakhir Ibu</h2>
            <ReactHTMLTableToExcel
              className='btn'
              table='dataIbu'
              filename='export Data Kunjungan Ibu'
              sheet='Data Kunjungan Ibu'
              buttonText='Export to Exl'
            />
          </div>

          <div className='table-wrapper'>
            <Table id='dataIbu' striped bordered hover size='sm'>
              <thead>
                <tr>
                  <th>No</th>
                  <th width='150px'>Action</th>
                  <th>Nama</th>
                  <th>Puskesmas</th>
                  <th>Nomor HP</th>
                  <th>Jumlah Kunjungan</th>
                  <th>Tanggal Kunjungan terakhir</th>
                  <th>Usia Kehamilan</th>
                  <th>Tekanan Darah</th>
                  <th>Tinggi Fundus</th>
                  <th>LiLA</th>
                  <th>KEK</th>
                  <th>Jantung</th>
                  <th>Kepala</th>
                  <th>Berat</th>
                  <th>Persentase</th>
                  <th>Status Ibu</th>
                  <th>Bumil Resiko</th>
                  <th>Buku KIA</th>
                  <th>Keluhan</th>
                  <th>Tata Laksana</th>
                  <th>Konseling P4K</th>
                  <th>Imunisasi TT</th>
                  <th>tabletTD</th>
                  <th>HB</th>
                  <th>Triple Eliminasi</th>
                  <th>Hepatitis</th>
                  <th>HIV</th>
                  <th>Malaria</th>
                  <th>TB</th>
                  <th>IMS</th>
                  <th>Gol Darah</th>
                  <th>Komplikasi</th>
                  <th>Alasan Rujuk</th>
                  <th>Poster Tinggi</th>
                  <th>Foto Poster TInggi</th>
                  <th>Dukungan Keluarga</th>
                  <th>Upload Dukungan</th>

                  {/* <th>Tanggal Akhir Mens</th> */}
                </tr>
              </thead>
              <tbody>
                {dataIbusaring.map((dataibu, index) => (
                  <tr key={dataibu[0]}>
                    <td> {index + 1}</td>
                    <td>
                      <Button
                        variant='danger'
                        onClick={() => deleteProduct(dataibu.id)}
                      >
                        detail
                      </Button>
                    </td>
                    <td> {dataibu[1]}</td>
                    <td> {dataibu[2]}</td>

                    <td> {dataibu[4]}</td>
                    <td> {dataibu[5]}</td>
                    <td> {dataibu[6]}</td>
                    <td> {dataibu[7]}</td>
                    <td> {dataibu[8]}</td>
                    <td> {dataibu[9]}</td>
                    <td> {dataibu[10]}</td>
                    <td> {dataibu[11]}</td>
                    <td> {dataibu[12]}</td>
                    <td> {dataibu[13]}</td>
                    <td> {dataibu[14]}</td>
                    <td> {dataibu[15]}</td>
                    <td> {dataibu[16]}</td>
                    <td> {dataibu[17]}</td>
                    <td> {dataibu[18]}</td>
                    <td> {dataibu[19]}</td>
                    <td> {dataibu[20]}</td>
                    <td> {dataibu[21]}</td>
                    <td> {dataibu[22]}</td>
                    <td> {dataibu[23]}</td>
                    <td> {dataibu[24]}</td>
                    <td> {dataibu[25]}</td>
                    <td> {dataibu[26]}</td>
                    <td> {dataibu[27]}</td>
                    <td> {dataibu[28]}</td>
                    <td> {dataibu[29]}</td>
                    <td> {dataibu[30]}</td>
                    <td> {dataibu[31]}</td>
                    <td> {dataibu[32]}</td>
                    <td> {dataibu[33]}</td>
                    <td> {dataibu[34]}</td>
                    <td> {dataibu[35]}</td>

                    {/* <td> {dataibu.tanggalMensAkhir}</td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataKunjunganIbu;
