import axios from 'axios';
import { API_MAMABOI } from '../../utils/constant';

class LoadDataStatus {
  // mendapatkan data
  load = (setData, access) => {
    this.setData = setData;
    this.access = access;
    this.#proccessData();
  };

  #proccessData = async () => {
    var dataStatus = [];
    var dataKomentar = [];
    var dataProfile = [];

    // ambil data status, komentar, informasi
    try {
      const response = await axios.get(API_MAMABOI + 'status');
      dataStatus = response.data.data;
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await axios.get(API_MAMABOI + 'komentar');
      dataKomentar = response.data.data;
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await axios.get(API_MAMABOI + 'profile');
      dataProfile = response.data.data;
    } catch (error) {
      console.error(error);
    }

    var statusKomen = dataStatus.map((r) => {
      var urlStatus = '';
      var filterKomen = dataKomentar.filter((f) => {
        return f.idStatus == r.id;
      });

      //mengisi foto pada koment

      var filterKomenProfil = filterKomen.map((rf) => {
        var urlKomen = '';
        var komenProfil = dataProfile.filter((rft) => {
          return rf.userId === rft.userName;
        });
        if (komenProfil.length > 0) {
          urlKomen = komenProfil[komenProfil.length - 1].profile;
        } else {
          urlKomen = 'https://mamaboi.net/mamaboi/ProfileMamaBoiKu.jpg';
        }
        var arrk = rf.tanggal.split('-');
        rf.tanggal = arrk[2] + '-' + arrk[1] + '-' + arrk[0];
        rf.urlKomen = urlKomen;
        return rf;
      });

      //mengisi foto pada status

      var statusProfil = dataProfile.filter((rftf) => {
        return r.userId === rftf.userName;
      });

      if (statusProfil.length > 0) {
        urlStatus = statusProfil[statusProfil.length - 1].profile;
      } else {
        urlStatus = 'https://mamaboi.net/mamaboi/ProfileMamaBoiKu.jpg';
      }

      var arr = r.tanggal.split('-');
      r.tanggal = arr[2] + '-' + arr[1] + '-' + arr[0];

      return [
        r.id,
        r.userId,
        r.foto,
        r.caption,
        r.tanggal,
        filterKomenProfil,
        r.tanggalKomen,
        urlStatus,
      ];
    });

    //  statusKomen.sort((a, b) => {
    //   if (a.tanggalKomen > b.tanggalKomen) return -1;
    //  return a.tanggalKomen < b.tanggalKomen ? 1 : 0;
    // });

    statusKomen.sort(function (a, b) {
      var dateA = new Date(a[6]),
        dateB = new Date(b[6]);

      return dateB - dateA;
    });

    // console.log(statusKomen);
    this.setData(statusKomen);
    //____________
  };
}

export default LoadDataStatus;
