import axios from 'axios';
import { API_MAMABOI } from '../../utils/constant';

class LoadGrafik {
  // mendapatkan data
  load = (setData, setYear, access) => {
    this.setData = setData;
    this.setYear = setYear;
    this.access = access;
    this.#proccessData();
  };

  #proccessData = async () => {
    var dataIbuku = [];
    var dataBersalin = [];

    try {
      const response = await axios.get(API_MAMABOI + 'kohort');
      dataIbuku = response.data.data;
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await axios.get(API_MAMABOI + 'kohort/smart/2023');
      dataBersalin = response.data.data;
    } catch (error) {
      console.error(error);
    }

    var ibuHamil = [];
    let com1 = 'Ibu hamil';
    let com2 = 'Ibu hamil yang menolak untuk diperiksa (masuk Bumil Resti)';
    let com3 = '';

    if (this.access !== 'Kabupaten') {
      ibuHamil = dataIbuku.filter((ibu) => {
        com3 = ibu['statusIbu'];
        return (
          ibu['puskesmas'] === this.access
          // &&
          // (com3.toUpperCase() === com2.toUpperCase() ||
          //   com3.toUpperCase() === com1.toUpperCase())
        );
      });
    } else {
      ibuHamil = dataIbuku;
      // .filter((ibu) => {
      //   com3 = ibu['statusIbu'];
      //   return (
      //     com3.toUpperCase() === com2.toUpperCase() ||
      //     com3.toUpperCase() === com1.toUpperCase()
      //   );
      // });
    }

    //membuat data hamil perbulan
    var listGrafik = ibuHamil.map((r) => {
      var tglCreate = r.createdAt;
      var arr = tglCreate.split('-');
      var bln = parseInt(arr[1]);
      var thn = parseInt(arr[0]);
      return [r.namaIbu, r.puskesmas, r.desaIbu, bln, thn];
    });

    var dateObj = new Date();

    var year = dateObj.getUTCFullYear();
    var dataHamilBulan = [];
    for (var i = 0; i < 12; i++) {
      var dataGrafik = listGrafik.filter((item) => {
        return item[3] == i + 1 && item[4] == year;
      });
      dataHamilBulan[i] = dataGrafik.length;
    }

    var ibuBersalin = [];

    if (this.access !== 'Kabupaten') {
      ibuBersalin = dataBersalin.filter((ibu) => {
        return (
          ibu['puskesmas'] === this.access
          // &&
          // (com3.toUpperCase() === com2.toUpperCase() ||
          //   com3.toUpperCase() === com1.toUpperCase())
        );
      });
    } else {
      ibuBersalin = dataBersalin;
      // .filter((ibu) => {
      //   com3 = ibu['statusIbu'];
      //   return (
      //     com3.toUpperCase() === com2.toUpperCase() ||
      //     com3.toUpperCase() === com1.toUpperCase()
      //   );
      // });
    }

    // ibuBersalin = dataBersalin;

    //membuat data hamil perbulan
    var listGrafikBersalin = ibuBersalin.map((r) => {
      var tglCreate = r.tglBersalin;
      var arr = tglCreate.split('-');
      var bln = parseInt(arr[1]);
      var thn = parseInt(arr[0]);
      return [r.id, bln, thn];
    });

    var dataBersalinBulan = [];
    for (var i = 0; i < 12; i++) {
      var dataGrafikBersalin = listGrafikBersalin.filter((item) => {
        return item[1] == i + 1 && item[2] == year;
      });
      dataBersalinBulan[i] = dataGrafikBersalin.length;
    }

    this.setData({ hamil: dataHamilBulan, bersalin: dataBersalinBulan });
    this.setYear(year);
  };
}

export default LoadGrafik;
