import axios from 'axios';
import { API_MAMABOI } from '../../utils/constant';

class LoadDataInformasi {
  // mendapatkan data
  load = (setData, access) => {
    this.setData = setData;
    this.access = access;
    this.#proccessData();
  };

  #proccessData = async () => {
    var dataInformasi = [];
    var dataProfile = [];

    // ambil data status, komentar, informasi
    try {
      const response = await axios.get(API_MAMABOI + 'informasi');
      dataInformasi = response.data.data;
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await axios.get(API_MAMABOI + 'profile');
      dataProfile = response.data.data;
    } catch (error) {
      console.error(error);
    }

    var dataInformasiProfil = dataInformasi.map((r) => {
      var statusProfil = dataProfile.filter((rftf) => {
        return r.userId === rftf.userName;
      });

      if (statusProfil.length > 0) {
        r.urlInfo = statusProfil[statusProfil.length - 1].profile;
      } else {
        r.urlInfo = 'https://mamaboi.net/mamaboi/ProfileMamaBoiKu.jpg';
      }
      return r;
    });

    dataInformasiProfil.sort(function (a, b) {
      var dateA = a.id,
        dateB = b.id;
      return dateB - dateA;
    });

    for (var i = 0; i < dataInformasiProfil.length; i++) {
      dataInformasiProfil[i].index = i + 1;
    }
    //console.log(dataInformasi);
    this.setData(dataInformasiProfil);
    //____________
  };
}

export default LoadDataInformasi;
